/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllExportWarehouses({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/exports?${payload}`)
        : () => axiosMicro.get('/exports', { params: payload }),
    );
    yield put(Actions.getAllExportWarehousesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllExportWarehousesFailure(messages));
    }
  }
}

function* createExportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/exports', payload));
    yield put(Actions.createExportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createExportWarehouseFailure(messages));
    }
  }
}

function* cancelExportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/exports/${payload}/cancel`));
    yield put(Actions.cancelExportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.cancelExportWarehouseFailure(messages));
    }
  }
}

function* considerExportWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/exports/${payload.id}/status`, payload.body));
    yield put(Actions.considerExportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.considerExportWarehouseFailure(messages));
    }
  }
}

function* updateExportWarehouse({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/exports/${payload.id}`, payload.body));
    yield put(Actions.updateExportWarehouseSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateExportWarehouseFailure(messages));
    }
  }
}

function* deleteExportWarehouse({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/exports/${payload}/cancel`));
    yield put(Actions.deleteExportWarehouseSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteExportWarehouseFailure(messages));
    }
  }
}

function* getOrderExportWarehouse({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/orders?${payload}`)
        : () => axiosMicro.get('/orders', { params: payload }),
    );
    yield put(Actions.getOrderExportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getOrderExportWarehouseFailure(messages));
    }
  }
}

function* getCustomerExportWarehouse({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/customers?${payload}`)
        : () => axiosMicro.get('/customers', { params: payload }),
    );
    yield put(Actions.getCustomerExportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getCustomerExportWarehouseFailure(messages));
    }
  }
}

function* getListOrderExportWarehouse({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/orders/date?${payload}`)
        : () => axiosMicro.get('/orders/date', { params: payload }),
    );
    yield put(Actions.getListOrderExportWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getListOrderExportWarehouseFailure(messages));
    }
  }
}

function* exportDriver({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/exports/${payload.id}/driver`, payload.body));
    yield put(Actions.exportDriverWarehousesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.exportDriverWarehousesFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllExportWarehousesRequest, getAllExportWarehouses);
  yield takeLatest(Actions.createExportWarehouseRequest, createExportWarehouse);
  yield takeLatest(Actions.updateExportWarehouseRequest, updateExportWarehouse);
  yield takeLatest(Actions.deleteExportWarehouseRequest, deleteExportWarehouse);
  yield takeLatest(Actions.getOrderExportWarehouseRequest, getOrderExportWarehouse);
  yield takeLatest(Actions.considerExportWarehouseRequest, considerExportWarehouse);
  yield takeLatest(Actions.getCustomerExportWarehouseRequest, getCustomerExportWarehouse);
  yield takeLatest(Actions.getListOrderExportWarehouseRequest, getListOrderExportWarehouse);
  yield takeLatest(Actions.exportDriverWarehousesRequest, exportDriver);
  yield takeLatest(Actions.cancelExportWarehouseRequest, cancelExportWarehouse);
}
