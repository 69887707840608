/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllProductGrades({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-grades?${payload}`)
        : () => axiosMicro.get('/product-grades', { params: payload }),
    );
    yield put(Actions.getAllProductGradesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllProductGradesFailure(messages));
    }
  }
}

function* getListProductGrades({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-grades/list?${payload}`)
        : () => axiosMicro.get('/product-grades/list', { params: payload }),
    );
    yield put(Actions.getListProductGradesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getListProductGradesFailure(messages));
    }
  }
}

function* createProductGrade({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-grades', payload));
    yield put(Actions.createProductGradeSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createProductGradeFailure(messages));
    }
  }
}

function* createProductGradeMulti({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-grades/save', payload));
    yield put(Actions.createProductGradeMultiSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createProductGradeMultiFailure(messages));
    }
  }
}

function* updateProductGrade({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/product-grades/${payload.id}`, payload.body, {
      params: {
        include: 'productSize',
      },
    }));
    yield put(Actions.updateProductGradeSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateProductGradeFailure(messages));
    }
  }
}

function* deleteProductGrade({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/product-grades/${payload}`));
    yield put(Actions.deleteProductGradeSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteProductGradeFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllProductGradesRequest, getAllProductGrades);
  yield takeLatest(Actions.getListProductGradesRequest, getListProductGrades);
  yield takeLatest(Actions.createProductGradeRequest, createProductGrade);
  yield takeLatest(Actions.createProductGradeMultiRequest, createProductGradeMulti);
  yield takeLatest(Actions.updateProductGradeRequest, updateProductGrade);
  yield takeLatest(Actions.deleteProductGradeRequest, deleteProductGrade);
}
