/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllProductSizes({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-sizes?${payload}`)
        : () => axiosMicro.get('/product-sizes', { params: payload }),
    );
    yield put(Actions.getAllProductSizesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllProductSizesFailure(messages));
    }
  }
}

function* getListProductSizes({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-sizes/list?${payload}`)
        : () => axiosMicro.get('/product-sizes/list', { params: payload }),
    );
    yield put(Actions.getListProductSizesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getListProductSizesFailure(messages));
    }
  }
}

function* createProductSize({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-sizes', payload, {
      params: {
        include: 'product,customer',
      },
    }));
    yield put(Actions.createProductSizeSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createProductSizeFailure(messages));
    }
  }
}

function* updateProductSize({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/product-sizes/${payload.id}`, payload.body, {
      params: {
        include: 'product,customer',
      },
    }));
    yield put(Actions.updateProductSizeSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateProductSizeFailure(messages));
    }
  }
}

function* deleteProductSize({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/product-sizes/${payload}`));
    yield put(Actions.deleteProductSizeSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteProductSizeFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllProductSizesRequest, getAllProductSizes);
  yield takeLatest(Actions.getListProductSizesRequest, getListProductSizes);
  yield takeLatest(Actions.createProductSizeRequest, createProductSize);
  yield takeLatest(Actions.updateProductSizeRequest, updateProductSize);
  yield takeLatest(Actions.deleteProductSizeRequest, deleteProductSize);
}
