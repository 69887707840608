// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All ProductGrade
  isFirstGetAllProductGrades: false,
  isGetAllProductGradesRequest: false,
  isGetAllProductGradesSuccess: false,
  isGetAllProductGradesFailure: false,
  getAllProductGradesState: {},
  // Get List ProductGrade
  isGetListProductGradesRequest: false,
  isGetListProductGradesSuccess: false,
  isGetListProductGradesFailure: false,
  getListProductGradesState: {},
  // Create ProductGrade
  isCreateProductGradeRequest: false,
  isCreateProductGradeSuccess: false,
  isCreateProductGradeFailure: false,
  // Create ProductGrade
  isCreateProductGradeMultiRequest: false,
  isCreateProductGradeMultiSuccess: false,
  isCreateProductGradeMultiFailure: false,
  // Update ProductGrade
  isUpdateProductGradeRequest: false,
  isUpdateProductGradeSuccess: false,
  isUpdateProductGradeFailure: false,
  // Delete ProductGrade
  isDeleteProductGradeRequest: false,
  isDeleteProductGradeSuccess: false,
  isDeleteProductGradeFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All ProductGrade
    [Actions.getAllProductGradesRequest]: (state) => ({
      ...state,
      isGetAllProductGradesRequest: true,
      isGetAllProductGradesSuccess: false,
      isGetAllProductGradesFailure: false,
    }),
    [Actions.getAllProductGradesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllProductGrades: true,
      isGetAllProductGradesRequest: false,
      isGetAllProductGradesSuccess: true,
      isGetAllProductGradesFailure: false,
      getAllProductGradesState: payload,
    }),
    [Actions.getAllProductGradesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductGradesRequest: false,
      isGetAllProductGradesSuccess: false,
      isGetAllProductGradesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get List ProductGrade
    [Actions.getListProductGradesRequest]: (state) => ({
      ...state,
      isGetListProductGradesRequest: true,
      isGetListProductGradesSuccess: false,
      isGetListProductGradesFailure: false,
    }),
    [Actions.getListProductGradesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetListProductGrades: true,
      isGetListProductGradesRequest: false,
      isGetListProductGradesSuccess: true,
      isGetListProductGradesFailure: false,
      getListProductGradesState: payload,
    }),
    [Actions.getListProductGradesFailure]: (state, { payload }) => ({
      ...state,
      isGetListProductGradesRequest: false,
      isGetListProductGradesSuccess: false,
      isGetListProductGradesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create ProductGrade
    [Actions.createProductGradeRequest]: (state) => ({
      ...state,
      isCreateProductGradeRequest: true,
      isCreateProductGradeSuccess: false,
      isCreateProductGradeFailure: false,
    }),
    [Actions.createProductGradeSuccess]: (state, { payload }) => {
      const getAllProductGradesStateTmp = handleUpdateDataList(state.getAllProductGradesState, payload.data, 'create');

      return ({
        ...state,
        isCreateProductGradeRequest: false,
        isCreateProductGradeSuccess: true,
        isCreateProductGradeFailure: false,
        getAllProductGradesState: { ...getAllProductGradesStateTmp },
      });
    },
    [Actions.createProductGradeFailure]: (state, { payload }) => ({
      ...state,
      isCreateProductGradeRequest: false,
      isCreateProductGradeSuccess: false,
      isCreateProductGradeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateProductGrade]: (state) => ({
      ...state,
      isCreateProductGradeRequest: false,
      isCreateProductGradeSuccess: false,
      isCreateProductGradeFailure: false,
    }),
    // #endregion
    // #region : Create ProductGrade
    [Actions.createProductGradeMultiRequest]: (state) => ({
      ...state,
      isCreateProductGradeMultiRequest: true,
      isCreateProductGradeMultiSuccess: false,
      isCreateProductGradeMultiFailure: false,
    }),
    [Actions.createProductGradeMultiSuccess]: (state) => ({
      ...state,
      isCreateProductGradeMultiRequest: false,
      isCreateProductGradeMultiSuccess: true,
      isCreateProductGradeMultiFailure: false,
    }),
    [Actions.createProductGradeMultiFailure]: (state, { payload }) => ({
      ...state,
      isCreateProductGradeMultiRequest: false,
      isCreateProductGradeMultiSuccess: false,
      isCreateProductGradeMultiFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateProductGradeMulti]: (state) => ({
      ...state,
      isCreateProductGradeMultiRequest: false,
      isCreateProductGradeMultiSuccess: false,
      isCreateProductGradeMultiFailure: false,
    }),
    // #endregion
    // #region : Update ProductGrade
    [Actions.updateProductGradeRequest]: (state) => ({
      ...state,
      isUpdateProductGradeRequest: true,
      isUpdateProductGradeSuccess: false,
      isUpdateProductGradeFailure: false,
    }),
    [Actions.updateProductGradeSuccess]: (state) => ({
      ...state,
      isUpdateProductGradeRequest: false,
      isUpdateProductGradeSuccess: true,
      isUpdateProductGradeFailure: false,
    }),
    [Actions.updateProductGradeFailure]: (state, { payload }) => ({
      ...state,
      isUpdateProductGradeRequest: false,
      isUpdateProductGradeSuccess: false,
      isUpdateProductGradeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateProductGrade]: (state) => ({
      ...state,
      isUpdateProductGradeRequest: false,
      isUpdateProductGradeSuccess: false,
      isUpdateProductGradeFailure: false,
    }),
    // #endregion
    // #region : Delete ProductGrade
    [Actions.deleteProductGradeRequest]: (state) => ({
      ...state,
      isDeleteProductGradeRequest: true,
      isDeleteProductGradeSuccess: false,
      isDeleteProductGradeFailure: false,
    }),
    [Actions.deleteProductGradeSuccess]: (state, { payload }) => {
      const getAllProductGradesStateTmp = handleUpdateDataList(state.getAllProductGradesState, payload, 'delete');

      return ({
        ...state,
        isDeleteProductGradeRequest: false,
        isDeleteProductGradeSuccess: true,
        isDeleteProductGradeFailure: false,
        getAllProductGradesState: { ...getAllProductGradesStateTmp },
      });
    },
    [Actions.deleteProductGradeFailure]: (state, { payload }) => ({
      ...state,
      isDeleteProductGradeRequest: false,
      isDeleteProductGradeSuccess: false,
      isDeleteProductGradeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteProductGrade]: (state) => ({
      ...state,
      isDeleteProductGradeRequest: false,
      isDeleteProductGradeSuccess: false,
      isDeleteProductGradeFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetProductGradeState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
