/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllPublicOrders({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/public/orders?${payload}`)
        : () => axiosMicro.get('/public/orders', { params: payload }),
    );
    yield put(Actions.getAllPublicOrdersSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllPublicOrdersFailure(messages));
    }
  }
}

function* createPublicOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/public/orders', payload));
    yield put(Actions.createPublicOrderSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createPublicOrderFailure(messages));
    }
  }
}

function* updatePublicOrder({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/public/orders/${payload.id}`, payload.body));
    yield put(Actions.updatePublicOrderSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updatePublicOrderFailure(messages));
    }
  }
}

function* deletePublicOrder({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/public/orders/${payload}`));
    yield put(Actions.deletePublicOrderSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deletePublicOrderFailure(messages));
    }
  }
}

function* updateStatusPublicOrder({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/public/orders/${payload.id}/status`, {
      status: payload.status,
    }));
    yield put(Actions.updateStatusPublicOrderSuccess());
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateStatusPublicOrderFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllPublicOrdersRequest, getAllPublicOrders);
  yield takeLatest(Actions.createPublicOrderRequest, createPublicOrder);
  yield takeLatest(Actions.updatePublicOrderRequest, updatePublicOrder);
  yield takeLatest(Actions.deletePublicOrderRequest, deletePublicOrder);
  yield takeLatest(Actions.updateStatusPublicOrderRequest, updateStatusPublicOrder);
}
