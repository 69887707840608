/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllRefunds({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/refunds?${payload}`)
        : () => axiosMicro.get('/refunds', { params: payload }),
    );
    yield put(Actions.getAllRefundsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllRefundsFailure(messages));
    }
  }
}

function* createRefund({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/refunds', payload));
    yield put(Actions.createRefundSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createRefundFailure(messages));
    }
  }
}

function* cancelRefund({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/refunds/${payload.id}/cancel`, payload.body || {}));
    yield put(Actions.cancelRefundSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.cancelRefundFailure(messages));
    }
  }
}

function* updateRefundStatus({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/refunds/${payload.id}/status`, payload.body));
    yield put(Actions.updateRefundStatusSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateRefundStatusFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllRefundsRequest, getAllRefunds);
  yield takeLatest(Actions.createRefundRequest, createRefund);
  yield takeLatest(Actions.cancelRefundRequest, cancelRefund);
  yield takeLatest(Actions.updateRefundStatusRequest, updateRefundStatus);
}
