// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllLabelsRequest = createAction('GET_ALL_LABELS_REQUEST');
export const getAllLabelsSuccess = createAction('GET_ALL_LABELS_SUCCESS');
export const getAllLabelsFailure = createAction('GET_ALL_LABELS_FAILURE');

export const createLabelRequest = createAction('CREATE_LABEL_REQUEST');
export const createLabelSuccess = createAction('CREATE_LABEL_SUCCESS');
export const createLabelFailure = createAction('CREATE_LABEL_FAILURE');
export const resetCreateLabel = createAction('RESET_CREATE_LABEL');

export const updateLabelRequest = createAction('UPDATE_LABEL_REQUEST');
export const updateLabelSuccess = createAction('UPDATE_LABEL_SUCCESS');
export const updateLabelFailure = createAction('UPDATE_LABEL_FAILURE');
export const resetUpdateLabel = createAction('RESET_UPDATE_LABEL');

export const deleteLabelRequest = createAction('DELETE_LABEL_REQUEST');
export const deleteLabelSuccess = createAction('DELETE_LABEL_SUCCESS');
export const deleteLabelFailure = createAction('DELETE_LABEL_FAILURE');
export const resetDeleteLabel = createAction('RESET_DELETE_LABEL');

export const getListLabelsRequest = createAction('GET_LIST_LABELS_REQUEST');
export const getListLabelsSuccess = createAction('GET_LIST_LABELS_SUCCESS');
export const getListLabelsFailure = createAction('GET_LIST_LABELS_FAILURE');

export const resetLabelState = createAction('RESET_LABEL_STATE');
