// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getListCustomerRequest = createAction('GET_LIST_CUSTOMER_REQUEST');
export const getListCustomerSuccess = createAction('GET_LIST_CUSTOMER_SUCCESS');
export const getListCustomerFailure = createAction('GET_LIST_CUSTOMER_FAILURE');
export const resetGetListCustomerState = createAction('RESET_GET_LIST_CUSTOMER_STATE');

export const addCustomerRequest = createAction('ADD_CUSTOMER_REQUEST');
export const addCustomerSuccess = createAction('ADD_CUSTOMER_SUCCESS');
export const addCustomerFailure = createAction('ADD_CUSTOMER_FAILURE');
export const resetAddCustomerState = createAction('RESET_ADD_CUSTOMER_STATE');

export const getDetailCustomerRequest = createAction('GET_DETAIL_CUSTOMER_REQUEST');
export const getDetailCustomerSuccess = createAction('GET_DETAIL_CUSTOMER_SUCCESS');
export const getDetailCustomerFailure = createAction('GET_DETAIL_CUSTOMER_FAILURE');
export const resetGetDetailCustomerState = createAction('RESET_GET_DETAIL_CUSTOMER_STATE');

export const getAllRegionRequest = createAction('GET_ALL_REGION_CUSTOMER_REQUEST');
export const getAllRegionSuccess = createAction('GET_ALL_REGION_CUSTOMER_SUCCESS');
export const getAllRegionFailure = createAction('GET_ALL_REGION_CUSTOMER_FAILURE');

export const getAllWardRequest = createAction('GET_ALL_WARD_CUSTOMER_REQUEST');
export const getAllWardSuccess = createAction('GET_ALL_WARD_CUSTOMER_SUCCESS');
export const getAllWardFailure = createAction('GET_ALL_WARD_CUSTOMER_FAILURE');

export const getAllDistrictRequest = createAction('GET_ALL_DISTRICT_REQUEST');
export const getAllDistrictSuccess = createAction('GET_ALL_DISTRICT_SUCCESS');
export const getAllDistrictFailure = createAction('GET_ALL_DISTRICT_FAILURE');

export const deleteCustomerRequest = createAction('DELETE_CUSTOMER_REQUEST');
export const deleteCustomerSuccess = createAction('DELETE_CUSTOMER_SUCCESS');
export const deleteCustomerFailure = createAction('DELETE_CUSTOMER_FAILURE');
export const resetDeleteCustomerState = createAction('RESET_DELETE_CUSTOMER_STATE');

export const editCustomerRequest = createAction('EDIT_CUSTOMER_REQUEST');
export const editCustomerSuccess = createAction('EDIT_CUSTOMER_SUCCESS');
export const editCustomerFailure = createAction('EDIT_CUSTOMER_FAILURE');
export const resetEditCustomerState = createAction('RESET_EDIT_CUSTOMER_STATE');

export const updateCustomerBRequest = createAction('UPDATE_CUSTOMER_B_REQUEST');
export const updateCustomerBSuccess = createAction('UPDATE_CUSTOMER_B_SUCCESS');
export const updateCustomerBFailure = createAction('UPDATE_CUSTOMER_B_FAILURE');
export const resetUpdateCustomerB = createAction('RESET_UPDATE_CUSTOMER_B');

export const getAllCustomerDebtRequest = createAction('GET_ALL_CUSTOMER_DEBT_REQUEST');
export const getAllCustomerDebtSuccess = createAction('GET_ALL_CUSTOMER_DEBT_SUCCESS');
export const getAllCustomerDebtFailure = createAction('GET_ALL_CUSTOMER_DEBT_FAILURE');
export const resetGetAllCustomerDebtState = createAction('RESET_GET_ALL_CUSTOMER_DEBT_STATE');

export const checkExistCustomerByCodeRequest = createAction('CHECK_EXIST_CUSTOMER_BY_CODE_REQUEST');
export const checkExistCustomerByCodeSuccess = createAction('CHECK_EXIST_CUSTOMER_BY_CODE_SUCCESS');
export const checkExistCustomerByCodeFailure = createAction('CHECK_EXIST_CUSTOMER_BY_CODE_FAILURE');
export const resetCheckExistCustomerByCode = createAction('RESET_CHECK_EXIST_CUSTOMER_BY_CODE');

export const checkExistCustomerByEmailRequest = createAction('CHECK_EXIST_CUSTOMER_BY_EMAIL_REQUEST');
export const checkExistCustomerByEmailSuccess = createAction('CHECK_EXIST_CUSTOMER_BY_EMAIL_SUCCESS');
export const checkExistCustomerByEmailFailure = createAction('CHECK_EXIST_CUSTOMER_BY_EMAIL_FAILURE');
export const resetCheckExistCustomerByEmail = createAction('RESET_CHECK_EXIST_CUSTOMER_BY_EMAIL');

export const resetCustomerState = createAction('RESET_USER_STATE');
