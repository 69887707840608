// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All ImportWarehouse
  isFirstGetAllImportWarehouses: false,
  isGetAllImportWarehousesRequest: false,
  isGetAllImportWarehousesSuccess: false,
  isGetAllImportWarehousesFailure: false,
  getAllImportWarehousesState: {},
  // Create ImportWarehouse
  isCreateImportWarehouseRequest: false,
  isCreateImportWarehouseSuccess: false,
  isCreateImportWarehouseFailure: false,
  // Update ImportWarehouse
  isUpdateImportWarehouseRequest: false,
  isUpdateImportWarehouseSuccess: false,
  isUpdateImportWarehouseFailure: false,
  // Delete ImportWarehouse
  isDeleteImportWarehouseRequest: false,
  isDeleteImportWarehouseSuccess: false,
  isDeleteImportWarehouseFailure: false,
  // Consider ImportWarehouse
  isConsiderImportWarehouseRequest: false,
  isConsiderImportWarehouseSuccess: false,
  isConsiderImportWarehouseFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All ImportWarehouse
    [Actions.getAllImportWarehousesRequest]: (state) => ({
      ...state,
      isGetAllImportWarehousesRequest: true,
      isGetAllImportWarehousesSuccess: false,
      isGetAllImportWarehousesFailure: false,
    }),
    [Actions.getAllImportWarehousesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllImportWarehouses: true,
      isGetAllImportWarehousesRequest: false,
      isGetAllImportWarehousesSuccess: true,
      isGetAllImportWarehousesFailure: false,
      getAllImportWarehousesState: payload,
    }),
    [Actions.getAllImportWarehousesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllImportWarehousesRequest: false,
      isGetAllImportWarehousesSuccess: false,
      isGetAllImportWarehousesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create ImportWarehouse
    [Actions.createImportWarehouseRequest]: (state) => ({
      ...state,
      isCreateImportWarehouseRequest: true,
      isCreateImportWarehouseSuccess: false,
      isCreateImportWarehouseFailure: false,
    }),
    [Actions.createImportWarehouseSuccess]: (state, { payload }) => {
      const getAllImportWarehousesStateTmp = handleUpdateDataList(state.getAllImportWarehousesState, payload.data, 'create');

      return ({
        ...state,
        isCreateImportWarehouseRequest: false,
        isCreateImportWarehouseSuccess: true,
        isCreateImportWarehouseFailure: false,
        getAllImportWarehousesState: { ...getAllImportWarehousesStateTmp },
      });
    },
    [Actions.createImportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isCreateImportWarehouseRequest: false,
      isCreateImportWarehouseSuccess: false,
      isCreateImportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateImportWarehouse]: (state) => ({
      ...state,
      isCreateImportWarehouseRequest: false,
      isCreateImportWarehouseSuccess: false,
      isCreateImportWarehouseFailure: false,
    }),
    // #endregion
    // #region : Update ImportWarehouse
    [Actions.updateImportWarehouseRequest]: (state) => ({
      ...state,
      isUpdateImportWarehouseRequest: true,
      isUpdateImportWarehouseSuccess: false,
      isUpdateImportWarehouseFailure: false,
    }),
    [Actions.updateImportWarehouseSuccess]: (state, { payload }) => {
      const getAllImportWarehousesStateTmp = handleUpdateDataList(state.getAllImportWarehousesState, payload.data, 'update');

      return ({
        ...state,
        isUpdateImportWarehouseRequest: false,
        isUpdateImportWarehouseSuccess: true,
        isUpdateImportWarehouseFailure: false,
        getAllImportWarehousesState: { ...getAllImportWarehousesStateTmp },
      });
    },
    [Actions.updateImportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isUpdateImportWarehouseRequest: false,
      isUpdateImportWarehouseSuccess: false,
      isUpdateImportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateImportWarehouse]: (state) => ({
      ...state,
      isUpdateImportWarehouseRequest: false,
      isUpdateImportWarehouseSuccess: false,
      isUpdateImportWarehouseFailure: false,
    }),
    // #endregion
    // #region : Delete ImportWarehouse
    [Actions.deleteImportWarehouseRequest]: (state) => ({
      ...state,
      isDeleteImportWarehouseRequest: true,
      isDeleteImportWarehouseSuccess: false,
      isDeleteImportWarehouseFailure: false,
    }),
    [Actions.deleteImportWarehouseSuccess]: (state, { payload }) => {
      const getAllImportWarehousesStateTmp = handleUpdateDataList(state.getAllImportWarehousesState, payload, 'delete');

      return ({
        ...state,
        isDeleteImportWarehouseRequest: false,
        isDeleteImportWarehouseSuccess: true,
        isDeleteImportWarehouseFailure: false,
        getAllImportWarehousesState: { ...getAllImportWarehousesStateTmp },
      });
    },
    [Actions.deleteImportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isDeleteImportWarehouseRequest: false,
      isDeleteImportWarehouseSuccess: false,
      isDeleteImportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteImportWarehouse]: (state) => ({
      ...state,
      isDeleteImportWarehouseRequest: false,
      isDeleteImportWarehouseSuccess: false,
      isDeleteImportWarehouseFailure: false,
    }),
    // #endregion

    // #region Consider importwarehouses
    [Actions.considerImportWarehouseRequest]: (state) => ({
      ...state,
      isConsiderImportWarehouseRequest: true,
      isConsiderImportWarehouseSuccess: false,
      isConsiderImportWarehouseFailure: false,
    }),
    [Actions.considerImportWarehouseSuccess]: (state) => ({
      ...state,
      isConsiderImportWarehouseRequest: false,
      isConsiderImportWarehouseSuccess: true,
      isConsiderImportWarehouseFailure: false,
    }),
    [Actions.considerImportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isConsiderImportWarehouseRequest: false,
      isConsiderImportWarehouseSuccess: false,
      isConsiderImportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetConsiderImportWarehouse]: (state) => ({
      ...state,
      isConsiderImportWarehouseRequest: false,
      isConsiderImportWarehouseSuccess: false,
      isConsiderImportWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region : Local
    [Actions.resetImportWarehouseState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
