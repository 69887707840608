// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All Shipping
  isFirstGetAllShippings: false,
  isGetAllShippingsRequest: false,
  isGetAllShippingsSuccess: false,
  isGetAllShippingsFailure: false,
  getAllShippingsState: {},
  // Create Shipping
  isCreateShippingRequest: false,
  isCreateShippingSuccess: false,
  isCreateShippingFailure: false,
  // Update Shipping
  isUpdateShippingRequest: false,
  isUpdateShippingSuccess: false,
  isUpdateShippingFailure: false,
  // Delete Shipping
  isDeleteShippingRequest: false,
  isDeleteShippingSuccess: false,
  isDeleteShippingFailure: false,

  isGetListShippingsRequest: false,
  isGetListShippingsSuccess: false,
  isGetListShippingsFailure: false,
  listShippingsState: {},

  isAddShippingsRequest: false,
  isAddShippingsSuccess: false,
  isAddShippingsFailure: false,
  responseDataAddShipping: {},

  isEditShippingsRequest: false,
  isEditShippingsSuccess: false,
  isEditShippingsFailure: false,

  isDeleteShippingsRequest: false,
  isDeleteShippingsSuccess: false,
  isDeleteShippingsFailure: false,

  isAddShippingsPriceRequest: false,
  isAddShippingsPriceSuccess: false,
  isAddShippingsPriceFailure: false,

  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Shipping
    [Actions.getAllShippingsRequest]: (state) => ({
      ...state,
      isGetAllShippingsRequest: true,
      isGetAllShippingsSuccess: false,
      isGetAllShippingsFailure: false,
    }),
    [Actions.getAllShippingsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllShippings: true,
      isGetAllShippingsRequest: false,
      isGetAllShippingsSuccess: true,
      isGetAllShippingsFailure: false,
      getAllShippingsState: payload,
    }),
    [Actions.getAllShippingsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllShippingsRequest: false,
      isGetAllShippingsSuccess: false,
      isGetAllShippingsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Shipping
    [Actions.createShippingRequest]: (state) => ({
      ...state,
      isCreateShippingRequest: true,
      isCreateShippingSuccess: false,
      isCreateShippingFailure: false,
    }),
    [Actions.createShippingSuccess]: (state, { payload }) => {
      const getAllShippingsStateTmp = handleUpdateDataList(state.getAllShippingsState, payload.data, 'create');

      return ({
        ...state,
        isCreateShippingRequest: false,
        isCreateShippingSuccess: true,
        isCreateShippingFailure: false,
        getAllShippingsState: { ...getAllShippingsStateTmp },
      });
    },
    [Actions.createShippingFailure]: (state, { payload }) => ({
      ...state,
      isCreateShippingRequest: false,
      isCreateShippingSuccess: false,
      isCreateShippingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateShipping]: (state) => ({
      ...state,
      isCreateShippingRequest: false,
      isCreateShippingSuccess: false,
      isCreateShippingFailure: false,
    }),
    // #endregion
    // #region : Update Shipping
    [Actions.updateShippingRequest]: (state) => ({
      ...state,
      isUpdateShippingRequest: true,
      isUpdateShippingSuccess: false,
      isUpdateShippingFailure: false,
    }),
    [Actions.updateShippingSuccess]: (state, { payload }) => {
      const getAllShippingsStateTmp = handleUpdateDataList(state.getAllShippingsState, payload.data, 'update');

      return ({
        ...state,
        isUpdateShippingRequest: false,
        isUpdateShippingSuccess: true,
        isUpdateShippingFailure: false,
        getAllShippingsState: { ...getAllShippingsStateTmp },
      });
    },
    [Actions.updateShippingFailure]: (state, { payload }) => ({
      ...state,
      isUpdateShippingRequest: false,
      isUpdateShippingSuccess: false,
      isUpdateShippingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateShipping]: (state) => ({
      ...state,
      isUpdateShippingRequest: false,
      isUpdateShippingSuccess: false,
      isUpdateShippingFailure: false,
    }),
    // #endregion
    // #region : Delete Shipping
    [Actions.deleteShippingRequest]: (state) => ({
      ...state,
      isDeleteShippingRequest: true,
      isDeleteShippingSuccess: false,
      isDeleteShippingFailure: false,
    }),
    [Actions.deleteShippingSuccess]: (state, { payload }) => {
      const getAllShippingsStateTmp = handleUpdateDataList(state.getAllShippingsState, payload, 'delete');

      return ({
        ...state,
        isDeleteShippingRequest: false,
        isDeleteShippingSuccess: true,
        isDeleteShippingFailure: false,
        getAllShippingsState: { ...getAllShippingsStateTmp },
      });
    },
    [Actions.deleteShippingFailure]: (state, { payload }) => ({
      ...state,
      isDeleteShippingRequest: false,
      isDeleteShippingSuccess: false,
      isDeleteShippingFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteShipping]: (state) => ({
      ...state,
      isDeleteShippingRequest: false,
      isDeleteShippingSuccess: false,
      isDeleteShippingFailure: false,
    }),
    // #endregion
    // #region: get list shippings
    [Actions.getListShippingsRequest]: (state) => ({
      ...state,
      isGetListShippingsRequest: true,
      isGetListShippingsSuccess: false,
      isGetDetailShippingsFailure: false,
    }),
    [Actions.getListShippingsSuccess]: (state, { payload }) => ({
      ...state,
      isGetListShippingsRequest: false,
      isGetListShippingsSuccess: true,
      isGetListShippingsFailure: false,
      listShippingsState: payload,
    }),
    [Actions.getListShippingsFailure]: (state, { payload }) => ({
      ...state,
      isGetListShippingsRequest: false,
      isGetDetailShippingsSuccess: false,
      isGetDetailShippingsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : add shippings
    [Actions.addShippingsRequest]: (state) => ({
      ...state,
      isAddShippingsRequest: true,
      isAddShippingsSuccess: false,
      isAddShippingsFailure: false,
    }),
    [Actions.addShippingsSuccess]: (state, { payload }) => ({
      ...state,
      isAddShippingsSuccess: true,
      isAddShippingsRequest: false,
      isAddShippingsFailure: false,
      responseDataAddShipping: payload,

    }),
    [Actions.addShippingsFailure]: (state, { payload }) => ({
      ...state,
      isAddShippingsRequest: false,
      isAddShippingsSuccess: false,
      isAddShippingsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddShippingsState]: (state) => ({
      ...state,
      isAddShippingsFailure: false,
      isAddShippingsRequest: false,
      isAddShippingsSuccess: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : edit shippings
    [Actions.editShippingsRequest]: (state) => ({
      ...state,
      isEditShippingsRequest: true,
      isEditShippingsSuccess: false,
      isEditShippingsFailure: false,
    }),
    [Actions.editShippingsSuccess]: (state) => ({
      ...state,
      isEditShippingsSuccess: true,
      isEditShippingsRequest: false,
      isEditShippingsFailure: false,
    }),
    [Actions.editShippingsFailure]: (state, { payload }) => ({
      ...state,
      isEditShippingsRequest: false,
      isEditShippingsSuccess: false,
      isEditShippingsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditShippingsState]: (state) => ({
      ...state,
      isEditShippingsFailure: false,
      isEditShippingsRequest: false,
      isEditShippingsSuccess: false,
    }),
    // #endregion
    // #region : Delete shippings

    [Actions.deleteShippingsRequest]: (state) => ({
      ...state,
      isDeleteShippingsRequest: true,
      isDeleteShippingsSuccess: false,
      isDeleteShippingsFailure: false,
    }),
    [Actions.deleteShippingsSuccess]: (state) => ({
      ...state,
      isDeleteShippingsSuccess: true,
      isDeleteShippingsRequest: false,
      isDeleteShippingsFailure: false,
    }),
    [Actions.deleteShippingsFailure]: (state, { payload }) => ({
      ...state,
      isDeleteShippingsRequest: false,
      isDeleteShippingsSuccess: false,
      isDeleteShippingsFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteShippingsState]: (state) => ({
      ...state,
      isDeleteShippingsFailure: false,
      isDeleteShippingsRequest: false,
      isDeleteShippingsSuccess: false,
    }),
    // #endregion

    // #region :
    [Actions.addShippingsPriceRequest]: (state) => ({
      ...state,
      isAddShippingsPriceRequest: true,
      isAddShippingsPriceSuccess: false,
      isAddShippingsPriceFailure: false,
    }),
    [Actions.addShippingsPriceSuccess]: (state) => ({
      ...state,
      isAddShippingsPriceRequest: false,
      isAddShippingsPriceSuccess: true,
      isAddShippingsPriceFailure: false,
    }),
    [Actions.addShippingsPriceFailure]: (state, { payload }) => ({
      ...state,
      isAddShippingsPriceRequest: false,
      isAddShippingsPriceSuccess: false,
      isAddShippingsPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddShippingsPriceState]: (state) => ({
      ...state,
      isAddShippingsPriceRequest: false,
      isAddShippingsPriceSuccess: false,
      isAddShippingsPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetShippingsState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
