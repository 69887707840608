/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllCarts({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/carts?${payload}`)
        : () => axiosMicro.get('/carts', { params: payload }),
    );
    yield put(Actions.getAllCartsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllCartsFailure(messages));
    }
  }
}
function* getAllHandoverCarts({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/handover-carts?${payload}`)
        : () => axiosMicro.get('/handover-carts', { params: payload }),
    );
    yield put(Actions.getAllHandoverCartSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllHandoverCartFailure(messages));
    }
  }
}

function* createCart({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/carts', payload));
    yield put(Actions.createCartSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createCartFailure(messages));
    }
  }
}

function* updateCart({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/carts/${payload.id}`, payload.body));
    yield put(Actions.updateCartSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateCartFailure(messages));
    }
  }
}

function* deleteCart({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/carts/${payload}`));
    yield put(Actions.deleteCartSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteCartFailure(messages));
    }
  }
}
function* approveCart({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/carts/${payload.id}/status`, payload.body));
    yield put(Actions.approveCartSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.approveCartFailure(messages));
    }
  }
}
function* cancelCart({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/carts/${payload.id}/cancel`));
    yield put(Actions.cancelCartSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.cancelCartFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllCartsRequest, getAllCarts);
  yield takeLatest(Actions.createCartRequest, createCart);
  yield takeLatest(Actions.updateCartRequest, updateCart);
  yield takeLatest(Actions.deleteCartRequest, deleteCart);
  yield takeLatest(Actions.getAllHandoverCartRequest, getAllHandoverCarts);
  yield takeLatest(Actions.approveCartRequest, approveCart);
  yield takeLatest(Actions.cancelCartRequest, cancelCart);
}
