// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllImportWarehousesRequest = createAction('GET_ALL_IMPORTWAREHOUSES_REQUEST');
export const getAllImportWarehousesSuccess = createAction('GET_ALL_IMPORTWAREHOUSES_SUCCESS');
export const getAllImportWarehousesFailure = createAction('GET_ALL_IMPORTWAREHOUSES_FAILURE');

export const createImportWarehouseRequest = createAction('CREATE_IMPORTWAREHOUSE_REQUEST');
export const createImportWarehouseSuccess = createAction('CREATE_IMPORTWAREHOUSE_SUCCESS');
export const createImportWarehouseFailure = createAction('CREATE_IMPORTWAREHOUSE_FAILURE');
export const resetCreateImportWarehouse = createAction('RESET_CREATE_IMPORTWAREHOUSE');

export const updateImportWarehouseRequest = createAction('UPDATE_IMPORTWAREHOUSE_REQUEST');
export const updateImportWarehouseSuccess = createAction('UPDATE_IMPORTWAREHOUSE_SUCCESS');
export const updateImportWarehouseFailure = createAction('UPDATE_IMPORTWAREHOUSE_FAILURE');
export const resetUpdateImportWarehouse = createAction('RESET_UPDATE_IMPORTWAREHOUSE');

export const deleteImportWarehouseRequest = createAction('DELETE_IMPORTWAREHOUSE_REQUEST');
export const deleteImportWarehouseSuccess = createAction('DELETE_IMPORTWAREHOUSE_SUCCESS');
export const deleteImportWarehouseFailure = createAction('DELETE_IMPORTWAREHOUSE_FAILURE');
export const resetDeleteImportWarehouse = createAction('RESET_DELETE_IMPORTWAREHOUSE');

export const considerImportWarehouseRequest = createAction('CONSIDER_IMPORTWAREHOUSE_REQUEST');
export const considerImportWarehouseSuccess = createAction('CONSIDER_IMPORTWAREHOUSE_SUCCESS');
export const considerImportWarehouseFailure = createAction('CONSIDER_IMPORTWAREHOUSE_FAILURE');
export const resetConsiderImportWarehouse = createAction('RESET_CONSIDER_IMPORTWAREHOUSE');

export const resetImportWarehouseState = createAction('RESET_IMPORTWAREHOUSE_STATE');
