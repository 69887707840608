// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllExchangeWarehousesRequest = createAction('GET_ALL_EXCHANGEWAREHOUSES_REQUEST');
export const getAllExchangeWarehousesSuccess = createAction('GET_ALL_EXCHANGEWAREHOUSES_SUCCESS');
export const getAllExchangeWarehousesFailure = createAction('GET_ALL_EXCHANGEWAREHOUSES_FAILURE');

export const getAllBatchNeedChangeRequest = createAction('GET_ALL_BATCH_NEED_CHANGE_REQUEST');
export const getAllBatchNeedChangeSuccess = createAction('GET_ALL_BATCH_NEED_CHANGE_SUCCESS');
export const getAllBatchNeedChangeFailure = createAction('GET_ALL_BATCH_NEED_CHANGE_FAILURE');

export const createExchangeWarehouseRequest = createAction('CREATE_EXCHANGEWAREHOUSE_REQUEST');
export const createExchangeWarehouseSuccess = createAction('CREATE_EXCHANGEWAREHOUSE_SUCCESS');
export const createExchangeWarehouseFailure = createAction('CREATE_EXCHANGEWAREHOUSE_FAILURE');
export const resetCreateExchangeWarehouse = createAction('RESET_CREATE_EXCHANGEWAREHOUSE');

export const updateExchangeWarehouseRequest = createAction('UPDATE_EXCHANGEWAREHOUSE_REQUEST');
export const updateExchangeWarehouseSuccess = createAction('UPDATE_EXCHANGEWAREHOUSE_SUCCESS');
export const updateExchangeWarehouseFailure = createAction('UPDATE_EXCHANGEWAREHOUSE_FAILURE');
export const resetUpdateExchangeWarehouse = createAction('RESET_UPDATE_EXCHANGEWAREHOUSE');

export const deleteExchangeWarehouseRequest = createAction('DELETE_EXCHANGEWAREHOUSE_REQUEST');
export const deleteExchangeWarehouseSuccess = createAction('DELETE_EXCHANGEWAREHOUSE_SUCCESS');
export const deleteExchangeWarehouseFailure = createAction('DELETE_EXCHANGEWAREHOUSE_FAILURE');
export const resetDeleteExchangeWarehouse = createAction('RESET_DELETE_EXCHANGEWAREHOUSE');

export const resetExchangeWarehouseState = createAction('RESET_EXCHANGEWAREHOUSE_STATE');
