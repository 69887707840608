/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/customers?include=region,branches${payload.params}` : '/customers?include=region,branches', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getListCustomerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListCustomerFailure(messages));
    }
  }
}

function* addCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/customers', payload));
    yield put(Actions.addCustomerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addCustomerFailure(messages));
    }
  }
}

function* getDetailCustomer({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/customers/${payload}`, {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getDetailCustomerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDetailCustomerFailure(messages));
    }
  }
}

function* getAllRegion({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload ? `/regions${payload}` : '/regions?search=parent_id:0&limit=0', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getAllRegionSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllRegionFailure(messages));
    }
  }
}

function* getAllWard({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload ? `/regions${payload}` : '/regions?search=parent_id:0&limit=0', {
      params: {
        timestamp: new Date().getTime(),
      },
    }));
    yield put(Actions.getAllWardSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllWardFailure(messages));
    }
  }
}

function* getAllDistrict({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload && payload < 65 ? `/regions?search=parent_id:${payload}&limit=0` : '/regions?search=parent_id:0&limit=0'));
    yield put(Actions.getAllDistrictSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllDistrictFailure(messages));
    }
  }
}

function* deleteCustomer({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/customers/${payload}`));
    yield put(Actions.deleteCustomerSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteCustomerFailure(messages));
    }
  }
}

function* editCustomer({ payload }) {
  try {
    const { id, ...data } = payload;
    const response = yield axiosMicro.patch(`/customers/${id}`, data.body);
    yield put(Actions.editCustomerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.editCustomerFailure(messages));
    }
  }
}

function* updateCustomerB({ payload }) {
  try {
    const response = yield axiosMicro.patch(`/customer-branches/${payload.id}`, payload.body);
    yield put(Actions.updateCustomerBSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateCustomerBFailure(messages));
    }
  }
}

function* getAllCustomerDebt({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/customers/debt', {
      params: payload.params,
    }));
    yield put(Actions.getAllCustomerDebtSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getAllCustomerDebtFailure(messages));
    }
  }
}

function* checkExistCustomerByCode({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/customers/list', {
      params: payload,
    }));
    yield put(Actions.checkExistCustomerByCodeSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.checkExistCustomerByCodeFailure(messages));
    }
  }
}

function* checkExistCustomerByEmail({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/customers/list', {
      params: payload,
    }));
    yield put(Actions.checkExistCustomerByEmailSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.checkExistCustomerByEmailFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListCustomerRequest, getListCustomer);
  yield takeLatest(Actions.addCustomerRequest, addCustomer);
  yield takeLatest(Actions.getDetailCustomerRequest, getDetailCustomer);
  yield takeLatest(Actions.getAllRegionRequest, getAllRegion);
  yield takeLatest(Actions.getAllWardRequest, getAllWard);
  yield takeLatest(Actions.getAllDistrictRequest, getAllDistrict);
  yield takeLatest(Actions.deleteCustomerRequest, deleteCustomer);
  yield takeLatest(Actions.editCustomerRequest, editCustomer);
  yield takeLatest(Actions.updateCustomerBRequest, updateCustomerB);
  yield takeLatest(Actions.getAllCustomerDebtRequest, getAllCustomerDebt);
  yield takeLatest(Actions.checkExistCustomerByCodeRequest, checkExistCustomerByCode);
  yield takeLatest(Actions.checkExistCustomerByEmailRequest, checkExistCustomerByEmail);
}
