/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllProductBags({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-bags?${payload}`)
        : () => axiosMicro.get('/product-bags', { params: payload }),
    );
    yield put(Actions.getAllProductBagsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllProductBagsFailure(messages));
    }
  }
}

function* createProductBag({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-bag', payload));
    yield put(Actions.createProductBagSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createProductBagFailure(messages));
    }
  }
}
function* createProductBagMulti({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-bags', payload));
    yield put(Actions.createProductBagSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createProductBagFailure(messages));
    }
  }
}

function* updateProductBag({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/product-bags/${payload.id}`, payload.body));
    yield put(Actions.updateProductBagSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateProductBagFailure(messages));
    }
  }
}

function* deleteProductBag({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/product-bags/${payload}`));
    yield put(Actions.deleteProductBagSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteProductBagFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllProductBagsRequest, getAllProductBags);
  yield takeLatest(Actions.createProductBagRequest, createProductBag);
  yield takeLatest(Actions.createProductBagMultiRequest, createProductBagMulti);
  yield takeLatest(Actions.updateProductBagRequest, updateProductBag);
  yield takeLatest(Actions.deleteProductBagRequest, deleteProductBag);
}
