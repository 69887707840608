// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All ExchangeWarehouse
  isFirstGetAllExchangeWarehouses: false,
  isGetAllExchangeWarehousesRequest: false,
  isGetAllExchangeWarehousesSuccess: false,
  isGetAllExchangeWarehousesFailure: false,
  getAllExchangeWarehousesState: {},
  //
  isGetAllBatchNeedChangeRequest: false,
  isGetAllBatchNeedChangeSuccess: false,
  isGetAllBatchNeedChangeFailure: false,
  getAllBatchNeedChangeState: {},
  // Create ExchangeWarehouse
  isCreateExchangeWarehouseRequest: false,
  isCreateExchangeWarehouseSuccess: false,
  isCreateExchangeWarehouseFailure: false,
  // Update ExchangeWarehouse
  isUpdateExchangeWarehouseRequest: false,
  isUpdateExchangeWarehouseSuccess: false,
  isUpdateExchangeWarehouseFailure: false,
  // Delete ExchangeWarehouse
  isDeleteExchangeWarehouseRequest: false,
  isDeleteExchangeWarehouseSuccess: false,
  isDeleteExchangeWarehouseFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All ExchangeWarehouse
    [Actions.getAllExchangeWarehousesRequest]: (state) => ({
      ...state,
      isGetAllExchangeWarehousesRequest: true,
      isGetAllExchangeWarehousesSuccess: false,
      isGetAllExchangeWarehousesFailure: false,
    }),
    [Actions.getAllExchangeWarehousesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllExchangeWarehouses: true,
      isGetAllExchangeWarehousesRequest: false,
      isGetAllExchangeWarehousesSuccess: true,
      isGetAllExchangeWarehousesFailure: false,
      getAllExchangeWarehousesState: payload,
    }),
    [Actions.getAllExchangeWarehousesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllExchangeWarehousesRequest: false,
      isGetAllExchangeWarehousesSuccess: false,
      isGetAllExchangeWarehousesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create ExchangeWarehouse
    [Actions.createExchangeWarehouseRequest]: (state) => ({
      ...state,
      isCreateExchangeWarehouseRequest: true,
      isCreateExchangeWarehouseSuccess: false,
      isCreateExchangeWarehouseFailure: false,
    }),
    [Actions.createExchangeWarehouseSuccess]: (state, { payload }) => {
      const getAllExchangeWarehousesStateTmp = handleUpdateDataList(state.getAllExchangeWarehousesState, payload.data, 'create');

      return ({
        ...state,
        isCreateExchangeWarehouseRequest: false,
        isCreateExchangeWarehouseSuccess: true,
        isCreateExchangeWarehouseFailure: false,
        getAllExchangeWarehousesState: { ...getAllExchangeWarehousesStateTmp },
      });
    },
    [Actions.createExchangeWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isCreateExchangeWarehouseRequest: false,
      isCreateExchangeWarehouseSuccess: false,
      isCreateExchangeWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateExchangeWarehouse]: (state) => ({
      ...state,
      isCreateExchangeWarehouseRequest: false,
      isCreateExchangeWarehouseSuccess: false,
      isCreateExchangeWarehouseFailure: false,
    }),
    // #endregion
    // #region : Update ExchangeWarehouse
    [Actions.updateExchangeWarehouseRequest]: (state) => ({
      ...state,
      isUpdateExchangeWarehouseRequest: true,
      isUpdateExchangeWarehouseSuccess: false,
      isUpdateExchangeWarehouseFailure: false,
    }),
    [Actions.updateExchangeWarehouseSuccess]: (state, { payload }) => {
      const getAllExchangeWarehousesStateTmp = handleUpdateDataList(state.getAllExchangeWarehousesState, payload.data, 'update');

      return ({
        ...state,
        isUpdateExchangeWarehouseRequest: false,
        isUpdateExchangeWarehouseSuccess: true,
        isUpdateExchangeWarehouseFailure: false,
        getAllExchangeWarehousesState: { ...getAllExchangeWarehousesStateTmp },
      });
    },
    [Actions.updateExchangeWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isUpdateExchangeWarehouseRequest: false,
      isUpdateExchangeWarehouseSuccess: false,
      isUpdateExchangeWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateExchangeWarehouse]: (state) => ({
      ...state,
      isUpdateExchangeWarehouseRequest: false,
      isUpdateExchangeWarehouseSuccess: false,
      isUpdateExchangeWarehouseFailure: false,
    }),
    // #endregion
    // #region : Delete ExchangeWarehouse
    [Actions.deleteExchangeWarehouseRequest]: (state) => ({
      ...state,
      isDeleteExchangeWarehouseRequest: true,
      isDeleteExchangeWarehouseSuccess: false,
      isDeleteExchangeWarehouseFailure: false,
    }),
    [Actions.deleteExchangeWarehouseSuccess]: (state, { payload }) => {
      const getAllExchangeWarehousesStateTmp = handleUpdateDataList(state.getAllExchangeWarehousesState, payload, 'delete');

      return ({
        ...state,
        isDeleteExchangeWarehouseRequest: false,
        isDeleteExchangeWarehouseSuccess: true,
        isDeleteExchangeWarehouseFailure: false,
        getAllExchangeWarehousesState: { ...getAllExchangeWarehousesStateTmp },
      });
    },
    [Actions.deleteExchangeWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isDeleteExchangeWarehouseRequest: false,
      isDeleteExchangeWarehouseSuccess: false,
      isDeleteExchangeWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteExchangeWarehouse]: (state) => ({
      ...state,
      isDeleteExchangeWarehouseRequest: false,
      isDeleteExchangeWarehouseSuccess: false,
      isDeleteExchangeWarehouseFailure: false,
    }),
    // #endregion

    // #region
    [Actions.getAllBatchNeedChangeRequest]: (state) => ({
      ...state,
      isGetAllBatchNeedChangeRequest: true,
      isGetAllBatchNeedChangeSuccess: false,
      isGetAllBatchNeedChangeFailure: false,
    }),
    [Actions.getAllBatchNeedChangeSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllBatchNeedChangeRequest: false,
      isGetAllBatchNeedChangeSuccess: true,
      isGetAllBatchNeedChangeFailure: false,
      getAllBatchNeedChangeState: payload,
    }),
    [Actions.getAllBatchNeedChangeFailure]: (state, { payload }) => ({
      ...state,
      isGetAllBatchNeedChangeRequest: false,
      isGetAllBatchNeedChangeSuccess: false,
      isGetAllBatchNeedChangeFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.resetExchangeWarehouseState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
