// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All PublicOrder
  isFirstGetAllPublicOrders: false,
  isGetAllPublicOrdersRequest: false,
  isGetAllPublicOrdersSuccess: false,
  isGetAllPublicOrdersFailure: false,
  getAllPublicOrdersState: {},
  // Create PublicOrder
  isCreatePublicOrderRequest: false,
  isCreatePublicOrderSuccess: false,
  isCreatePublicOrderFailure: false,
  // Update PublicOrder
  isUpdatePublicOrderRequest: false,
  isUpdatePublicOrderSuccess: false,
  isUpdatePublicOrderFailure: false,
  // Delete PublicOrder
  isDeletePublicOrderRequest: false,
  isDeletePublicOrderSuccess: false,
  isDeletePublicOrderFailure: false,
  //
  isUpdateStatusPublicOrderRequest: false,
  isUpdateStatusPublicOrderSuccess: false,
  isUpdateStatusPublicOrderFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All PublicOrder
    [Actions.getAllPublicOrdersRequest]: (state) => ({
      ...state,
      isGetAllPublicOrdersRequest: true,
      isGetAllPublicOrdersSuccess: false,
      isGetAllPublicOrdersFailure: false,
    }),
    [Actions.getAllPublicOrdersSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllPublicOrders: true,
      isGetAllPublicOrdersRequest: false,
      isGetAllPublicOrdersSuccess: true,
      isGetAllPublicOrdersFailure: false,
      getAllPublicOrdersState: payload,
    }),
    [Actions.getAllPublicOrdersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllPublicOrdersRequest: false,
      isGetAllPublicOrdersSuccess: false,
      isGetAllPublicOrdersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create PublicOrder
    [Actions.createPublicOrderRequest]: (state) => ({
      ...state,
      isCreatePublicOrderRequest: true,
      isCreatePublicOrderSuccess: false,
      isCreatePublicOrderFailure: false,
    }),
    [Actions.createPublicOrderSuccess]: (state, { payload }) => {
      const getAllPublicOrdersStateTmp = handleUpdateDataList(state.getAllPublicOrdersState, payload.data, 'create');

      return ({
        ...state,
        isCreatePublicOrderRequest: false,
        isCreatePublicOrderSuccess: true,
        isCreatePublicOrderFailure: false,
        getAllPublicOrdersState: { ...getAllPublicOrdersStateTmp },
      });
    },
    [Actions.createPublicOrderFailure]: (state, { payload }) => ({
      ...state,
      isCreatePublicOrderRequest: false,
      isCreatePublicOrderSuccess: false,
      isCreatePublicOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePublicOrder]: (state) => ({
      ...state,
      isCreatePublicOrderRequest: false,
      isCreatePublicOrderSuccess: false,
      isCreatePublicOrderFailure: false,
    }),
    // #endregion
    // #region : Update PublicOrder
    [Actions.updatePublicOrderRequest]: (state) => ({
      ...state,
      isUpdatePublicOrderRequest: true,
      isUpdatePublicOrderSuccess: false,
      isUpdatePublicOrderFailure: false,
    }),
    [Actions.updatePublicOrderSuccess]: (state, { payload }) => {
      const getAllPublicOrdersStateTmp = handleUpdateDataList(state.getAllPublicOrdersState, payload.data, 'update');

      return ({
        ...state,
        isUpdatePublicOrderRequest: false,
        isUpdatePublicOrderSuccess: true,
        isUpdatePublicOrderFailure: false,
        getAllPublicOrdersState: { ...getAllPublicOrdersStateTmp },
      });
    },
    [Actions.updatePublicOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdatePublicOrderRequest: false,
      isUpdatePublicOrderSuccess: false,
      isUpdatePublicOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdatePublicOrder]: (state) => ({
      ...state,
      isUpdatePublicOrderRequest: false,
      isUpdatePublicOrderSuccess: false,
      isUpdatePublicOrderFailure: false,
    }),
    // #endregion
    // #region : Delete PublicOrder
    [Actions.deletePublicOrderRequest]: (state) => ({
      ...state,
      isDeletePublicOrderRequest: true,
      isDeletePublicOrderSuccess: false,
      isDeletePublicOrderFailure: false,
    }),
    [Actions.deletePublicOrderSuccess]: (state, { payload }) => {
      const getAllPublicOrdersStateTmp = handleUpdateDataList(state.getAllPublicOrdersState, payload, 'delete');

      return ({
        ...state,
        isDeletePublicOrderRequest: false,
        isDeletePublicOrderSuccess: true,
        isDeletePublicOrderFailure: false,
        getAllPublicOrdersState: { ...getAllPublicOrdersStateTmp },
      });
    },
    [Actions.deletePublicOrderFailure]: (state, { payload }) => ({
      ...state,
      isDeletePublicOrderRequest: false,
      isDeletePublicOrderSuccess: false,
      isDeletePublicOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeletePublicOrder]: (state) => ({
      ...state,
      isDeletePublicOrderRequest: false,
      isDeletePublicOrderSuccess: false,
      isDeletePublicOrderFailure: false,
    }),
    // #endregion

    // #region
    [Actions.updateStatusPublicOrderRequest]: (state) => ({
      ...state,
      isUpdateStatusPublicOrderRequest: true,
      isUpdateStatusPublicOrderSuccess: false,
      isUpdateStatusPublicOrderFailure: false,
    }),
    [Actions.updateStatusPublicOrderSuccess]: (state) => ({
      ...state,
      isUpdateStatusPublicOrderRequest: false,
      isUpdateStatusPublicOrderSuccess: true,
      isUpdateStatusPublicOrderFailure: false,
    }),
    [Actions.updateStatusPublicOrderFailure]: (state, { payload }) => ({
      ...state,
      isUpdateStatusPublicOrderRequest: false,
      isUpdateStatusPublicOrderSuccess: false,
      isUpdateStatusPublicOrderFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateStatusPublicOrder]: (state) => ({
      ...state,
      isUpdateStatusPublicOrderRequest: false,
      isUpdateStatusPublicOrderSuccess: false,
      isUpdateStatusPublicOrderFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region : Local
    [Actions.resetPublicOrderState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
