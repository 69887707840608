/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getProductPricesList({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params
        ? `/product-prices?include=product,regionGroup&${payload.params}`
        : '/product-prices?include=product,regionGroup',
    ));
    yield put(Actions.getProductPricesListSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getProductPricesListFailure(messages));
    }
  }
}

function* createProductPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-prices', payload));
    yield put(Actions.createProductPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createProductPriceFailure(messages));
    }
  }
}

function* updateProductPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/product-prices/${payload.id}`, payload.params));
    yield put(Actions.updateProductPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateProductPriceFailure(messages));
    }
  }
}

function* deleteProductPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/product-prices/${payload}`));
    yield put(Actions.deleteProductPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteProductPriceFailure(messages));
    }
  }
}

function* addProductPriceRoad({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-prices/save', { ...payload, type: 'road' }));
    yield put(Actions.addProductPriceRoadSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addProductPriceRoadFailure(messages));
    }
  }
}

function* addProductPricePlane({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-prices/save', { ...payload, type: 'plane' }));
    yield put(Actions.addProductPricePlaneSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addProductPricePlaneFailure(messages));
    }
  }
}

function* addProductPrice({ payload }) {
  try {
    if (payload.road.prices.length > 0) {
      const payloadDup = { ...payload.road };
      if (payload['road-company'].prices.length > 0) {
        payloadDup.company = payload['road-company'].prices;
      }
      yield call(() => axiosMicro.post('/product-prices/save', payloadDup));
    }
    if (payload.plane.prices.length > 0) {
      const payloadDup = { ...payload.plane };
      if (payload['plane-company'].prices.length > 0) {
        payloadDup.company = payload['plane-company'].prices;
      }
      yield call(() => axiosMicro.post('/product-prices/save', payloadDup));
    }
    yield put(Actions.addProductPriceSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addProductPriceFailure(messages));
    }
  }
}

function* getDateProductPrice({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params
        ? `/product-prices/date?${payload.params}`
        : '/product-prices/date',
    ));
    yield put(Actions.getDateProductPriceSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDateProductPriceFailure(messages));
    }
  }
}

function* findProductPriceByDate({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/product-prices/${payload.data}/${payload.time}`));
    yield put(Actions.findProductPriceByDateSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.findProductPriceByDateFailure(messages));
    }
  }
}

function* findProductPriceByDateNow({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/product-prices/${payload.data}/${payload.time}`));
    yield put(Actions.findProductPriceByDateNowSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.findProductPriceByDateNowFailure(messages));
    }
  }
}

function* findProductPriceByDateFuture({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/product-prices/${payload.data}/${payload.time}`));
    yield put(Actions.findProductPriceByDateFutureSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.findProductPriceByDateFutureFailure(messages));
    }
  }
}

function* deleteProductPriceByDate({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/product-prices/${payload}`));
    yield put(Actions.deleteProductPriceByDateSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteProductPriceByDateFailure(messages));
    }
  }
}

function* getAllProductSizesPricesList({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-sizes/list?${payload}`)
        : () => axiosMicro.get('/product-sizes/list', { params: payload }),
    );
    yield put(Actions.getAllProductSizesPricesListSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllProductSizesPricesListFailure(messages));
    }
  }
}

function* getAllProductSizesPricesListNow({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-sizes/list?${payload}`)
        : () => axiosMicro.get('/product-sizes/list', { params: payload }),
    );
    yield put(Actions.getAllProductSizesPricesListNowSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllProductSizesPricesListNowFailure(messages));
    }
  }
}

function* getAllProductSizesPricesListFuture({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/product-sizes/list?${payload}`)
        : () => axiosMicro.get('/product-sizes/list', { params: payload }),
    );
    yield put(Actions.getAllProductSizesPricesListFutureSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllProductSizesPricesListFutureFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getProductPricesListRequest, getProductPricesList);
  yield takeLatest(Actions.createProductPriceRequest, createProductPrice);
  yield takeLatest(Actions.updateProductPriceRequest, updateProductPrice);
  yield takeLatest(Actions.deleteProductPriceRequest, deleteProductPrice);
  yield takeLatest(Actions.addProductPriceRoadRequest, addProductPriceRoad);
  yield takeLatest(Actions.addProductPricePlaneRequest, addProductPricePlane);
  yield takeLatest(Actions.addProductPriceRequest, addProductPrice);
  yield takeLatest(Actions.getDateProductPriceRequest, getDateProductPrice);
  yield takeLatest(Actions.findProductPriceByDateRequest, findProductPriceByDate);
  yield takeLatest(Actions.findProductPriceByDateNowRequest, findProductPriceByDateNow);
  yield takeLatest(Actions.findProductPriceByDateFutureRequest, findProductPriceByDateFuture);
  yield takeLatest(Actions.deleteProductPriceByDateRequest, deleteProductPriceByDate);
  yield takeLatest(Actions.getAllProductSizesPricesListRequest, getAllProductSizesPricesList);
  yield takeLatest(Actions.getAllProductSizesPricesListNowRequest, getAllProductSizesPricesListNow);
  yield takeLatest(Actions.getAllProductSizesPricesListFutureRequest, getAllProductSizesPricesListFuture);
}
