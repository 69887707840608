/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllBatches({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/batches?${payload}`)
        : () => axiosMicro.get('/batches', { params: payload }),
    );
    yield put(Actions.getAllBatchesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllBatchesFailure(messages));
    }
  }
}

function* createBatch({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/batches', payload));
    yield put(Actions.createBatchSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createBatchFailure(messages));
    }
  }
}

function* updateBatch({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/batches/${payload.id}`, payload.body));
    yield put(Actions.updateBatchSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateBatchFailure(messages));
    }
  }
}

function* deleteBatch({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/batches/${payload}`));
    yield put(Actions.deleteBatchSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteBatchFailure(messages));
    }
  }
}

function* getListBatches({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/batches/list?${payload}`)
        : () => axiosMicro.get('/batches/list', { params: payload }),
    );
    yield put(Actions.getListBatchesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getListBatchesFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllBatchesRequest, getAllBatches);
  yield takeLatest(Actions.createBatchRequest, createBatch);
  yield takeLatest(Actions.updateBatchRequest, updateBatch);
  yield takeLatest(Actions.deleteBatchRequest, deleteBatch);
  yield takeLatest(Actions.getListBatchesRequest, getListBatches);
}
