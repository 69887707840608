/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllDrivers({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/drivers?${payload}`)
        : () => axiosMicro.get('/drivers', { params: payload }),
    );
    yield put(Actions.getAllDriversSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllDriversFailure(messages));
    }
  }
}

function* createDriver({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/drivers', payload));
    yield put(Actions.createDriverSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createDriverFailure(messages));
    }
  }
}

function* updateDriver({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/drivers/${payload.id}`, payload.body));
    yield put(Actions.updateDriverSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateDriverFailure(messages));
    }
  }
}

function* deleteDriver({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/drivers/${payload}`));
    yield put(Actions.deleteDriverSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteDriverFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllDriversRequest, getAllDrivers);
  yield takeLatest(Actions.createDriverRequest, createDriver);
  yield takeLatest(Actions.updateDriverRequest, updateDriver);
  yield takeLatest(Actions.deleteDriverRequest, deleteDriver);
}
