/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllExports({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/exports?${payload}`)
        : () => axiosMicro.get('/exports', { params: payload }),
    );
    yield put(Actions.getAllExportProductsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllExportProductsFailure(messages));
    }
  }
}

function* createExport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/exports', payload));
    yield put(Actions.createExportProductSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createExportProductFailure(messages));
    }
  }
}

function* cancelExport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/exports/${payload.id}/cancel`));
    yield put(Actions.cancelExportProductSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.cancelExportProductFailure(messages));
    }
  }
}

function* updateStatusExport({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/exports/${payload.id}/status`, payload.body));
    yield put(Actions.updateStatusExportProductSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateStatusExportProductFailure(messages));
    }
  }
}

function* updateExportProduct({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/exports/${payload.id}`, payload.body));
    yield put(Actions.updateExportProductSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateExportProductFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllExportProductsRequest, getAllExports);
  yield takeLatest(Actions.createExportProductRequest, createExport);
  yield takeLatest(Actions.cancelExportProductRequest, cancelExport);
  yield takeLatest(Actions.updateStatusExportProductRequest, updateStatusExport);
  yield takeLatest(Actions.updateExportProductRequest, updateExportProduct);
}
