/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllExchangeWarehouses({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/exchanges?${payload}`)
        : () => axiosMicro.get('/exchanges', { params: payload }),
    );
    yield put(Actions.getAllExchangeWarehousesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllExchangeWarehousesFailure(messages));
    }
  }
}

function* getAllBatchNeedChange({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get('/batches/list', { params: payload }));
    yield put(Actions.getAllBatchNeedChangeSuccess(response?.data));
  } catch (error) {
    if (error.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllBatchNeedChangeFailure(messages));
    }
  }
}

function* createExchangeWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/exchanges', payload));
    yield put(Actions.createExchangeWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createExchangeWarehouseFailure(messages));
    }
  }
}

function* updateExchangeWarehouse({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/exchanges/${payload.id}`, payload.body));
    yield put(Actions.updateExchangeWarehouseSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateExchangeWarehouseFailure(messages));
    }
  }
}

function* deleteExchangeWarehouse({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/exchanges/${payload}/cancel`));
    yield put(Actions.deleteExchangeWarehouseSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteExchangeWarehouseFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllExchangeWarehousesRequest, getAllExchangeWarehouses);
  yield takeLatest(Actions.createExchangeWarehouseRequest, createExchangeWarehouse);
  yield takeLatest(Actions.updateExchangeWarehouseRequest, updateExchangeWarehouse);
  yield takeLatest(Actions.deleteExchangeWarehouseRequest, deleteExchangeWarehouse);
  yield takeLatest(Actions.getAllBatchNeedChangeRequest, getAllBatchNeedChange);
}
