/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListProductTypes({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(
      payload?.params ? `/product-categories?${payload.params}` : '/product-categories',
    ));
    yield put(Actions.getListProductTypesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListProductTypesFailure(messages));
    }
  }
}

function* createProductType({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/product-categories', payload));
    yield put(Actions.createProductTypeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createProductTypeFailure(messages));
    }
  }
}

function* updateProductType({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post(`/product-categories/${payload.id}?_method=PATCH`, payload.params));
    yield put(Actions.updateProductTypeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.updateProductTypeFailure(messages));
    }
  }
}

function* deleteProductType({ payload }) {
  try {
    const response = yield call(() => axiosMicro.delete(`/product-categories/${payload}`));
    yield put(Actions.deleteProductTypeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteProductTypeFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListProductTypesRequest, getListProductTypes);
  yield takeLatest(Actions.createProductTypeRequest, createProductType);
  yield takeLatest(Actions.updateProductTypeRequest, updateProductType);
  yield takeLatest(Actions.deleteProductTypeRequest, deleteProductType);
}
