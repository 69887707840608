/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllLateReviews({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/late-reviews?${payload}`)
        : () => axiosMicro.get('/late-reviews', { params: payload }),
    );
    yield put(Actions.getAllLateReviewsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllLateReviewsFailure(messages));
    }
  }
}

function* createLateReview({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/late-reviews', payload));
    yield put(Actions.createLateReviewSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createLateReviewFailure(messages));
    }
  }
}

function* updateLateReview({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/late-reviews/${payload.id}`, payload.body));
    yield put(Actions.updateLateReviewSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateLateReviewFailure(messages));
    }
  }
}

function* deleteLateReview({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/late-reviews/${payload}`));
    yield put(Actions.deleteLateReviewSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteLateReviewFailure(messages));
    }
  }
}

function* changeStatusLateReview({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/late-reviews/${payload.id}/status`, payload.body));
    yield put(Actions.changeStatusLateReviewSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.changeStatusLateReviewFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllLateReviewsRequest, getAllLateReviews);
  yield takeLatest(Actions.createLateReviewRequest, createLateReview);
  yield takeLatest(Actions.updateLateReviewRequest, updateLateReview);
  yield takeLatest(Actions.deleteLateReviewRequest, deleteLateReview);
  yield takeLatest(Actions.changeStatusLateReviewRequest, changeStatusLateReview);
}
