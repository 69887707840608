// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
// import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All ExportWarehouse
  isFirstGetAllExportWarehouses: false,
  isGetAllExportWarehousesRequest: false,
  isGetAllExportWarehousesSuccess: false,
  isGetAllExportWarehousesFailure: false,
  getAllExportWarehousesState: {},
  // Create ExportWarehouse
  isCreateExportWarehouseRequest: false,
  isCreateExportWarehouseSuccess: false,
  isCreateExportWarehouseFailure: false,
  // Cancel ExportWarehouse
  isCancelExportWarehouseRequest: false,
  isCancelExportWarehouseSuccess: false,
  isCancelExportWarehouseFailure: false,
  // Update ExportWarehouse
  isUpdateExportWarehouseRequest: false,
  isUpdateExportWarehouseSuccess: false,
  isUpdateExportWarehouseFailure: false,
  // Delete ExportWarehouse
  isDeleteExportWarehouseRequest: false,
  isDeleteExportWarehouseSuccess: false,
  isDeleteExportWarehouseFailure: false,
  // Get order export warehouse
  isGetOrderExportWarehouseRequest: false,
  isGetOrderExportWarehouseSuccess: false,
  isGetOrderExportWarehouseFailure: false,
  getOrderExportWarehouseState: {},
  // Get list order export warehouse
  isGetListOrderExportWarehouseRequest: false,
  isGetListOrderExportWarehouseSuccess: false,
  isGetListOrderExportWarehouseFailure: false,
  getListOrderExportWarehouseState: {},
  // Consider export warehouse
  isConsiderExportWarehouseRequest: false,
  isConsiderExportWarehouseSuccess: false,
  isConsiderExportWarehouseFailure: false,
  // Customer export warehouse
  isGetCustomerExportWarehouseRequest: false,
  isGetCustomerExportWarehouseSuccess: false,
  isGetCustomerExportWarehouseFailure: false,
  getCustomerExportWarehouseState: {},
  // Export driver
  isExportDriverWarehousesRequest: false,
  isExportDriverWarehousesSuccess: false,
  isExportDriverWarehousesFailure: false,

  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All ExportWarehouse
    [Actions.getAllExportWarehousesRequest]: (state) => ({
      ...state,
      isGetAllExportWarehousesRequest: true,
      isGetAllExportWarehousesSuccess: false,
      isGetAllExportWarehousesFailure: false,
    }),
    [Actions.getAllExportWarehousesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllExportWarehouses: true,
      isGetAllExportWarehousesRequest: false,
      isGetAllExportWarehousesSuccess: true,
      isGetAllExportWarehousesFailure: false,
      getAllExportWarehousesState: payload,
    }),
    [Actions.getAllExportWarehousesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllExportWarehousesRequest: false,
      isGetAllExportWarehousesSuccess: false,
      isGetAllExportWarehousesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create ExportWarehouse
    [Actions.createExportWarehouseRequest]: (state) => ({
      ...state,
      isCreateExportWarehouseRequest: true,
      isCreateExportWarehouseSuccess: false,
      isCreateExportWarehouseFailure: false,
    }),
    [Actions.createExportWarehouseSuccess]: (state) => ({
      ...state,
      isCreateExportWarehouseRequest: false,
      isCreateExportWarehouseSuccess: true,
      isCreateExportWarehouseFailure: false,
    }),
    [Actions.createExportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isCreateExportWarehouseRequest: false,
      isCreateExportWarehouseSuccess: false,
      isCreateExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateExportWarehouse]: (state) => ({
      ...state,
      isCreateExportWarehouseRequest: false,
      isCreateExportWarehouseSuccess: false,
      isCreateExportWarehouseFailure: false,
    }),
    // #endregion
    // #region : Cancel ExportWarehouse
    [Actions.cancelExportWarehouseRequest]: (state) => ({
      ...state,
      isCancelExportWarehouseRequest: true,
      isCancelExportWarehouseSuccess: false,
      isCancelExportWarehouseFailure: false,
    }),
    [Actions.cancelExportWarehouseSuccess]: (state) => ({
      ...state,
      isCancelExportWarehouseRequest: false,
      isCancelExportWarehouseSuccess: true,
      isCancelExportWarehouseFailure: false,
    }),
    [Actions.cancelExportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isCancelExportWarehouseRequest: false,
      isCancelExportWarehouseSuccess: false,
      isCancelExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelExportWarehouse]: (state) => ({
      ...state,
      isCancelExportWarehouseRequest: false,
      isCancelExportWarehouseSuccess: false,
      isCancelExportWarehouseFailure: false,
    }),
    // #endregion
    // #region : Update ExportWarehouse
    [Actions.updateExportWarehouseRequest]: (state) => ({
      ...state,
      isUpdateExportWarehouseRequest: true,
      isUpdateExportWarehouseSuccess: false,
      isUpdateExportWarehouseFailure: false,
    }),
    [Actions.updateExportWarehouseSuccess]: (state) => ({
      ...state,
      isUpdateExportWarehouseRequest: false,
      isUpdateExportWarehouseSuccess: true,
      isUpdateExportWarehouseFailure: false,
    }),
    [Actions.updateExportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isUpdateExportWarehouseRequest: false,
      isUpdateExportWarehouseSuccess: false,
      isUpdateExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateExportWarehouse]: (state) => ({
      ...state,
      isUpdateExportWarehouseRequest: false,
      isUpdateExportWarehouseSuccess: false,
      isUpdateExportWarehouseFailure: false,
    }),
    // #endregion
    // #region : Delete ExportWarehouse
    [Actions.deleteExportWarehouseRequest]: (state) => ({
      ...state,
      isDeleteExportWarehouseRequest: true,
      isDeleteExportWarehouseSuccess: false,
      isDeleteExportWarehouseFailure: false,
    }),
    [Actions.deleteExportWarehouseSuccess]: (state) => ({
      ...state,
      isDeleteExportWarehouseRequest: false,
      isDeleteExportWarehouseSuccess: true,
      isDeleteExportWarehouseFailure: false,
    }),
    [Actions.deleteExportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isDeleteExportWarehouseRequest: false,
      isDeleteExportWarehouseSuccess: false,
      isDeleteExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteExportWarehouse]: (state) => ({
      ...state,
      isDeleteExportWarehouseRequest: false,
      isDeleteExportWarehouseSuccess: false,
      isDeleteExportWarehouseFailure: false,
    }),
    // #endregion

    // #region Get order export warehouse
    [Actions.getOrderExportWarehouseRequest]: (state) => ({
      ...state,
      isGetOrderExportWarehouseRequest: true,
      isGetOrderExportWarehouseSuccess: false,
      isGetOrderExportWarehouseFailure: false,
    }),
    [Actions.getOrderExportWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetOrderExportWarehouseRequest: false,
      isGetOrderExportWarehouseSuccess: true,
      isGetOrderExportWarehouseFailure: false,
      getOrderExportWarehouseState: payload,
    }),
    [Actions.getOrderExportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetOrderExportWarehouseRequest: false,
      isGetOrderExportWarehouseSuccess: false,
      isGetOrderExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Get list order export warehouse
    [Actions.getListOrderExportWarehouseRequest]: (state) => ({
      ...state,
      isGetListOrderExportWarehouseRequest: true,
      isGetListOrderExportWarehouseSuccess: false,
      isGetListOrderExportWarehouseFailure: false,
    }),
    [Actions.getListOrderExportWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetListOrderExportWarehouseRequest: false,
      isGetListOrderExportWarehouseSuccess: true,
      isGetListOrderExportWarehouseFailure: false,
      getListOrderExportWarehouseState: payload,
    }),
    [Actions.getListOrderExportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetListOrderExportWarehouseRequest: false,
      isGetListOrderExportWarehouseSuccess: false,
      isGetListOrderExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Consider export warehouse
    [Actions.considerExportWarehouseRequest]: (state) => ({
      ...state,
      isConsiderExportWarehouseRequest: true,
      isConsiderExportWarehouseSuccess: false,
      isConsiderExportWarehouseFailure: false,
    }),
    [Actions.considerExportWarehouseSuccess]: (state) => ({
      ...state,
      isConsiderExportWarehouseRequest: false,
      isConsiderExportWarehouseSuccess: true,
      isConsiderExportWarehouseFailure: false,
    }),
    [Actions.considerExportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isConsiderExportWarehouseRequest: false,
      isConsiderExportWarehouseSuccess: false,
      isConsiderExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetConsiderExportWarehouse]: (state) => ({
      ...state,
      isConsiderExportWarehouseRequest: false,
      isConsiderExportWarehouseSuccess: false,
      isConsiderExportWarehouseFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Get customer export warehouse
    [Actions.getCustomerExportWarehouseRequest]: (state) => ({
      ...state,
      isGetCustomerExportWarehouseRequest: true,
      isGetCustomerExportWarehouseSuccess: false,
      isGetCustomerExportWarehouseFailure: false,
    }),
    [Actions.getCustomerExportWarehouseSuccess]: (state, { payload }) => ({
      ...state,
      isGetCustomerExportWarehouseRequest: false,
      isGetCustomerExportWarehouseSuccess: true,
      isGetCustomerExportWarehouseFailure: false,
      getCustomerExportWarehouseState: payload,
    }),
    [Actions.getCustomerExportWarehouseFailure]: (state, { payload }) => ({
      ...state,
      isGetCustomerExportWarehouseRequest: false,
      isGetCustomerExportWarehouseSuccess: false,
      isGetCustomerExportWarehouseFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Export driver
    [Actions.exportDriverWarehousesRequest]: (state) => ({
      ...state,
      isExportDriverWarehousesRequest: true,
      isExportDriverWarehousesSuccess: false,
      isExportDriverWarehousesFailure: false,
    }),
    [Actions.exportDriverWarehousesSuccess]: (state) => ({
      ...state,
      isExportDriverWarehousesRequest: false,
      isExportDriverWarehousesSuccess: true,
      isExportDriverWarehousesFailure: false,
    }),
    [Actions.exportDriverWarehousesFailure]: (state, { payload }) => ({
      ...state,
      isExportDriverWarehousesRequest: false,
      isExportDriverWarehousesSuccess: false,
      isExportDriverWarehousesFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetExportDriverWarehouses]: (state) => ({
      ...state,
      isExportDriverWarehousesRequest: false,
      isExportDriverWarehousesSuccess: false,
      isExportDriverWarehousesFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region : Local
    [Actions.resetExportWarehouseState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
