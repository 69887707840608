// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllBatchesRequest = createAction('GET_ALL_BATCHES_REQUEST');
export const getAllBatchesSuccess = createAction('GET_ALL_BATCHES_SUCCESS');
export const getAllBatchesFailure = createAction('GET_ALL_BATCHES_FAILURE');

export const createBatchRequest = createAction('CREATE_BATCH_REQUEST');
export const createBatchSuccess = createAction('CREATE_BATCH_SUCCESS');
export const createBatchFailure = createAction('CREATE_BATCH_FAILURE');
export const resetCreateBatch = createAction('RESET_CREATE_BATCH');

export const updateBatchRequest = createAction('UPDATE_BATCH_REQUEST');
export const updateBatchSuccess = createAction('UPDATE_BATCH_SUCCESS');
export const updateBatchFailure = createAction('UPDATE_BATCH_FAILURE');
export const resetUpdateBatch = createAction('RESET_UPDATE_BATCH');

export const deleteBatchRequest = createAction('DELETE_BATCH_REQUEST');
export const deleteBatchSuccess = createAction('DELETE_BATCH_SUCCESS');
export const deleteBatchFailure = createAction('DELETE_BATCH_FAILURE');
export const resetDeleteBatch = createAction('RESET_DELETE_BATCH');

export const getListBatchesRequest = createAction('GET_LIST_BATCHES_REQUEST');
export const getListBatchesSuccess = createAction('GET_LIST_BATCHES_SUCCESS');
export const getListBatchesFailure = createAction('GET_LIST_BATCHES_FAILURE');

export const resetBatchState = createAction('RESET_BATCH_STATE');
