/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllResizes({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/resizes?${payload}`)
        : () => axiosMicro.get('/resizes', { params: payload }),
    );
    yield put(Actions.getAllResizesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllResizesFailure(messages));
    }
  }
}

function* createResize({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/resizes', payload));
    yield put(Actions.createResizeSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createResizeFailure(messages));
    }
  }
}

function* updateResize({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/resizes/${payload.id}`, payload.body));
    yield put(Actions.updateResizeSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateResizeFailure(messages));
    }
  }
}

function* deleteResize({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/resizes/${payload.id}/cancel`, payload.body));
    yield put(Actions.deleteResizeSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteResizeFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllResizesRequest, getAllResizes);
  yield takeLatest(Actions.createResizeRequest, createResize);
  yield takeLatest(Actions.updateResizeRequest, updateResize);
  yield takeLatest(Actions.deleteResizeRequest, deleteResize);
}
