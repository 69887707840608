// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllProductGradesRequest = createAction('GET_ALL_PRODUCTGRADES_REQUEST');
export const getAllProductGradesSuccess = createAction('GET_ALL_PRODUCTGRADES_SUCCESS');
export const getAllProductGradesFailure = createAction('GET_ALL_PRODUCTGRADES_FAILURE');

export const getListProductGradesRequest = createAction('GET_LIST_PRODUCTGRADES_REQUEST');
export const getListProductGradesSuccess = createAction('GET_LIST_PRODUCTGRADES_SUCCESS');
export const getListProductGradesFailure = createAction('GET_LIST_PRODUCTGRADES_FAILURE');

export const createProductGradeRequest = createAction('CREATE_PRODUCTGRADE_REQUEST');
export const createProductGradeSuccess = createAction('CREATE_PRODUCTGRADE_SUCCESS');
export const createProductGradeFailure = createAction('CREATE_PRODUCTGRADE_FAILURE');
export const resetCreateProductGrade = createAction('RESET_CREATE_PRODUCTGRADE');

export const createProductGradeMultiRequest = createAction('CREATE_PRODUCTGRADE_MULTI_REQUEST');
export const createProductGradeMultiSuccess = createAction('CREATE_PRODUCTGRADE_MULTI_SUCCESS');
export const createProductGradeMultiFailure = createAction('CREATE_PRODUCTGRADE_MULTI_FAILURE');
export const resetCreateProductGradeMulti = createAction('RESET_CREATE_PRODUCTGRADE_MULTI');

export const updateProductGradeRequest = createAction('UPDATE_PRODUCTGRADE_REQUEST');
export const updateProductGradeSuccess = createAction('UPDATE_PRODUCTGRADE_SUCCESS');
export const updateProductGradeFailure = createAction('UPDATE_PRODUCTGRADE_FAILURE');
export const resetUpdateProductGrade = createAction('RESET_UPDATE_PRODUCTGRADE');

export const deleteProductGradeRequest = createAction('DELETE_PRODUCTGRADE_REQUEST');
export const deleteProductGradeSuccess = createAction('DELETE_PRODUCTGRADE_SUCCESS');
export const deleteProductGradeFailure = createAction('DELETE_PRODUCTGRADE_FAILURE');
export const resetDeleteProductGrade = createAction('RESET_DELETE_PRODUCTGRADE');

export const resetProductGradeState = createAction('RESET_PRODUCTGRADE_STATE');
