// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All ProductBag
  isFirstGetAllProductBags: false,
  isGetAllProductBagsRequest: false,
  isGetAllProductBagsSuccess: false,
  isGetAllProductBagsFailure: false,
  getAllProductBagsState: {},
  // Create ProductBag
  isCreateProductBagRequest: false,
  isCreateProductBagSuccess: false,
  isCreateProductBagFailure: false,
  // Create ProductBag Multi
  isCreateProductBagMultiRequest: false,
  isCreateProductBagMultiSuccess: false,
  isCreateProductBagMultiFailure: false,
  // Update ProductBag
  isUpdateProductBagRequest: false,
  isUpdateProductBagSuccess: false,
  isUpdateProductBagFailure: false,
  // Delete ProductBag
  isDeleteProductBagRequest: false,
  isDeleteProductBagSuccess: false,
  isDeleteProductBagFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All ProductBag
    [Actions.getAllProductBagsRequest]: (state) => ({
      ...state,
      isGetAllProductBagsRequest: true,
      isGetAllProductBagsSuccess: false,
      isGetAllProductBagsFailure: false,
    }),
    [Actions.getAllProductBagsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllProductBags: true,
      isGetAllProductBagsRequest: false,
      isGetAllProductBagsSuccess: true,
      isGetAllProductBagsFailure: false,
      getAllProductBagsState: payload,
    }),
    [Actions.getAllProductBagsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductBagsRequest: false,
      isGetAllProductBagsSuccess: false,
      isGetAllProductBagsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create ProductBag
    [Actions.createProductBagRequest]: (state) => ({
      ...state,
      isCreateProductBagRequest: true,
      isCreateProductBagSuccess: false,
      isCreateProductBagFailure: false,
    }),
    [Actions.createProductBagSuccess]: (state, { payload }) => {
      const getAllProductBagsStateTmp = handleUpdateDataList(state.getAllProductBagsState, payload.data, 'create');

      return ({
        ...state,
        isCreateProductBagRequest: false,
        isCreateProductBagSuccess: true,
        isCreateProductBagFailure: false,
        getAllProductBagsState: { ...getAllProductBagsStateTmp },
      });
    },
    [Actions.createProductBagFailure]: (state, { payload }) => ({
      ...state,
      isCreateProductBagRequest: false,
      isCreateProductBagSuccess: false,
      isCreateProductBagFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateProductBag]: (state) => ({
      ...state,
      isCreateProductBagRequest: false,
      isCreateProductBagSuccess: false,
      isCreateProductBagFailure: false,
    }),
    // #endregion
    // #region : Create ProductBag Multi
    [Actions.createProductBagMultiRequest]: (state) => ({
      ...state,
      isCreateProductBagMultiRequest: true,
      isCreateProductBagMultiSuccess: false,
      isCreateProductBagMultiFailure: false,
    }),
    [Actions.createProductBagMultiSuccess]: (state, { payload }) => {
      const getAllProductBagsStateTmp = handleUpdateDataList(state.getAllProductBagsState, payload.data, 'create');

      return ({
        ...state,
        isCreateProductBagMultiRequest: false,
        isCreateProductBagMultiSuccess: true,
        isCreateProductBagMultiFailure: false,
        getAllProductBagsState: { ...getAllProductBagsStateTmp },
      });
    },
    [Actions.createProductBagMultiFailure]: (state, { payload }) => ({
      ...state,
      isCreateProductBagMultiRequest: false,
      isCreateProductBagMultiSuccess: false,
      isCreateProductBagMultiFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateProductBagMulti]: (state) => ({
      ...state,
      isCreateProductBagMultiRequest: false,
      isCreateProductBagMultiSuccess: false,
      isCreateProductBagMultiFailure: false,
    }),
    // #endregion
    // #region : Update ProductBag
    [Actions.updateProductBagRequest]: (state) => ({
      ...state,
      isUpdateProductBagRequest: true,
      isUpdateProductBagSuccess: false,
      isUpdateProductBagFailure: false,
    }),
    [Actions.updateProductBagSuccess]: (state, { payload }) => {
      const getAllProductBagsStateTmp = handleUpdateDataList(state.getAllProductBagsState, payload.data, 'update');

      return ({
        ...state,
        isUpdateProductBagRequest: false,
        isUpdateProductBagSuccess: true,
        isUpdateProductBagFailure: false,
        getAllProductBagsState: { ...getAllProductBagsStateTmp },
      });
    },
    [Actions.updateProductBagFailure]: (state, { payload }) => ({
      ...state,
      isUpdateProductBagRequest: false,
      isUpdateProductBagSuccess: false,
      isUpdateProductBagFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateProductBag]: (state) => ({
      ...state,
      isUpdateProductBagRequest: false,
      isUpdateProductBagSuccess: false,
      isUpdateProductBagFailure: false,
    }),
    // #endregion
    // #region : Delete ProductBag
    [Actions.deleteProductBagRequest]: (state) => ({
      ...state,
      isDeleteProductBagRequest: true,
      isDeleteProductBagSuccess: false,
      isDeleteProductBagFailure: false,
    }),
    [Actions.deleteProductBagSuccess]: (state, { payload }) => {
      const getAllProductBagsStateTmp = handleUpdateDataList(state.getAllProductBagsState, payload, 'delete');

      return ({
        ...state,
        isDeleteProductBagRequest: false,
        isDeleteProductBagSuccess: true,
        isDeleteProductBagFailure: false,
        getAllProductBagsState: { ...getAllProductBagsStateTmp },
      });
    },
    [Actions.deleteProductBagFailure]: (state, { payload }) => ({
      ...state,
      isDeleteProductBagRequest: false,
      isDeleteProductBagSuccess: false,
      isDeleteProductBagFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteProductBag]: (state) => ({
      ...state,
      isDeleteProductBagRequest: false,
      isDeleteProductBagSuccess: false,
      isDeleteProductBagFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetProductBagState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
