// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllDriversRequest = createAction('GET_ALL_DRIVERS_REQUEST');
export const getAllDriversSuccess = createAction('GET_ALL_DRIVERS_SUCCESS');
export const getAllDriversFailure = createAction('GET_ALL_DRIVERS_FAILURE');

export const createDriverRequest = createAction('CREATE_DRIVER_REQUEST');
export const createDriverSuccess = createAction('CREATE_DRIVER_SUCCESS');
export const createDriverFailure = createAction('CREATE_DRIVER_FAILURE');
export const resetCreateDriver = createAction('RESET_CREATE_DRIVER');

export const updateDriverRequest = createAction('UPDATE_DRIVER_REQUEST');
export const updateDriverSuccess = createAction('UPDATE_DRIVER_SUCCESS');
export const updateDriverFailure = createAction('UPDATE_DRIVER_FAILURE');
export const resetUpdateDriver = createAction('RESET_UPDATE_DRIVER');

export const deleteDriverRequest = createAction('DELETE_DRIVER_REQUEST');
export const deleteDriverSuccess = createAction('DELETE_DRIVER_SUCCESS');
export const deleteDriverFailure = createAction('DELETE_DRIVER_FAILURE');
export const resetDeleteDriver = createAction('RESET_DELETE_DRIVER');

export const resetDriverState = createAction('RESET_DRIVER_STATE');
