// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All Resize
  isFirstGetAllResizes: false,
  isGetAllResizesRequest: false,
  isGetAllResizesSuccess: false,
  isGetAllResizesFailure: false,
  getAllResizesState: {},
  // Create Resize
  isCreateResizeRequest: false,
  isCreateResizeSuccess: false,
  isCreateResizeFailure: false,
  // Update Resize
  isUpdateResizeRequest: false,
  isUpdateResizeSuccess: false,
  isUpdateResizeFailure: false,
  // Delete Resize
  isDeleteResizeRequest: false,
  isDeleteResizeSuccess: false,
  isDeleteResizeFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Resize
    [Actions.getAllResizesRequest]: (state) => ({
      ...state,
      isGetAllResizesRequest: true,
      isGetAllResizesSuccess: false,
      isGetAllResizesFailure: false,
    }),
    [Actions.getAllResizesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllResizes: true,
      isGetAllResizesRequest: false,
      isGetAllResizesSuccess: true,
      isGetAllResizesFailure: false,
      getAllResizesState: payload,
    }),
    [Actions.getAllResizesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllResizesRequest: false,
      isGetAllResizesSuccess: false,
      isGetAllResizesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Resize
    [Actions.createResizeRequest]: (state) => ({
      ...state,
      isCreateResizeRequest: true,
      isCreateResizeSuccess: false,
      isCreateResizeFailure: false,
    }),
    [Actions.createResizeSuccess]: (state) => ({
      ...state,
      isCreateResizeRequest: false,
      isCreateResizeSuccess: true,
      isCreateResizeFailure: false,
    }),
    [Actions.createResizeFailure]: (state, { payload }) => ({
      ...state,
      isCreateResizeRequest: false,
      isCreateResizeSuccess: false,
      isCreateResizeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateResize]: (state) => ({
      ...state,
      isCreateResizeRequest: false,
      isCreateResizeSuccess: false,
      isCreateResizeFailure: false,
    }),
    // #endregion
    // #region : Update Resize
    [Actions.updateResizeRequest]: (state) => ({
      ...state,
      isUpdateResizeRequest: true,
      isUpdateResizeSuccess: false,
      isUpdateResizeFailure: false,
    }),
    [Actions.updateResizeSuccess]: (state, { payload }) => {
      const getAllResizesStateTmp = handleUpdateDataList(state.getAllResizesState, payload.data, 'update');

      return ({
        ...state,
        isUpdateResizeRequest: false,
        isUpdateResizeSuccess: true,
        isUpdateResizeFailure: false,
        getAllResizesState: { ...getAllResizesStateTmp },
      });
    },
    [Actions.updateResizeFailure]: (state, { payload }) => ({
      ...state,
      isUpdateResizeRequest: false,
      isUpdateResizeSuccess: false,
      isUpdateResizeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateResize]: (state) => ({
      ...state,
      isUpdateResizeRequest: false,
      isUpdateResizeSuccess: false,
      isUpdateResizeFailure: false,
    }),
    // #endregion
    // #region : Delete Resize
    [Actions.deleteResizeRequest]: (state) => ({
      ...state,
      isDeleteResizeRequest: true,
      isDeleteResizeSuccess: false,
      isDeleteResizeFailure: false,
    }),
    [Actions.deleteResizeSuccess]: (state) => ({
      ...state,
      isDeleteResizeRequest: false,
      isDeleteResizeSuccess: true,
      isDeleteResizeFailure: false,
    }),
    [Actions.deleteResizeFailure]: (state, { payload }) => ({
      ...state,
      isDeleteResizeRequest: false,
      isDeleteResizeSuccess: false,
      isDeleteResizeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteResize]: (state) => ({
      ...state,
      isDeleteResizeRequest: false,
      isDeleteResizeSuccess: false,
      isDeleteResizeFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetResizeState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
