// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All Batch
  isFirstGetAllBatches: false,
  isGetAllBatchesRequest: false,
  isGetAllBatchesSuccess: false,
  isGetAllBatchesFailure: false,
  getAllBatchesState: {},
  // Create Batch
  isCreateBatchRequest: false,
  isCreateBatchSuccess: false,
  isCreateBatchFailure: false,
  // Update Batch
  isUpdateBatchRequest: false,
  isUpdateBatchSuccess: false,
  isUpdateBatchFailure: false,
  // Delete Batch
  isDeleteBatchRequest: false,
  isDeleteBatchSuccess: false,
  isDeleteBatchFailure: false,
  // Get list
  isGetListBatchesRequest: false,
  isGetListBatchesSuccess: false,
  isGetListBatchesFailure: false,
  getListBatchesState: {},
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Batch
    [Actions.getAllBatchesRequest]: (state) => ({
      ...state,
      isGetAllBatchesRequest: true,
      isGetAllBatchesSuccess: false,
      isGetAllBatchesFailure: false,
    }),
    [Actions.getAllBatchesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllBatches: true,
      isGetAllBatchesRequest: false,
      isGetAllBatchesSuccess: true,
      isGetAllBatchesFailure: false,
      getAllBatchesState: payload,
    }),
    [Actions.getAllBatchesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllBatchesRequest: false,
      isGetAllBatchesSuccess: false,
      isGetAllBatchesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Batch
    [Actions.createBatchRequest]: (state) => ({
      ...state,
      isCreateBatchRequest: true,
      isCreateBatchSuccess: false,
      isCreateBatchFailure: false,
    }),
    [Actions.createBatchSuccess]: (state, { payload }) => {
      const getAllBatchesStateTmp = handleUpdateDataList(state.getAllBatchesState, payload.data, 'create');

      return ({
        ...state,
        isCreateBatchRequest: false,
        isCreateBatchSuccess: true,
        isCreateBatchFailure: false,
        getAllBatchesState: { ...getAllBatchesStateTmp },
      });
    },
    [Actions.createBatchFailure]: (state, { payload }) => ({
      ...state,
      isCreateBatchRequest: false,
      isCreateBatchSuccess: false,
      isCreateBatchFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateBatch]: (state) => ({
      ...state,
      isCreateBatchRequest: false,
      isCreateBatchSuccess: false,
      isCreateBatchFailure: false,
    }),
    // #endregion
    // #region : Update Batch
    [Actions.updateBatchRequest]: (state) => ({
      ...state,
      isUpdateBatchRequest: true,
      isUpdateBatchSuccess: false,
      isUpdateBatchFailure: false,
    }),
    [Actions.updateBatchSuccess]: (state, { payload }) => {
      const getAllBatchesStateTmp = handleUpdateDataList(state.getAllBatchesState, payload.data, 'update');

      return ({
        ...state,
        isUpdateBatchRequest: false,
        isUpdateBatchSuccess: true,
        isUpdateBatchFailure: false,
        getAllBatchesState: { ...getAllBatchesStateTmp },
      });
    },
    [Actions.updateBatchFailure]: (state, { payload }) => ({
      ...state,
      isUpdateBatchRequest: false,
      isUpdateBatchSuccess: false,
      isUpdateBatchFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateBatch]: (state) => ({
      ...state,
      isUpdateBatchRequest: false,
      isUpdateBatchSuccess: false,
      isUpdateBatchFailure: false,
    }),
    // #endregion
    // #region : Delete Batch
    [Actions.deleteBatchRequest]: (state) => ({
      ...state,
      isDeleteBatchRequest: true,
      isDeleteBatchSuccess: false,
      isDeleteBatchFailure: false,
    }),
    [Actions.deleteBatchSuccess]: (state, { payload }) => {
      const getAllBatchesStateTmp = handleUpdateDataList(state.getAllBatchesState, payload, 'delete');

      return ({
        ...state,
        isDeleteBatchRequest: false,
        isDeleteBatchSuccess: true,
        isDeleteBatchFailure: false,
        getAllBatchesState: { ...getAllBatchesStateTmp },
      });
    },
    [Actions.deleteBatchFailure]: (state, { payload }) => ({
      ...state,
      isDeleteBatchRequest: false,
      isDeleteBatchSuccess: false,
      isDeleteBatchFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteBatch]: (state) => ({
      ...state,
      isDeleteBatchRequest: false,
      isDeleteBatchSuccess: false,
      isDeleteBatchFailure: false,
    }),
    // #endregion
    // #region : Get List Batch
    [Actions.getListBatchesRequest]: (state) => ({
      ...state,
      isGetListBatchesRequest: true,
      isGetListBatchesSuccess: false,
      isGetListBatchesFailure: false,
    }),
    [Actions.getListBatchesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetListBatches: true,
      isGetListBatchesRequest: false,
      isGetListBatchesSuccess: true,
      isGetListBatchesFailure: false,
      getListBatchesState: payload,
    }),
    [Actions.getListBatchesFailure]: (state, { payload }) => ({
      ...state,
      isGetListBatchesRequest: false,
      isGetListBatchesSuccess: false,
      isGetListBatchesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.resetBatchState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
