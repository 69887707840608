// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllPublicRefundsRequest = createAction('GET_ALL_PUBLICREFUNDS_REQUEST');
export const getAllPublicRefundsSuccess = createAction('GET_ALL_PUBLICREFUNDS_SUCCESS');
export const getAllPublicRefundsFailure = createAction('GET_ALL_PUBLICREFUNDS_FAILURE');

export const createPublicRefundRequest = createAction('CREATE_PUBLICREFUND_REQUEST');
export const createPublicRefundSuccess = createAction('CREATE_PUBLICREFUND_SUCCESS');
export const createPublicRefundFailure = createAction('CREATE_PUBLICREFUND_FAILURE');
export const resetCreatePublicRefund = createAction('RESET_CREATE_PUBLICREFUND');

export const updatePublicRefundRequest = createAction('UPDATE_PUBLICREFUND_REQUEST');
export const updatePublicRefundSuccess = createAction('UPDATE_PUBLICREFUND_SUCCESS');
export const updatePublicRefundFailure = createAction('UPDATE_PUBLICREFUND_FAILURE');
export const resetUpdatePublicRefund = createAction('RESET_UPDATE_PUBLICREFUND');

export const deletePublicRefundRequest = createAction('DELETE_PUBLICREFUND_REQUEST');
export const deletePublicRefundSuccess = createAction('DELETE_PUBLICREFUND_SUCCESS');
export const deletePublicRefundFailure = createAction('DELETE_PUBLICREFUND_FAILURE');
export const resetDeletePublicRefund = createAction('RESET_DELETE_PUBLICREFUND');

export const updateStatusPublicRefundRequest = createAction('UPDATE_STATUS_PUBLIC_REFUND_REQUEST');
export const updateStatusPublicRefundSuccess = createAction('UPDATE_STATUS_PUBLIC_REFUND_SUCCESS');
export const updateStatusPublicRefundFailure = createAction('UPDATE_STATUS_PUBLIC_REFUND_FAILURE');
export const resetUpdateStatusPublicRefund = createAction('RESET_UPDATE_UPDATE_PUBLIC_REFUND');

export const resetPublicRefundState = createAction('RESET_PUBLICREFUND_STATE');
