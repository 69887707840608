// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllPublicOrdersRequest = createAction('GET_ALL_PUBLICORDERS_REQUEST');
export const getAllPublicOrdersSuccess = createAction('GET_ALL_PUBLICORDERS_SUCCESS');
export const getAllPublicOrdersFailure = createAction('GET_ALL_PUBLICORDERS_FAILURE');

export const createPublicOrderRequest = createAction('CREATE_PUBLICORDER_REQUEST');
export const createPublicOrderSuccess = createAction('CREATE_PUBLICORDER_SUCCESS');
export const createPublicOrderFailure = createAction('CREATE_PUBLICORDER_FAILURE');
export const resetCreatePublicOrder = createAction('RESET_CREATE_PUBLICORDER');

export const updatePublicOrderRequest = createAction('UPDATE_PUBLICORDER_REQUEST');
export const updatePublicOrderSuccess = createAction('UPDATE_PUBLICORDER_SUCCESS');
export const updatePublicOrderFailure = createAction('UPDATE_PUBLICORDER_FAILURE');
export const resetUpdatePublicOrder = createAction('RESET_UPDATE_PUBLICORDER');

export const updateStatusPublicOrderRequest = createAction('UPDATE_STATUS_PUBLICORDER_REQUEST');
export const updateStatusPublicOrderSuccess = createAction('UPDATE_STATUS_PUBLICORDER_SUCCESS');
export const updateStatusPublicOrderFailure = createAction('UPDATE_STATUS_PUBLICORDER_FAILURE');
export const resetUpdateStatusPublicOrder = createAction('RESET_UPDATE_STATUS_PUBLICORDER');

export const deletePublicOrderRequest = createAction('DELETE_PUBLICORDER_REQUEST');
export const deletePublicOrderSuccess = createAction('DELETE_PUBLICORDER_SUCCESS');
export const deletePublicOrderFailure = createAction('DELETE_PUBLICORDER_FAILURE');
export const resetDeletePublicOrder = createAction('RESET_DELETE_PUBLICORDER');

export const resetPublicOrderState = createAction('RESET_PUBLICORDER_STATE');
