// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllProductSizesRequest = createAction('GET_ALL_PRODUCTSIZES_REQUEST');
export const getAllProductSizesSuccess = createAction('GET_ALL_PRODUCTSIZES_SUCCESS');
export const getAllProductSizesFailure = createAction('GET_ALL_PRODUCTSIZES_FAILURE');

export const getListProductSizesRequest = createAction('GET_LIST_PRODUCTSIZES_REQUEST');
export const getListProductSizesSuccess = createAction('GET_LIST_PRODUCTSIZES_SUCCESS');
export const getListProductSizesFailure = createAction('GET_LIST_PRODUCTSIZES_FAILURE');

export const createProductSizeRequest = createAction('CREATE_PRODUCTSIZE_REQUEST');
export const createProductSizeSuccess = createAction('CREATE_PRODUCTSIZE_SUCCESS');
export const createProductSizeFailure = createAction('CREATE_PRODUCTSIZE_FAILURE');
export const resetCreateProductSize = createAction('RESET_CREATE_PRODUCTSIZE');

export const updateProductSizeRequest = createAction('UPDATE_PRODUCTSIZE_REQUEST');
export const updateProductSizeSuccess = createAction('UPDATE_PRODUCTSIZE_SUCCESS');
export const updateProductSizeFailure = createAction('UPDATE_PRODUCTSIZE_FAILURE');
export const resetUpdateProductSize = createAction('RESET_UPDATE_PRODUCTSIZE');

export const deleteProductSizeRequest = createAction('DELETE_PRODUCTSIZE_REQUEST');
export const deleteProductSizeSuccess = createAction('DELETE_PRODUCTSIZE_SUCCESS');
export const deleteProductSizeFailure = createAction('DELETE_PRODUCTSIZE_FAILURE');
export const resetDeleteProductSize = createAction('RESET_DELETE_PRODUCTSIZE');

export const resetProductSizeState = createAction('RESET_PRODUCTSIZE_STATE');
