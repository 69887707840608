/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllLabels({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/labels?${payload}`)
        : () => axiosMicro.get('/labels', { params: payload }),
    );
    yield put(Actions.getAllLabelsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllLabelsFailure(messages));
    }
  }
}

function* createLabel({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/labels', payload));
    yield put(Actions.createLabelSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createLabelFailure(messages));
    }
  }
}

function* updateLabel({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/labels/${payload.id}`, payload.body));
    yield put(Actions.updateLabelSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateLabelFailure(messages));
    }
  }
}

function* deleteLabel({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/labels/${payload}`));
    yield put(Actions.deleteLabelSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteLabelFailure(messages));
    }
  }
}

function* getListLabels({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/labels/list?${payload}`)
        : () => axiosMicro.get('/labels/list', { params: payload }),
    );
    yield put(Actions.getListLabelsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getListLabelsFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllLabelsRequest, getAllLabels);
  yield takeLatest(Actions.createLabelRequest, createLabel);
  yield takeLatest(Actions.updateLabelRequest, updateLabel);
  yield takeLatest(Actions.deleteLabelRequest, deleteLabel);
  yield takeLatest(Actions.getListLabelsRequest, getListLabels);
}
