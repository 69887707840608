// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllreportsRequest = createAction('GET_ALL_REPORTS_REQUEST');
export const getAllreportsSuccess = createAction('GET_ALL_REPORTS_SUCCESS');
export const getAllreportsFailure = createAction('GET_ALL_REPORTS_FAILURE');

export const createReportRequest = createAction('CREATE_REPORT_REQUEST');
export const createReportSuccess = createAction('CREATE_REPORT_SUCCESS');
export const createReportFailure = createAction('CREATE_REPORT_FAILURE');
export const resetCreateReport = createAction('RESET_CREATE_REPORT');

export const updateReportRequest = createAction('UPDATE_REPORT_REQUEST');
export const updateReportSuccess = createAction('UPDATE_REPORT_SUCCESS');
export const updateReportFailure = createAction('UPDATE_REPORT_FAILURE');
export const resetUpdateReport = createAction('RESET_UPDATE_REPORT');

export const deleteReportRequest = createAction('DELETE_REPORT_REQUEST');
export const deleteReportSuccess = createAction('DELETE_REPORT_SUCCESS');
export const deleteReportFailure = createAction('DELETE_REPORT_FAILURE');
export const resetDeleteReport = createAction('RESET_DELETE_REPORT');

export const getReportWarehouseDetailRequest = createAction('GET_REPORT_WAREHOUSE_DETAIL_REQUEST');
export const getReportWarehouseDetailSuccess = createAction('GET_REPORT_WAREHOUSE_DETAIL_SUCCESS');
export const getReportWarehouseDetailFailure = createAction('GET_REPORT_WAREHOUSE_DETAIL_FAILURE');

export const exportExcelGetReportWarehouseDetailRequest = createAction('EXPORT_EXCEL_GET_REPORT_WAREHOUSE_DETAIL_REQUEST');
export const exportExcelGetReportWarehouseDetailSuccess = createAction('EXPORT_EXCEL_GET_REPORT_WAREHOUSE_DETAIL_SUCCESS');
export const exportExcelGetReportWarehouseDetailFailure = createAction('EXPORT_EXCEL_GET_REPORT_WAREHOUSE_DETAIL_FAILURE');
export const resetExportExcelGetReportWarehouseDetail = createAction('RESET_EXPORT_EXCEL_GET_REPORT_WAREHOUSE_DETAIL');

export const getReportWarehouseGeneralRequest = createAction('GET_REPORT_WAREHOUSE_GENERAL_REQUEST');
export const getReportWarehouseGeneralSuccess = createAction('GET_REPORT_WAREHOUSE_GENERAL_SUCCESS');
export const getReportWarehouseGeneralFailure = createAction('GET_REPORT_WAREHOUSE_GENERAL_FAILURE');

export const exportReportWarehouseGeneralRequest = createAction('EXPORT_REPORT_WAREHOUSE_GENERAL_REQUEST');
export const exportReportWarehouseGeneralSuccess = createAction('EXPORT_REPORT_WAREHOUSE_GENERAL_SUCCESS');
export const exportReportWarehouseGeneralFailure = createAction('EXPORT_REPORT_WAREHOUSE_GENERAL_FAILURE');
export const resetExportReportWarehouseGeneral = createAction('RESET_EXPORT_REPORT_WAREHOUSE_GENERAL');

export const getReportWarehouseProductRequest = createAction('GET_REPORT_WAREHOUSE_PRODUCT_REQUEST');
export const getReportWarehouseProductSuccess = createAction('GET_REPORT_WAREHOUSE_PRODUCT_SUCCESS');
export const getReportWarehouseProductFailure = createAction('GET_REPORT_WAREHOUSE_PRODUCT_FAILURE');

export const exportExcelGetReportWarehouseProductRequest = createAction('EXPORT_EXCEL_GET_REPORT_WAREHOUSE_PRODUCT_REQUEST');
export const exportExcelGetReportWarehouseProductSuccess = createAction('EXPORT_EXCEL_GET_REPORT_WAREHOUSE_PRODUCT_SUCCESS');
export const exportExcelGetReportWarehouseProductFailure = createAction('EXPORT_EXCEL_GET_REPORT_WAREHOUSE_PRODUCT_FAILURE');
export const resetExportExcelGetReportWarehouseProduct = createAction('RESET_EXPORT_EXCEL_GET_REPORT_WAREHOUSE_PRODUCT');

export const getListCustomerReportRequest = createAction('GET_LIST_CUSTOMER_REPORT_REQUEST');
export const getListCustomerReportSuccess = createAction('GET_LIST_CUSTOMER_REPORT_SUCCESS');
export const getListCustomerReportFailure = createAction('GET_LIST_CUSTOMER_REPORT_FAILURE');

export const getListCustomerReportDupRequest = createAction('GET_LIST_CUSTOMER_REPORT_DUP_REQUEST');
export const getListCustomerReportDupSuccess = createAction('GET_LIST_CUSTOMER_REPORT_DUP_SUCCESS');
export const getListCustomerReportDupFailure = createAction('GET_LIST_CUSTOMER_REPORT_DUP_FAILURE');

export const getReportCustomerDetailRequest = createAction('GET_REPORT_CUSTOMER_DETAIL_REQUEST');
export const getReportCustomerDetailSuccess = createAction('GET_REPORT_CUSTOMER_DETAIL_SUCCESS');
export const getReportCustomerDetailFailure = createAction('GET_REPORT_CUSTOMER_DETAIL_FAILURE');

export const exportReportCustomerDetailRequest = createAction('EXPORT_REPORT_CUSTOMER_DETAIL_REQUEST');
export const exportReportCustomerDetailSuccess = createAction('EXPORT_REPORT_CUSTOMER_DETAIL_SUCCESS');
export const exportReportCustomerDetailFailure = createAction('EXPORT_REPORT_CUSTOMER_DETAIL_FAILURE');
export const resetExportReportCustomerDetail = createAction('RESET_EXPORT_REPORT_CUSTOMER_DETAIL');

export const getReportCustomerExportRequest = createAction('GET_REPORT_CUSTOMER_EXPORT_REQUEST');
export const getReportCustomerExportSuccess = createAction('GET_REPORT_CUSTOMER_EXPORT_SUCCESS');
export const getReportCustomerExportFailure = createAction('GET_REPORT_CUSTOMER_EXPORT_FAILURE');

export const exportReportCustomerExportRequest = createAction('EXPORT_REPORT_CUSTOMER_EXPORT_REQUEST');
export const exportReportCustomerExportSuccess = createAction('EXPORT_REPORT_CUSTOMER_EXPORT_SUCCESS');
export const exportReportCustomerExportFailure = createAction('EXPORT_REPORT_CUSTOMER_EXPORT_FAILURE');
export const resetExportReportCustomerExport = createAction('RESET_EXPORT_REPORT_CUSTOMER_EXPORT');

export const getReportProcessDetailRequest = createAction('GET_REPORT_PROCESS_DETAIL_REQUEST');
export const getReportProcessDetailSuccess = createAction('GET_REPORT_PROCESS_DETAIL_SUCCESS');
export const getReportProcessDetailFailure = createAction('GET_REPORT_PROCESS_DETAIL_FAILURE');

export const exportReportProcessDetailRequest = createAction('EXPORT_REPORT_PROCESS_DETAIL_REQUEST');
export const exportReportProcessDetailSuccess = createAction('EXPORT_REPORT_PROCESS_DETAIL_SUCCESS');
export const exportReportProcessDetailFailure = createAction('EXPORT_REPORT_PROCESS_DETAIL_FAILURE');
export const resetExportReportProcessDetail = createAction('RESET_EXPORT_REPORT_PROCESS_DETAIL');

export const saveReportProcessDetailRequest = createAction('SAVE_REPORT_PROCESS_DETAIL_REQUEST');
export const saveReportProcessDetailSuccess = createAction('SAVE_REPORT_PROCESS_DETAIL_SUCCESS');
export const saveReportProcessDetailFailure = createAction('SAVE_REPORT_PROCESS_DETAIL_FAILURE');
export const resetSaveReportProcessDetail = createAction('RESET_SAVE_REPORT_PROCESS_DETAIL');

export const getReportProcessTotalRequest = createAction('GET_REPORT_PROCESS_TOTAL_REQUEST');
export const getReportProcessTotalSuccess = createAction('GET_REPORT_PROCESS_TOTAL_SUCCESS');
export const getReportProcessTotalFailure = createAction('GET_REPORT_PROCESS_TOTAL_FAILURE');

export const exportReportProcessTotalRequest = createAction('EXPORT_REPORT_PROCESS_TOTAL_REQUEST');
export const exportReportProcessTotalSuccess = createAction('EXPORT_REPORT_PROCESS_TOTAL_SUCCESS');
export const exportReportProcessTotalFailure = createAction('EXPORT_REPORT_PROCESS_TOTAL_FAILURE');
export const resetExportReportProcessTotal = createAction('RESET_EXPORT_REPORT_PROCESS_TOTAL');

export const getReportExportDayRequest = createAction('GET_REPORT_EXPORT_DAY_REQUEST');
export const getReportExportDaySuccess = createAction('GET_REPORT_EXPORT_DAY_SUCCESS');
export const getReportExportDayFailure = createAction('GET_REPORT_EXPORT_DAY_FAILURE');

export const exportReportExportDayRequest = createAction('EXPORT_REPORT_EXPORT_DAY_REQUEST');
export const exportReportExportDaySuccess = createAction('EXPORT_REPORT_EXPORT_DAY_SUCCESS');
export const exportReportExportDayFailure = createAction('EXPORT_REPORT_EXPORT_DAY_FAILURE');
export const resetExportReportExportDay = createAction('RESET_EXPORT_REPORT_EXPORT_DAY');

export const getReportExportDateRequest = createAction('GET_REPORT_EXPORT_DATE_REQUEST');
export const getReportExportDateSuccess = createAction('GET_REPORT_EXPORT_DATE_SUCCESS');
export const getReportExportDateFailure = createAction('GET_REPORT_EXPORT_DATE_FAILURE');

export const getReportExportOrderDateRequest = createAction('GET_REPORT_EXPORT_ORDER_DATE_REQUEST');
export const getReportExportOrderDateSuccess = createAction('GET_REPORT_EXPORT_ORDER_DATE_SUCCESS');
export const getReportExportOrderDateFailure = createAction('GET_REPORT_EXPORT_ORDER_DATE_FAILURE');
export const resetReportExportOrderDate = createAction('RESET_REPORT_EXPORT_ORDER_DATE_DATE');

export const exportReportExportDateRequest = createAction('EXPORT_REPORT_EXPORT_DATE_REQUEST');
export const exportReportExportDateSuccess = createAction('EXPORT_REPORT_EXPORT_DATE_SUCCESS');
export const exportReportExportDateFailure = createAction('EXPORT_REPORT_EXPORT_DATE_FAILURE');
export const resetExportReportExportDate = createAction('RESET_EXPORT_REPORT_EXPORT_DATE');

export const exportReportExportOrderDateRequest = createAction('EXPORT_REPORT_EXPORT_ORDER_DATE_REQUEST');
export const exportReportExportOrderDateSuccess = createAction('EXPORT_REPORT_EXPORT_ORDER_DATE_SUCCESS');
export const exportReportExportOrderDateFailure = createAction('EXPORT_REPORT_EXPORT_ORDER_DATE_FAILURE');
export const resetExportReportExportOrderDate = createAction('RESET_EXPORT_REPORT_EXPORT_ORDER_DATE');

export const getReportSellDayRequest = createAction('GET_REPORT_SELL_DAY_REQUEST');
export const getReportSellDaySuccess = createAction('GET_REPORT_SELL_DAY_SUCCESS');
export const getReportSellDayFailure = createAction('GET_REPORT_SELL_DAY_FAILURE');

export const exportReportSellDayRequest = createAction('EXPORT_REPORT_SELL_DAY_REQUEST');
export const exportReportSellDaySuccess = createAction('EXPORT_REPORT_SELL_DAY_SUCCESS');
export const exportReportSellDayFailure = createAction('EXPORT_REPORT_SELL_DAY_FAILURE');
export const resetExportReportSellDay = createAction('RESET_EXPORT_REPORT_SELL_DAY');

export const getReportCustomerExportWarehouseRequest = createAction('GET_REPORT_CUSTOMER_EXPORT_WAREHOUSE_REQUEST');
export const getReportCustomerExportWarehouseSuccess = createAction('GET_REPORT_CUSTOMER_EXPORT_WAREHOUSE_SUCCESS');
export const getReportCustomerExportWarehouseFailure = createAction('GET_REPORT_CUSTOMER_EXPORT_WAREHOUSE_FAILURE');

export const exportReportCustomerExportWarehouseRequest = createAction('EXPORT_REPORT_CUSTOMER_EXPORT_WAREHOUSE_REQUEST');
export const exportReportCustomerExportWarehouseSuccess = createAction('EXPORT_REPORT_CUSTOMER_EXPORT_WAREHOUSE_SUCCESS');
export const exportReportCustomerExportWarehouseFailure = createAction('EXPORT_REPORT_CUSTOMER_EXPORT_WAREHOUSE_FAILURE');
export const resetExportReportCustomerExportWarehouse = createAction('RESET_EXPORT_REPORT_CUSTOMER_EXPORT_WAREHOUSE');

export const resetReportState = createAction('RESET_REPORT_STATE');
