// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllRefundsRequest = createAction('GET_ALL_REFUNDS_REQUEST');
export const getAllRefundsSuccess = createAction('GET_ALL_REFUNDS_SUCCESS');
export const getAllRefundsFailure = createAction('GET_ALL_REFUNDS_FAILURE');

export const createRefundRequest = createAction('CREATE_REFUND_REQUEST');
export const createRefundSuccess = createAction('CREATE_REFUND_SUCCESS');
export const createRefundFailure = createAction('CREATE_REFUND_FAILURE');
export const resetCreateRefund = createAction('RESET_CREATE_REFUND');

export const cancelRefundRequest = createAction('CANCEL_REFUND_REQUEST');
export const cancelRefundSuccess = createAction('CANCEL_REFUND_SUCCESS');
export const cancelRefundFailure = createAction('CANCEL_REFUND_FAILURE');
export const resetCancelRefund = createAction('RESET_CANCEL_REFUND');

export const updateRefundStatusRequest = createAction('UPDATE_REFUND_STATUS_REQUEST');
export const updateRefundStatusSuccess = createAction('UPDATE_REFUND_STATUS_SUCCESS');
export const updateRefundStatusFailure = createAction('UPDATE_REFUND_STATUS_FAILURE');
export const resetUpdateRefundStatus = createAction('RESET_UPDATE_REFUND_STATUS');

export const resetRefundState = createAction('RESET_REFUND_STATE');
