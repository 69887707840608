// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All ProductSize
  isFirstGetAllProductSizes: false,
  isGetAllProductSizesRequest: false,
  isGetAllProductSizesSuccess: false,
  isGetAllProductSizesFailure: false,
  getAllProductSizesState: {},
  // Get List ProductSize
  isGetListProductSizesRequest: false,
  isGetListProductSizesSuccess: false,
  isGetListProductSizesFailure: false,
  getListProductSizesState: {},
  // Create ProductSize
  isCreateProductSizeRequest: false,
  isCreateProductSizeSuccess: false,
  isCreateProductSizeFailure: false,
  // Update ProductSize
  isUpdateProductSizeRequest: false,
  isUpdateProductSizeSuccess: false,
  isUpdateProductSizeFailure: false,
  // Delete ProductSize
  isDeleteProductSizeRequest: false,
  isDeleteProductSizeSuccess: false,
  isDeleteProductSizeFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All ProductSize
    [Actions.getAllProductSizesRequest]: (state) => ({
      ...state,
      isGetAllProductSizesRequest: true,
      isGetAllProductSizesSuccess: false,
      isGetAllProductSizesFailure: false,
    }),
    [Actions.getAllProductSizesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllProductSizes: true,
      isGetAllProductSizesRequest: false,
      isGetAllProductSizesSuccess: true,
      isGetAllProductSizesFailure: false,
      getAllProductSizesState: payload,
    }),
    [Actions.getAllProductSizesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductSizesRequest: false,
      isGetAllProductSizesSuccess: false,
      isGetAllProductSizesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Get List ProductSize
    [Actions.getListProductSizesRequest]: (state) => ({
      ...state,
      isGetListProductSizesRequest: true,
      isGetListProductSizesSuccess: false,
      isGetListProductSizesFailure: false,
    }),
    [Actions.getListProductSizesSuccess]: (state, { payload }) => ({
      ...state,
      isGetListProductSizesRequest: false,
      isGetListProductSizesSuccess: true,
      isGetListProductSizesFailure: false,
      getListProductSizesState: payload,
    }),
    [Actions.getListProductSizesFailure]: (state, { payload }) => ({
      ...state,
      isGetListProductSizesRequest: false,
      isGetListProductSizesSuccess: false,
      isGetListProductSizesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create ProductSize
    [Actions.createProductSizeRequest]: (state) => ({
      ...state,
      isCreateProductSizeRequest: true,
      isCreateProductSizeSuccess: false,
      isCreateProductSizeFailure: false,
    }),
    [Actions.createProductSizeSuccess]: (state, { payload }) => {
      const getAllProductSizesStateTmp = handleUpdateDataList(state.getAllProductSizesState, payload.data, 'create');

      return ({
        ...state,
        isCreateProductSizeRequest: false,
        isCreateProductSizeSuccess: true,
        isCreateProductSizeFailure: false,
        getAllProductSizesState: { ...getAllProductSizesStateTmp },
      });
    },
    [Actions.createProductSizeFailure]: (state, { payload }) => ({
      ...state,
      isCreateProductSizeRequest: false,
      isCreateProductSizeSuccess: false,
      isCreateProductSizeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateProductSize]: (state) => ({
      ...state,
      isCreateProductSizeRequest: false,
      isCreateProductSizeSuccess: false,
      isCreateProductSizeFailure: false,
    }),
    // #endregion
    // #region : Update ProductSize
    [Actions.updateProductSizeRequest]: (state) => ({
      ...state,
      isUpdateProductSizeRequest: true,
      isUpdateProductSizeSuccess: false,
      isUpdateProductSizeFailure: false,
    }),
    [Actions.updateProductSizeSuccess]: (state, { payload }) => {
      const getAllProductSizesStateTmp = handleUpdateDataList(state.getAllProductSizesState, payload.data, 'update');

      return ({
        ...state,
        isUpdateProductSizeRequest: false,
        isUpdateProductSizeSuccess: true,
        isUpdateProductSizeFailure: false,
        getAllProductSizesState: { ...getAllProductSizesStateTmp },
      });
    },
    [Actions.updateProductSizeFailure]: (state, { payload }) => ({
      ...state,
      isUpdateProductSizeRequest: false,
      isUpdateProductSizeSuccess: false,
      isUpdateProductSizeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateProductSize]: (state) => ({
      ...state,
      isUpdateProductSizeRequest: false,
      isUpdateProductSizeSuccess: false,
      isUpdateProductSizeFailure: false,
    }),
    // #endregion
    // #region : Delete ProductSize
    [Actions.deleteProductSizeRequest]: (state) => ({
      ...state,
      isDeleteProductSizeRequest: true,
      isDeleteProductSizeSuccess: false,
      isDeleteProductSizeFailure: false,
    }),
    [Actions.deleteProductSizeSuccess]: (state, { payload }) => {
      const getAllProductSizesStateTmp = handleUpdateDataList(state.getAllProductSizesState, payload, 'delete');

      return ({
        ...state,
        isDeleteProductSizeRequest: false,
        isDeleteProductSizeSuccess: true,
        isDeleteProductSizeFailure: false,
        getAllProductSizesState: { ...getAllProductSizesStateTmp },
      });
    },
    [Actions.deleteProductSizeFailure]: (state, { payload }) => ({
      ...state,
      isDeleteProductSizeRequest: false,
      isDeleteProductSizeSuccess: false,
      isDeleteProductSizeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteProductSize]: (state) => ({
      ...state,
      isDeleteProductSizeRequest: false,
      isDeleteProductSizeSuccess: false,
      isDeleteProductSizeFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetProductSizeState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
