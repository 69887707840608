// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllExportProductsRequest = createAction('GET_ALL_EXPORT_PRODUCTS_REQUEST');
export const getAllExportProductsSuccess = createAction('GET_ALL_EXPORT_PRODUCTS_SUCCESS');
export const getAllExportProductsFailure = createAction('GET_ALL_EXPORT_PRODUCTS_FAILURE');

export const createExportProductRequest = createAction('CREATE_EXPORT_PRODUCT_REQUEST');
export const createExportProductSuccess = createAction('CREATE_EXPORT_PRODUCT_SUCCESS');
export const createExportProductFailure = createAction('CREATE_EXPORT_PRODUCT_FAILURE');
export const resetCreateExportProduct = createAction('RESET_CREATE_EXPORT_PRODUCT');

export const cancelExportProductRequest = createAction('CANCEL_EXPORT_PRODUCT_REQUEST');
export const cancelExportProductSuccess = createAction('CANCEL_EXPORT_PRODUCT_SUCCESS');
export const cancelExportProductFailure = createAction('CANCEL_EXPORT_PRODUCT_FAILURE');
export const resetCancelExportProduct = createAction('RESET_CANCEL_EXPORT_PRODUCT');

export const updateStatusExportProductRequest = createAction('UPDATE_STATUS_EXPORT_PRODUCT_REQUEST');
export const updateStatusExportProductSuccess = createAction('UPDATE_STATUS_EXPORT_PRODUCT_SUCCESS');
export const updateStatusExportProductFailure = createAction('UPDATE_STATUS_EXPORT_PRODUCT_FAILURE');
export const resetUpdateStatusExportProduct = createAction('RESET_UPDATE_STATUS_EXPORT_PRODUCT');

export const updateExportProductRequest = createAction('UPDATE_EXPORT_PRODUCT_REQUEST');
export const updateExportProductSuccess = createAction('UPDATE_EXPORT_PRODUCT_SUCCESS');
export const updateExportProductFailure = createAction('UPDATE_EXPORT_PRODUCT_FAILURE');
export const resetUpdateExportProduct = createAction('RESET_UPDATE_EXPORT_PRODUCT');

export const resetExportProductState = createAction('RESET_EXPORT_PRODUCT_STATE');
