// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  //
  isGetListProductsRequest: false,
  isGetListProductsSuccess: false,
  isGetListProductsFailure: false,
  listProducts: {},
  //
  isCreateProductRequest: false,
  isCreateProductSuccess: false,
  isCreateProductFailure: false,
  //
  isUpdateProductRequest: false,
  isUpdateProductSuccess: false,
  isUpdateProductFailure: false,
  //
  isDeleteProductRequest: false,
  isDeleteProductSuccess: false,
  isDeleteProductFailure: false,
  //
  isGetAllProductTypesRequest: false,
  isGetAllProductTypesSuccess: false,
  isGetAllProductTypesFailure: false,
  allProductTypesState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get list products
    [Actions.getListProductsRequest]: (state) => ({
      ...state,
      isGetListProductsRequest: true,
      isGetListProductsSuccess: false,
      isGetListProductsFailure: false,
    }),
    [Actions.getListProductsSuccess]: (state, { payload }) => ({
      ...state,
      isGetListProductsRequest: false,
      isGetListProductsSuccess: true,
      isGetListProductsFailure: false,
      listProducts: payload,
    }),
    [Actions.getListProductsFailure]: (state, { payload }) => ({
      ...state,
      isGetListProductsRequest: false,
      isGetListProductsSuccess: false,
      isGetListProductsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create product
    [Actions.createProductRequest]: (state) => ({
      ...state,
      isCreateProductRequest: true,
      isCreateProductSuccess: false,
      isCreateProductFailure: false,
    }),
    [Actions.createProductSuccess]: (state, { payload }) => {
      const getListProductStateTmp = handleUpdateDataList(state.listProducts, payload.data, 'create');

      return ({
        ...state,
        isCreateProductRequest: false,
        isCreateProductSuccess: true,
        isCreateProductFailure: false,
        listProducts: { ...getListProductStateTmp },
      });
    },
    [Actions.createProductFailure]: (state, { payload }) => ({
      ...state,
      isCreateProductRequest: false,
      isCreateProductSuccess: false,
      isCreateProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateProductState]: (state) => ({
      ...state,
      isCreateProductRequest: false,
      isCreateProductSuccess: false,
      isCreateProductFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update product
    [Actions.updateProductRequest]: (state) => ({
      ...state,
      isUpdateProductRequest: true,
      isUpdateProductSuccess: false,
      isUpdateProductFailure: false,
    }),
    [Actions.updateProductSuccess]: (state, { payload }) => {
      const getListProductStateTmp = handleUpdateDataList(state.listProducts, payload.data, 'update');

      return ({
        ...state,
        isUpdateProductRequest: false,
        isUpdateProductSuccess: true,
        isUpdateProductFailure: false,
        listProducts: { ...getListProductStateTmp },
      });
    },
    [Actions.updateProductFailure]: (state, { payload }) => ({
      ...state,
      isUpdateProductRequest: false,
      isUpdateProductSuccess: false,
      isUpdateProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateProductState]: (state) => ({
      ...state,
      isUpdateProductRequest: false,
      isUpdateProductSuccess: false,
      isUpdateProductFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete product
    [Actions.deleteProductRequest]: (state) => ({
      ...state,
      isDeleteProductRequest: true,
      isDeleteProductSuccess: false,
      isDeleteProductFailure: false,
    }),
    [Actions.deleteProductSuccess]: (state, { payload }) => {
      const getListProductStateTmp = handleUpdateDataList(state.listProducts, payload, 'delete');

      return ({
        ...state,
        isDeleteProductRequest: false,
        isDeleteProductSuccess: true,
        isDeleteProductFailure: false,
        listProducts: { ...getListProductStateTmp },
      });
    },
    [Actions.deleteProductFailure]: (state, { payload }) => ({
      ...state,
      isDeleteProductRequest: false,
      isDeleteProductSuccess: false,
      isDeleteProductFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteProductState]: (state) => ({
      ...state,
      isDeleteProductRequest: false,
      isDeleteProductSuccess: false,
      isDeleteProductFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get all product types
    [Actions.getAllProductTypesRequest]: (state) => ({
      ...state,
      isGetAllProductTypesRequest: true,
      isGetAllProductTypesSuccess: false,
      isGetAllProductTypesFailure: false,
    }),
    [Actions.getAllProductTypesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllProductTypesRequest: false,
      isGetAllProductTypesSuccess: true,
      isGetAllProductTypesFailure: false,
      allProductTypesState: payload,
    }),
    [Actions.getAllProductTypesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductTypesRequest: false,
      isGetAllProductTypesSuccess: false,
      isGetAllProductTypesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.resetProductState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
