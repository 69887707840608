// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllResizesRequest = createAction('GET_ALL_RESIZES_REQUEST');
export const getAllResizesSuccess = createAction('GET_ALL_RESIZES_SUCCESS');
export const getAllResizesFailure = createAction('GET_ALL_RESIZES_FAILURE');

export const createResizeRequest = createAction('CREATE_RESIZE_REQUEST');
export const createResizeSuccess = createAction('CREATE_RESIZE_SUCCESS');
export const createResizeFailure = createAction('CREATE_RESIZE_FAILURE');
export const resetCreateResize = createAction('RESET_CREATE_RESIZE');

export const updateResizeRequest = createAction('UPDATE_RESIZE_REQUEST');
export const updateResizeSuccess = createAction('UPDATE_RESIZE_SUCCESS');
export const updateResizeFailure = createAction('UPDATE_RESIZE_FAILURE');
export const resetUpdateResize = createAction('RESET_UPDATE_RESIZE');

export const deleteResizeRequest = createAction('DELETE_RESIZE_REQUEST');
export const deleteResizeSuccess = createAction('DELETE_RESIZE_SUCCESS');
export const deleteResizeFailure = createAction('DELETE_RESIZE_FAILURE');
export const resetDeleteResize = createAction('RESET_DELETE_RESIZE');

export const resetResizeState = createAction('RESET_RESIZE_STATE');
