// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  //
  isGetProductPricesListRequest: false,
  isGetProductPricesListSuccess: false,
  isGetProductPricesListFailure: false,
  productPricesListState: {},
  //
  isCreateProductPriceRequest: false,
  isCreateProductPriceSuccess: false,
  isCreateProductPriceFailure: false,
  //
  isUpdateProductPriceRequest: false,
  isUpdateProductPriceSuccess: false,
  isUpdateProductPriceFailure: false,
  //
  isDeleteProductPriceRequest: false,
  isDeleteProductPriceSuccess: false,
  isDeleteProductPriceFailure: false,
  //
  isAddProductPriceRoadRequest: false,
  isAddProductPriceRoadSuccess: false,
  isAddProductPriceRoadFailure: false,
  //
  isAddProductPricePlaneRequest: false,
  isAddProductPricePlaneSuccess: false,
  isAddProductPricePlaneFailure: false,
  //
  isAddProductPriceRequest: false,
  isAddProductPriceSuccess: false,
  isAddProductPriceFailure: false,
  //
  isGetDateProductPriceRequest: false,
  isGetDateProductPriceSuccess: false,
  isGetDateProductPriceFailure: false,
  getDateProductPriceState: {},
  //
  isFindProductPriceByDateRequest: false,
  isFindProductPriceByDateSuccess: false,
  isFindProductPriceByDateFailure: false,
  findProductPriceByDateState: {},
  //
  isFindProductPriceByDateNowRequest: false,
  isFindProductPriceByDateNowSuccess: false,
  isFindProductPriceByDateNowFailure: false,
  findProductPriceByDateNowState: {},
  //
  isFindProductPriceByDateFutureRequest: false,
  isFindProductPriceByDateFutureSuccess: false,
  isFindProductPriceByDateFutureFailure: false,
  findProductPriceByDateFutureState: {},
  //
  isDeleteProductPriceByDateRequest: false,
  isDeleteProductPriceByDateSuccess: false,
  isDeleteProductPriceByDateFailure: false,
  //
  isGetAllProductSizesPricesListRequest: false,
  isGetAllProductSizesPricesListSuccess: false,
  isGetAllProductSizesPricesListFailure: false,
  getAllProductSizesPricesListState: {},
  //
  isGetAllProductSizesPricesListNowRequest: false,
  isGetAllProductSizesPricesListNowSuccess: false,
  isGetAllProductSizesPricesListNowFailure: false,
  getAllProductSizesPricesListNowState: {},
  //
  isGetAllProductSizesPricesListFutureRequest: false,
  isGetAllProductSizesPricesListFutureSuccess: false,
  isGetAllProductSizesPricesListFutureFailure: false,
  getAllProductSizesPricesListFutureState: {},
  //
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get list product prices
    [Actions.getProductPricesListRequest]: (state) => ({
      ...state,
      isGetProductPricesListRequest: true,
      isGetProductPricesListSuccess: false,
      isGetProductPricesListFailure: false,
    }),
    [Actions.getProductPricesListSuccess]: (state, { payload }) => ({
      ...state,
      isGetProductPricesListRequest: false,
      isGetProductPricesListSuccess: true,
      isGetProductPricesListFailure: false,
      productPricesListState: payload,
    }),
    [Actions.getProductPricesListFailure]: (state, { payload }) => ({
      ...state,
      isGetProductPricesListRequest: false,
      isGetProductPricesListSuccess: false,
      isGetProductPricesListFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create product price
    [Actions.createProductPriceRequest]: (state) => ({
      ...state,
      isCreateProductPriceRequest: true,
      isCreateProductPriceSuccess: false,
      isCreateProductPriceFailure: false,
    }),
    [Actions.createProductPriceSuccess]: (state) => ({
      ...state,
      isCreateProductPriceRequest: false,
      isCreateProductPriceSuccess: true,
      isCreateProductPriceFailure: false,
    }),
    [Actions.createProductPriceFailure]: (state, { payload }) => ({
      ...state,
      isCreateProductPriceRequest: false,
      isCreateProductPriceSuccess: false,
      isCreateProductPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateProductPriceState]: (state) => ({
      ...state,
      isCreateProductPriceRequest: false,
      isCreateProductPriceSuccess: false,
      isCreateProductPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Update product price
    [Actions.updateProductPriceRequest]: (state) => ({
      ...state,
      isUpdateProductPriceRequest: true,
      isUpdateProductPriceSuccess: false,
      isUpdateProductPriceFailure: false,
    }),
    [Actions.updateProductPriceSuccess]: (state) => ({
      ...state,
      isUpdateProductPriceRequest: false,
      isUpdateProductPriceSuccess: true,
      isUpdateProductPriceFailure: false,
    }),
    [Actions.updateProductPriceFailure]: (state, { payload }) => ({
      ...state,
      isUpdateProductPriceRequest: false,
      isUpdateProductPriceSuccess: false,
      isUpdateProductPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateProductPriceState]: (state) => ({
      ...state,
      isUpdateProductPriceRequest: false,
      isUpdateProductPriceSuccess: false,
      isUpdateProductPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete product price
    [Actions.deleteProductPriceRequest]: (state) => ({
      ...state,
      isDeleteProductPriceRequest: true,
      isDeleteProductPriceSuccess: false,
      isDeleteProductPriceFailure: false,
    }),
    [Actions.deleteProductPriceSuccess]: (state) => ({
      ...state,
      isDeleteProductPriceRequest: false,
      isDeleteProductPriceSuccess: true,
      isDeleteProductPriceFailure: false,
    }),
    [Actions.deleteProductPriceFailure]: (state, { payload }) => ({
      ...state,
      isDeleteProductPriceRequest: false,
      isDeleteProductPriceSuccess: false,
      isDeleteProductPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteProductPriceState]: (state) => ({
      ...state,
      isDeleteProductPriceRequest: false,
      isDeleteProductPriceSuccess: false,
      isDeleteProductPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Add product price road
    [Actions.addProductPriceRoadRequest]: (state) => ({
      ...state,
      isAddProductPriceRoadRequest: true,
      isAddProductPriceRoadSuccess: false,
      isAddProductPriceRoadFailure: false,
    }),
    [Actions.addProductPriceRoadSuccess]: (state) => ({
      ...state,
      isAddProductPriceRoadRequest: false,
      isAddProductPriceRoadSuccess: true,
      isAddProductPriceRoadFailure: false,
    }),
    [Actions.addProductPriceRoadFailure]: (state, { payload }) => ({
      ...state,
      isAddProductPriceRoadRequest: false,
      isAddProductPriceRoadSuccess: false,
      isAddProductPriceRoadFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddProductPriceRoadState]: (state) => ({
      ...state,
      isAddProductPriceRoadRequest: false,
      isAddProductPriceRoadSuccess: false,
      isAddProductPriceRoadFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Add product price plane
    [Actions.addProductPricePlaneRequest]: (state) => ({
      ...state,
      isAddProductPricePlaneRequest: true,
      isAddProductPricePlaneSuccess: false,
      isAddProductPricePlaneFailure: false,
    }),
    [Actions.addProductPricePlaneSuccess]: (state) => ({
      ...state,
      isAddProductPricePlaneRequest: false,
      isAddProductPricePlaneSuccess: true,
      isAddProductPricePlaneFailure: false,
    }),
    [Actions.addProductPricePlaneFailure]: (state, { payload }) => ({
      ...state,
      isAddProductPricePlaneRequest: false,
      isAddProductPricePlaneSuccess: false,
      isAddProductPricePlaneFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddProductPricePlaneState]: (state) => ({
      ...state,
      isAddProductPricePlaneRequest: false,
      isAddProductPricePlaneSuccess: false,
      isAddProductPricePlaneFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Add product price
    [Actions.addProductPriceRequest]: (state) => ({
      ...state,
      isAddProductPriceRequest: true,
      isAddProductPriceSuccess: false,
      isAddProductPriceFailure: false,
    }),
    [Actions.addProductPriceSuccess]: (state) => ({
      ...state,
      isAddProductPriceRequest: false,
      isAddProductPriceSuccess: true,
      isAddProductPriceFailure: false,
    }),
    [Actions.addProductPriceFailure]: (state, { payload }) => ({
      ...state,
      isAddProductPriceRequest: false,
      isAddProductPriceSuccess: false,
      isAddProductPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddProductPriceState]: (state) => ({
      ...state,
      isAddProductPriceRequest: false,
      isAddProductPriceSuccess: false,
      isAddProductPriceFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Get date product price
    [Actions.getDateProductPriceRequest]: (state) => ({
      ...state,
      isGetDateProductPriceRequest: true,
      isGetDateProductPriceSuccess: false,
      isGetDateProductPriceFailure: false,
    }),
    [Actions.getDateProductPriceSuccess]: (state, { payload }) => ({
      ...state,
      isGetDateProductPriceRequest: false,
      isGetDateProductPriceSuccess: true,
      isGetDateProductPriceFailure: false,
      getDateProductPriceState: payload,
    }),
    [Actions.getDateProductPriceFailure]: (state, { payload }) => ({
      ...state,
      isGetDateProductPriceRequest: false,
      isGetDateProductPriceSuccess: false,
      isGetDateProductPriceFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetDateProductPriceState]: (state) => ({
      ...state,
      isGetDateProductPriceRequest: false,
      isGetDateProductPriceSuccess: false,
      isGetDateProductPriceFailure: false,
      getDateProductPriceState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Find product price by date
    [Actions.findProductPriceByDateRequest]: (state) => ({
      ...state,
      isFindProductPriceByDateRequest: true,
      isFindProductPriceByDateSuccess: false,
      isFindProductPriceByDateFailure: false,
    }),
    [Actions.findProductPriceByDateSuccess]: (state, { payload }) => ({
      ...state,
      isFindProductPriceByDateRequest: false,
      isFindProductPriceByDateSuccess: true,
      isFindProductPriceByDateFailure: false,
      findProductPriceByDateState: payload,
    }),
    [Actions.findProductPriceByDateFailure]: (state, { payload }) => ({
      ...state,
      isFindProductPriceByDateRequest: false,
      isFindProductPriceByDateSuccess: false,
      isFindProductPriceByDateFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetFindProductPriceByDateState]: (state) => ({
      ...state,
      isFindProductPriceByDateRequest: false,
      isFindProductPriceByDateSuccess: false,
      isFindProductPriceByDateFailure: false,
      findProductPriceByDateState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Find product price by date now
    [Actions.findProductPriceByDateNowRequest]: (state) => ({
      ...state,
      isFindProductPriceByDateNowRequest: true,
      isFindProductPriceByDateNowSuccess: false,
      isFindProductPriceByDateNowFailure: false,
    }),
    [Actions.findProductPriceByDateNowSuccess]: (state, { payload }) => ({
      ...state,
      isFindProductPriceByDateNowRequest: false,
      isFindProductPriceByDateNowSuccess: true,
      isFindProductPriceByDateNowFailure: false,
      findProductPriceByDateNowState: payload,
    }),
    [Actions.findProductPriceByDateNowFailure]: (state, { payload }) => ({
      ...state,
      isFindProductPriceByDateNowRequest: false,
      isFindProductPriceByDateNowSuccess: false,
      isFindProductPriceByDateNowFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetFindProductPriceByDateNowState]: (state) => ({
      ...state,
      isFindProductPriceByDateNowRequest: false,
      isFindProductPriceByDateNowSuccess: false,
      isFindProductPriceByDateNowFailure: false,
      findProductPriceByDateNowState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Find product price by date future
    [Actions.findProductPriceByDateFutureRequest]: (state) => ({
      ...state,
      isFindProductPriceByDateFutureRequest: true,
      isFindProductPriceByDateFutureSuccess: false,
      isFindProductPriceByDateFutureFailure: false,
    }),
    [Actions.findProductPriceByDateFutureSuccess]: (state, { payload }) => ({
      ...state,
      isFindProductPriceByDateFutureRequest: false,
      isFindProductPriceByDateFutureSuccess: true,
      isFindProductPriceByDateFutureFailure: false,
      findProductPriceByDateFutureState: payload,
    }),
    [Actions.findProductPriceByDateFutureFailure]: (state, { payload }) => ({
      ...state,
      isFindProductPriceByDateFutureRequest: false,
      isFindProductPriceByDateFutureSuccess: false,
      isFindProductPriceByDateFutureFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetFindProductPriceByDateFutureState]: (state) => ({
      ...state,
      isFindProductPriceByDateFutureRequest: false,
      isFindProductPriceByDateFutureSuccess: false,
      isFindProductPriceByDateFutureFailure: false,
      findProductPriceByDateFutureState: {},
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete product price by date
    [Actions.deleteProductPriceByDateRequest]: (state) => ({
      ...state,
      isDeleteProductPriceByDateRequest: true,
      isDeleteProductPriceByDateSuccess: false,
      isDeleteProductPriceByDateFailure: false,
    }),
    [Actions.deleteProductPriceByDateSuccess]: (state) => ({
      ...state,
      isDeleteProductPriceByDateRequest: false,
      isDeleteProductPriceByDateSuccess: true,
      isDeleteProductPriceByDateFailure: false,
    }),
    [Actions.deleteProductPriceByDateFailure]: (state, { payload }) => ({
      ...state,
      isDeleteProductPriceByDateRequest: false,
      isDeleteProductPriceByDateSuccess: false,
      isDeleteProductPriceByDateFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteProductPriceByDateState]: (state) => ({
      ...state,
      isDeleteProductPriceByDateRequest: false,
      isDeleteProductPriceByDateSuccess: false,
      isDeleteProductPriceByDateFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Delete product price by date
    [Actions.getAllProductSizesPricesListRequest]: (state) => ({
      ...state,
      isGetAllProductSizesPricesListRequest: true,
      isGetAllProductSizesPricesListSuccess: false,
      isGetAllProductSizesPricesListFailure: false,
    }),
    [Actions.getAllProductSizesPricesListSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllProductSizesPricesListRequest: false,
      isGetAllProductSizesPricesListSuccess: true,
      isGetAllProductSizesPricesListFailure: false,
      getAllProductSizesPricesListState: payload,
    }),
    [Actions.getAllProductSizesPricesListFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductSizesPricesListRequest: false,
      isGetAllProductSizesPricesListSuccess: false,
      isGetAllProductSizesPricesListFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetAllProductSizesPricesListState]: (state) => ({
      ...state,
      isGetAllProductSizesPricesListRequest: false,
      isGetAllProductSizesPricesListSuccess: false,
      isGetAllProductSizesPricesListFailure: false,
      getAllProductSizesPricesListState: {},
    }),
    // #endregion
    // #region : Delete product price by date
    [Actions.getAllProductSizesPricesListNowRequest]: (state) => ({
      ...state,
      isGetAllProductSizesPricesListNowRequest: true,
      isGetAllProductSizesPricesListNowSuccess: false,
      isGetAllProductSizesPricesListNowFailure: false,
    }),
    [Actions.getAllProductSizesPricesListNowSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllProductSizesPricesListNowRequest: false,
      isGetAllProductSizesPricesListNowSuccess: true,
      isGetAllProductSizesPricesListNowFailure: false,
      getAllProductSizesPricesListNowState: payload,
    }),
    [Actions.getAllProductSizesPricesListNowFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductSizesPricesListNowRequest: false,
      isGetAllProductSizesPricesListNowSuccess: false,
      isGetAllProductSizesPricesListNowFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetAllProductSizesPricesListNowState]: (state) => ({
      ...state,
      isGetAllProductSizesPricesListNowRequest: false,
      isGetAllProductSizesPricesListNowSuccess: false,
      isGetAllProductSizesPricesListNowFailure: false,
      getAllProductSizesPricesListNowState: {},
    }),
    // #endregion
    // #region : Delete product price by date
    [Actions.getAllProductSizesPricesListFutureRequest]: (state) => ({
      ...state,
      isGetAllProductSizesPricesListFutureRequest: true,
      isGetAllProductSizesPricesListFutureSuccess: false,
      isGetAllProductSizesPricesListFutureFailure: false,
    }),
    [Actions.getAllProductSizesPricesListFutureSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllProductSizesPricesListFutureRequest: false,
      isGetAllProductSizesPricesListFutureSuccess: true,
      isGetAllProductSizesPricesListFutureFailure: false,
      getAllProductSizesPricesListFutureState: payload,
    }),
    [Actions.getAllProductSizesPricesListFutureFailure]: (state, { payload }) => ({
      ...state,
      isGetAllProductSizesPricesListFutureRequest: false,
      isGetAllProductSizesPricesListFutureSuccess: false,
      isGetAllProductSizesPricesListFutureFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetGetAllProductSizesPricesListFutureState]: (state) => ({
      ...state,
      isGetAllProductSizesPricesListFutureRequest: false,
      isGetAllProductSizesPricesListFutureSuccess: false,
      isGetAllProductSizesPricesListFutureFailure: false,
      getAllProductSizesPricesListFutureState: {},
    }),
    // #endregion
    // #region : Local
    [Actions.resetProductPriceState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
