// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All Label
  isFirstGetAllLabels: false,
  isGetAllLabelsRequest: false,
  isGetAllLabelsSuccess: false,
  isGetAllLabelsFailure: false,
  getAllLabelsState: {},
  // Create Label
  isCreateLabelRequest: false,
  isCreateLabelSuccess: false,
  isCreateLabelFailure: false,
  // Update Label
  isUpdateLabelRequest: false,
  isUpdateLabelSuccess: false,
  isUpdateLabelFailure: false,
  // Delete Label
  isDeleteLabelRequest: false,
  isDeleteLabelSuccess: false,
  isDeleteLabelFailure: false,
  // Get list
  isGetListLabelsRequest: false,
  isGetListLabelsSuccess: false,
  isGetListLabelsFailure: false,
  getListLabelsState: {},
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Label
    [Actions.getAllLabelsRequest]: (state) => ({
      ...state,
      isGetAllLabelsRequest: true,
      isGetAllLabelsSuccess: false,
      isGetAllLabelsFailure: false,
    }),
    [Actions.getAllLabelsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllLabels: true,
      isGetAllLabelsRequest: false,
      isGetAllLabelsSuccess: true,
      isGetAllLabelsFailure: false,
      getAllLabelsState: payload,
    }),
    [Actions.getAllLabelsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllLabelsRequest: false,
      isGetAllLabelsSuccess: false,
      isGetAllLabelsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Label
    [Actions.createLabelRequest]: (state) => ({
      ...state,
      isCreateLabelRequest: true,
      isCreateLabelSuccess: false,
      isCreateLabelFailure: false,
    }),
    [Actions.createLabelSuccess]: (state, { payload }) => {
      const getAllLabelsStateTmp = handleUpdateDataList(state.getAllLabelsState, payload.data, 'create');

      return ({
        ...state,
        isCreateLabelRequest: false,
        isCreateLabelSuccess: true,
        isCreateLabelFailure: false,
        getAllLabelsState: { ...getAllLabelsStateTmp },
      });
    },
    [Actions.createLabelFailure]: (state, { payload }) => ({
      ...state,
      isCreateLabelRequest: false,
      isCreateLabelSuccess: false,
      isCreateLabelFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateLabel]: (state) => ({
      ...state,
      isCreateLabelRequest: false,
      isCreateLabelSuccess: false,
      isCreateLabelFailure: false,
    }),
    // #endregion
    // #region : Update Label
    [Actions.updateLabelRequest]: (state) => ({
      ...state,
      isUpdateLabelRequest: true,
      isUpdateLabelSuccess: false,
      isUpdateLabelFailure: false,
    }),
    [Actions.updateLabelSuccess]: (state, { payload }) => {
      const getAllLabelsStateTmp = handleUpdateDataList(state.getAllLabelsState, payload.data, 'update');

      return ({
        ...state,
        isUpdateLabelRequest: false,
        isUpdateLabelSuccess: true,
        isUpdateLabelFailure: false,
        getAllLabelsState: { ...getAllLabelsStateTmp },
      });
    },
    [Actions.updateLabelFailure]: (state, { payload }) => ({
      ...state,
      isUpdateLabelRequest: false,
      isUpdateLabelSuccess: false,
      isUpdateLabelFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateLabel]: (state) => ({
      ...state,
      isUpdateLabelRequest: false,
      isUpdateLabelSuccess: false,
      isUpdateLabelFailure: false,
    }),
    // #endregion
    // #region : Delete Label
    [Actions.deleteLabelRequest]: (state) => ({
      ...state,
      isDeleteLabelRequest: true,
      isDeleteLabelSuccess: false,
      isDeleteLabelFailure: false,
    }),
    [Actions.deleteLabelSuccess]: (state, { payload }) => {
      const getAllLabelsStateTmp = handleUpdateDataList(state.getAllLabelsState, payload, 'delete');

      return ({
        ...state,
        isDeleteLabelRequest: false,
        isDeleteLabelSuccess: true,
        isDeleteLabelFailure: false,
        getAllLabelsState: { ...getAllLabelsStateTmp },
      });
    },
    [Actions.deleteLabelFailure]: (state, { payload }) => ({
      ...state,
      isDeleteLabelRequest: false,
      isDeleteLabelSuccess: false,
      isDeleteLabelFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteLabel]: (state) => ({
      ...state,
      isDeleteLabelRequest: false,
      isDeleteLabelSuccess: false,
      isDeleteLabelFailure: false,
    }),
    // #endregion
    // #region Get list
    [Actions.getListLabelsRequest]: (state) => ({
      ...state,
      isGetListLabelsRequest: true,
      isGetListLabelsSuccess: false,
      isGetListLabelsFailure: false,
    }),
    [Actions.getListLabelsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetListLabels: true,
      isGetListLabelsRequest: false,
      isGetListLabelsSuccess: true,
      isGetListLabelsFailure: false,
      getListLabelsState: payload,
    }),
    [Actions.getListLabelsFailure]: (state, { payload }) => ({
      ...state,
      isGetListLabelsRequest: false,
      isGetListLabelsSuccess: false,
      isGetListLabelsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Local
    [Actions.resetLabelState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
