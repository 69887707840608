/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* signIn({ payload }) {
  try {
    const response = yield call(() => axios.post(
      `${process.env.REACT_APP_DEV_MICRO_APP}/clients/web/login`,
      payload,
    ));
    const {
      token_type, access_token, refresh_token, expires_in,
    } = response.data;
    localStorage.setItem('accessToken', `${token_type} ${access_token}`);
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('expires_in', expires_in);
    localStorage.setItem('is_login', true);
    yield put(Actions.signInSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.signInFailure(messages));
    }
  }
}

function* changePassword({ payload }) {
  try {
    const { id, currentPassword, newPassword } = payload;
    yield call(() => axiosMicro.patch(`/users/${id}/password`, {
      current_password: currentPassword,
      new_password: newPassword,
    }));
    yield put(Actions.changePasswordSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.changePasswordFailure(messages));
    }
  }
}

function* requireChangePassword({ payload }) {
  try {
    const { id, currentPassword, newPassword } = payload;
    yield call(() => axiosMicro.patch(`/users/${id}/password`, {
      current_password: currentPassword,
      new_password: newPassword,
    }));
    yield put(Actions.requireChangePasswordSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.requireChangePasswordFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.signInRequest, signIn);
  yield takeLatest(Actions.changePasswordRequest, changePassword);
  yield takeLatest(Actions.requireChangePasswordRequest, requireChangePassword);
}
