// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  isGetListCustomerRequest: false,
  isGetListCustomerSuccess: false,
  isGetListCustomerFailure: false,
  listCustomer: {},

  isAddCustomerRequest: false,
  isAddCustomerSuccess: false,
  isAddCustomerFailure: false,
  addCustomerResponse: {},

  isGetDetailCustomerRequest: false,
  isGetDetailCustomerSuccess: false,
  isGetDetailCustomerFailure: false,
  detailCustomer: {},

  isGetAllRegionRequest: false,
  isGetAllRegionSuccess: false,
  isGetAllRegionFailure: false,
  listRegion: {},

  isGetAllWardRequest: false,
  isGetAllWardSuccess: false,
  isGetAllWardFailure: false,
  listWard: {},

  isGetAllDistrictRequest: false,
  isGetAllDistrictSuccess: false,
  isGetAllDistrictFailure: false,
  listDistrict: {},

  isDeleteCustomerRequest: false,
  isDeleteCustomerSuccess: false,
  isDeleteCustomerFailure: false,

  isEditCustomerRequest: false,
  isEditCustomerSuccess: false,
  isEditCustomerFailure: false,
  editCustomerResponse: {},

  isImportCustomerRequest: false,
  isImportCustomerSuccess: false,
  isImportCustomerFailure: false,

  isGetAllOrderCustomerRequest: false,
  isGetAllOrderCustomerSuccess: false,
  isGetAllOrderCustomerFailure: false,
  listOrderCustomer: {},

  isUpdateCustomerBRequest: false,
  isUpdateCustomerBSuccess: false,
  isUpdateCustomerBFailure: false,
  updateCustomerBState: {},

  // Get All Customer Debt
  isGetAllCustomerDebtRequest: false,
  isGetAllCustomerDebtSuccess: false,
  isGetAllCustomerDebtFailure: false,
  getAllCustomerDebtState: {},

  // Check exist customer by code
  isCheckExistCustomerByCodeRequest: false,
  isCheckExistCustomerByCodeSuccess: false,
  isCheckExistCustomerByCodeFailure: false,
  checkExistCustomerByCodeState: {},

  // Check exist customer by email
  isCheckExistCustomerByEmailRequest: false,
  isCheckExistCustomerByEmailSuccess: false,
  isCheckExistCustomerByEmailFailure: false,
  checkExistCustomerByEmailState: {},

  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region: get list customer
    [Actions.getListCustomerRequest]: (state) => ({
      ...state,
      isGetListCustomerRequest: true,
      isGetListCustomerSuccess: false,
      isGetDetailCustomerFailure: false,
    }),
    [Actions.getListCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isGetListCustomerRequest: false,
      isGetListCustomerSuccess: true,
      isGetListCustomerFailure: false,
      listCustomer: payload,
    }),
    [Actions.getListCustomerFailure]: (state, { payload }) => ({
      ...state,
      isGetListCustomerRequest: false,
      isGetDetailCustomerSuccess: false,
      isGetDetailCustomerFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region : add customer
    [Actions.addCustomerRequest]: (state) => ({
      ...state,
      isAddCustomerRequest: true,
      isAddCustomerSuccess: false,
      isAddCustomerFailure: false,
    }),
    [Actions.addCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isAddCustomerRequest: false,
      isAddCustomerSuccess: true,
      isAddCustomerFailure: false,
      addCustomerResponse: payload,
    }),
    [Actions.addCustomerFailure]: (state, { payload }) => ({
      ...state,
      isAddCustomerRequest: false,
      isAddCustomerSuccess: false,
      isAddCustomerFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetAddCustomerState]: (state) => ({
      ...state,
      isAddCustomerFailure: false,
      isAddCustomerRequest: false,
      isAddCustomerSuccess: false,
      addCustomerResponse: {},
      errorMessages: [],
    }),
    // #endregion

    // #region : get detail
    [Actions.getDetailCustomerRequest]: (state) => ({
      ...state,
      isGetDetailCustomerRequest: true,
      isGetDetailCustomerSuccess: false,
      isGetDetailCustomerFailure: false,
    }),
    [Actions.getDetailCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isGetDetailCustomerRequest: false,
      isGetDetailCustomerSuccess: true,
      isGetDetailCustomerFailure: false,
      detailCustomer: payload,
    }),
    [Actions.getDetailCustomerFailure]: (state, { payload }) => ({
      ...state,
      isGetDetailCustomerRequest: false,
      isGetDetailCustomerSuccess: false,
      isGetDetailCustomerFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region : region
    [Actions.getAllRegionRequest]: (state) => ({
      ...state,
      isGetAllRegionRequest: true,
      isGetAllRegionSuccess: false,
      isGetAllRegionFailure: false,
    }),
    [Actions.getAllRegionSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllRegionRequest: false,
      isGetAllRegionSuccess: true,
      isGetAllRegionFailure: false,
      listRegion: payload,
    }),
    [Actions.getAllRegionFailure]: (state, { payload }) => ({
      ...state,
      isGetAllRegionRequest: false,
      isGetAllRegionSuccess: false,
      isGetAllRegionFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : region
    [Actions.getAllWardRequest]: (state) => ({
      ...state,
      isGetAllWardRequest: true,
      isGetAllWardSuccess: false,
      isGetAllWardFailure: false,
    }),
    [Actions.getAllWardSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllWardRequest: false,
      isGetAllWardSuccess: true,
      isGetAllWardFailure: false,
      listWard: payload,
    }),
    [Actions.getAllWardFailure]: (state, { payload }) => ({
      ...state,
      isGetAllWardRequest: false,
      isGetAllWardSuccess: false,
      isGetAllWardFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region
    [Actions.getAllDistrictRequest]: (state) => ({
      ...state,
      isGetAllDistrictRequest: true,
      isGetAllDistrictSuccess: false,
      isGetAllDistrictFailure: false,
    }),
    [Actions.getAllDistrictSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllDistrictRequest: false,
      isGetAllDistrictSuccess: true,
      isGetAllDistrictFailure: false,
      listDistrict: payload,
    }),
    [Actions.getAllDistrictFailure]: (state, { payload }) => ({
      ...state,
      isGetAllDistrictFailure: true,
      isGetAllDistrictSuccess: false,
      isGetAllDistrictRequest: false,
      errorMessages: payload,
    }),
    // #endregion
    // #region: delete customer
    [Actions.deleteCustomerRequest]: (state) => ({
      ...state,
      isDeleteCustomerRequest: true,
      isDeleteCustomerSuccess: false,
      isDeleteCustomerFailure: false,
    }),
    [Actions.deleteCustomerSuccess]: (state) => ({
      ...state,
      isDeleteCustomerRequest: false,
      isDeleteCustomerSuccess: true,
      isDeleteCustomerFailure: false,

    }),
    [Actions.deleteCustomerFailure]: (state, { payload }) => ({
      ...state,
      isDeleteCustomerRequest: false,
      isDeleteCustomerSuccess: false,
      isDeleteCustomerFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteCustomerState]: (state) => ({
      ...state,
      isDeleteCustomerFailure: false,
      isDeleteCustomerRequest: false,
      isDeleteCustomerSuccess: false,
      errorMessages: [],
    }),
    // #endregion

    // #region: edit customer
    [Actions.editCustomerRequest]: (state) => ({
      ...state,
      isEditCustomerRequest: true,
      isEditCustomerSuccess: false,
      isEditCustomerFailure: false,
    }),
    [Actions.editCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isEditCustomerRequest: false,
      isEditCustomerSuccess: true,
      isEditCustomerFailure: false,
      editCustomerResponse: payload,
    }),
    [Actions.editCustomerFailure]: (state, { payload }) => ({
      ...state,
      isEditCustomerRequest: false,
      isEditCustomerSuccess: false,
      isEditCustomerFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetEditCustomerState]: (state) => ({
      ...state,
      isEditCustomerFailure: false,
      isEditCustomerRequest: false,
      isEditCustomerSuccess: false,
      editCustomerResponse: {},
      errorMessages: [],
    }),
    // #endregion

    // #region Update customer b
    [Actions.updateCustomerBRequest]: (state) => ({
      ...state,
      isUpdateCustomerBRequest: true,
      isUpdateCustomerBSuccess: false,
      isUpdateCustomerBFailure: false,
    }),
    [Actions.updateCustomerBSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateCustomerBRequest: false,
      isUpdateCustomerBSuccess: true,
      isUpdateCustomerBFailure: false,
      updateCustomerBState: payload,
    }),
    [Actions.updateCustomerBFailure]: (state, { payload }) => ({
      ...state,
      isUpdateCustomerBRequest: false,
      isUpdateCustomerBSuccess: false,
      isUpdateCustomerBFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateCustomerB]: (state) => ({
      ...state,
      isUpdateCustomerBRequest: false,
      isUpdateCustomerBSuccess: false,
      isUpdateCustomerBFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region: get all customer debt
    [Actions.getAllCustomerDebtRequest]: (state) => ({
      ...state,
      isGetAllCustomerDebtRequest: true,
      isGetAllCustomerDebtSuccess: false,
      isGetAllCustomerDebtFailure: false,
    }),
    [Actions.getAllCustomerDebtSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCustomerDebtRequest: false,
      isGetAllCustomerDebtSuccess: true,
      isGetAllCustomerDebtFailure: false,
      getAllCustomerDebtState: payload,
    }),
    [Actions.getAllCustomerDebtFailure]: (state, { payload }) => ({
      ...state,
      isGetAllCustomerDebtRequest: false,
      isGetAllCustomerDebtSuccess: false,
      isGetAllCustomerDebtFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region
    [Actions.checkExistCustomerByCodeRequest]: (state) => ({
      ...state,
      isCheckExistCustomerByCodeRequest: true,
      isCheckExistCustomerByCodeSuccess: false,
      isCheckExistCustomerByCodeFailure: false,
    }),
    [Actions.checkExistCustomerByCodeSuccess]: (state, { payload }) => ({
      ...state,
      isCheckExistCustomerByCodeRequest: false,
      isCheckExistCustomerByCodeSuccess: true,
      isCheckExistCustomerByCodeFailure: false,
      checkExistCustomerByCodeState: payload,
    }),
    [Actions.checkExistCustomerByCodeFailure]: (state, { payload }) => ({
      ...state,
      isCheckExistCustomerByCodeRequest: false,
      isCheckExistCustomerByCodeSuccess: false,
      isCheckExistCustomerByCodeFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCheckExistCustomerByCode]: (state) => ({
      ...state,
      isCheckExistCustomerByCodeRequest: false,
      isCheckExistCustomerByCodeSuccess: false,
      isCheckExistCustomerByCodeFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region
    [Actions.checkExistCustomerByEmailRequest]: (state) => ({
      ...state,
      isCheckExistCustomerByEmailRequest: true,
      isCheckExistCustomerByEmailSuccess: false,
      isCheckExistCustomerByEmailFailure: false,
    }),
    [Actions.checkExistCustomerByEmailSuccess]: (state, { payload }) => ({
      ...state,
      isCheckExistCustomerByEmailRequest: false,
      isCheckExistCustomerByEmailSuccess: true,
      isCheckExistCustomerByEmailFailure: false,
      checkExistCustomerByEmailState: payload,
    }),
    [Actions.checkExistCustomerByEmailFailure]: (state, { payload }) => ({
      ...state,
      isCheckExistCustomerByEmailRequest: false,
      isCheckExistCustomerByEmailSuccess: false,
      isCheckExistCustomerByEmailFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCheckExistCustomerByEmail]: (state) => ({
      ...state,
      isCheckExistCustomerByEmailRequest: false,
      isCheckExistCustomerByEmailSuccess: false,
      isCheckExistCustomerByEmailFailure: false,
      errorMessages: [],
    }),

    // #endregion

    // #region : Local
    [Actions.resetCustomerState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
