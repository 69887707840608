// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllExportWarehousesRequest = createAction('GET_ALL_EXPORTWAREHOUSES_REQUEST');
export const getAllExportWarehousesSuccess = createAction('GET_ALL_EXPORTWAREHOUSES_SUCCESS');
export const getAllExportWarehousesFailure = createAction('GET_ALL_EXPORTWAREHOUSES_FAILURE');

export const createExportWarehouseRequest = createAction('CREATE_EXPORTWAREHOUSE_REQUEST');
export const createExportWarehouseSuccess = createAction('CREATE_EXPORTWAREHOUSE_SUCCESS');
export const createExportWarehouseFailure = createAction('CREATE_EXPORTWAREHOUSE_FAILURE');
export const resetCreateExportWarehouse = createAction('RESET_CREATE_EXPORTWAREHOUSE');

export const cancelExportWarehouseRequest = createAction('CANCEL_EXPORTWAREHOUSE_REQUEST');
export const cancelExportWarehouseSuccess = createAction('CANCEL_EXPORTWAREHOUSE_SUCCESS');
export const cancelExportWarehouseFailure = createAction('CANCEL_EXPORTWAREHOUSE_FAILURE');
export const resetCancelExportWarehouse = createAction('RESET_CANCEL_EXPORTWAREHOUSE');

export const updateExportWarehouseRequest = createAction('UPDATE_EXPORTWAREHOUSE_REQUEST');
export const updateExportWarehouseSuccess = createAction('UPDATE_EXPORTWAREHOUSE_SUCCESS');
export const updateExportWarehouseFailure = createAction('UPDATE_EXPORTWAREHOUSE_FAILURE');
export const resetUpdateExportWarehouse = createAction('RESET_UPDATE_EXPORTWAREHOUSE');

export const deleteExportWarehouseRequest = createAction('DELETE_EXPORTWAREHOUSE_REQUEST');
export const deleteExportWarehouseSuccess = createAction('DELETE_EXPORTWAREHOUSE_SUCCESS');
export const deleteExportWarehouseFailure = createAction('DELETE_EXPORTWAREHOUSE_FAILURE');
export const resetDeleteExportWarehouse = createAction('RESET_DELETE_EXPORTWAREHOUSE');

export const getOrderExportWarehouseRequest = createAction('GET_ORDER_EXPORTWAREHOUSE_REQUEST');
export const getOrderExportWarehouseSuccess = createAction('GET_ORDER_EXPORTWAREHOUSE_SUCCESS');
export const getOrderExportWarehouseFailure = createAction('GET_ORDER_EXPORTWAREHOUSE_FAILURE');

export const getListOrderExportWarehouseRequest = createAction('GET_LIST_ORDER_EXPORTWAREHOUSE_REQUEST');
export const getListOrderExportWarehouseSuccess = createAction('GET_LIST_ORDER_EXPORTWAREHOUSE_SUCCESS');
export const getListOrderExportWarehouseFailure = createAction('GET_LIST_ORDER_EXPORTWAREHOUSE_FAILURE');

export const getCustomerExportWarehouseRequest = createAction('GET_CUSTOMER_EXPORTWAREHOUSE_REQUEST');
export const getCustomerExportWarehouseSuccess = createAction('GET_CUSTOMER_EXPORTWAREHOUSE_SUCCESS');
export const getCustomerExportWarehouseFailure = createAction('GET_CUSTOMER_EXPORTWAREHOUSE_FAILURE');

export const considerExportWarehouseRequest = createAction('CONSIDER_EXPORTWAREHOUSE_REQUEST');
export const considerExportWarehouseSuccess = createAction('CONSIDER_EXPORTWAREHOUSE_SUCCESS');
export const considerExportWarehouseFailure = createAction('CONSIDER_EXPORTWAREHOUSE_FAILURE');
export const resetConsiderExportWarehouse = createAction('RESET_CONSIDER_EXPORTWAREHOUSE');

export const exportDriverWarehousesRequest = createAction('EXPORT_DRIVER_WAREHOUSES_REQUEST');
export const exportDriverWarehousesSuccess = createAction('EXPORT_DRIVER_WAREHOUSES_SUCCESS');
export const exportDriverWarehousesFailure = createAction('EXPORT_DRIVER_WAREHOUSES_FAILURE');
export const resetExportDriverWarehouses = createAction('RESET_EXPORT_DRIVER_WAREHOUSES');

export const resetExportWarehouseState = createAction('RESET_EXPORTWAREHOUSE_STATE');
