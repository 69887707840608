/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllCustomerBranches({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/customer-branches?${payload}`)
        : () => axiosMicro.get('/customer-branches', { params: payload }),
    );
    yield put(Actions.getAllCustomerBranchesSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllCustomerBranchesFailure(messages));
    }
  }
}

function* createCustomerBranch({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/customer-branches', payload));
    yield put(Actions.createCustomerBranchSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createCustomerBranchFailure(messages));
    }
  }
}

function* updateCustomerBranch({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/customer-branches/${payload.id}`, payload.body));
    yield put(Actions.updateCustomerBranchSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateCustomerBranchFailure(messages));
    }
  }
}

function* deleteCustomerBranch({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/customer-branches/${payload}`));
    yield put(Actions.deleteCustomerBranchSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteCustomerBranchFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllCustomerBranchesRequest, getAllCustomerBranches);
  yield takeLatest(Actions.createCustomerBranchRequest, createCustomerBranch);
  yield takeLatest(Actions.updateCustomerBranchRequest, updateCustomerBranch);
  yield takeLatest(Actions.deleteCustomerBranchRequest, deleteCustomerBranch);
}
