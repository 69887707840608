// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllLateReviewsRequest = createAction('GET_ALL_LATEREVIEWS_REQUEST');
export const getAllLateReviewsSuccess = createAction('GET_ALL_LATEREVIEWS_SUCCESS');
export const getAllLateReviewsFailure = createAction('GET_ALL_LATEREVIEWS_FAILURE');

export const createLateReviewRequest = createAction('CREATE_LATEREVIEW_REQUEST');
export const createLateReviewSuccess = createAction('CREATE_LATEREVIEW_SUCCESS');
export const createLateReviewFailure = createAction('CREATE_LATEREVIEW_FAILURE');
export const resetCreateLateReview = createAction('RESET_CREATE_LATEREVIEW');

export const updateLateReviewRequest = createAction('UPDATE_LATEREVIEW_REQUEST');
export const updateLateReviewSuccess = createAction('UPDATE_LATEREVIEW_SUCCESS');
export const updateLateReviewFailure = createAction('UPDATE_LATEREVIEW_FAILURE');
export const resetUpdateLateReview = createAction('RESET_UPDATE_LATEREVIEW');

export const deleteLateReviewRequest = createAction('DELETE_LATEREVIEW_REQUEST');
export const deleteLateReviewSuccess = createAction('DELETE_LATEREVIEW_SUCCESS');
export const deleteLateReviewFailure = createAction('DELETE_LATEREVIEW_FAILURE');
export const resetDeleteLateReview = createAction('RESET_DELETE_LATEREVIEW');

export const changeStatusLateReviewRequest = createAction('CHANGE_STATUS_LATEREVIEW_REQUEST');
export const changeStatusLateReviewSuccess = createAction('CHANGE_STATUS_LATEREVIEW_SUCCESS');
export const changeStatusLateReviewFailure = createAction('CHANGE_STATUS_LATEREVIEW_FAILURE');
export const resetChangeStatusLateReview = createAction('RESET_CHANGE_STATUS_LATEREVIEW');

export const resetLateReviewState = createAction('RESET_LATEREVIEW_STATE');
