// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const searchReceiverRequest = createAction('SEARCH_RECEIVER_REQUEST');
export const searchReceiverSuccess = createAction('SEARCH_RECEIVER_SUCCESS');
export const searchReceiverFailure = createAction('SEARCH_RECEIVER_FAILURE');
export const resetSearchReceiverState = createAction(
  'RESET_SEARCH_RECEIVER_STATE',
);

export const regionReceiverRequest = createAction('REGION_RECEIVER_REQUEST');
export const regionReceiverSuccess = createAction('REGION_RECEIVER_SUCCESS');
export const regionReceiverFailure = createAction('REGION_RECEIVER_FAILURE');
export const resetRegionReceiverState = createAction(
  'RESET_REGION_RECEIVER_STATE',
);

export const searchProductTypeRequest = createAction(
  'SEARCH_PRODUCT_TYPE_REQUEST',
);
export const searchProductTypeSuccess = createAction(
  'SEARCH_PRODUCT_TYPE_SUCCESS',
);
export const searchProductTypeFailure = createAction(
  'SEARCH_PRODUCT_TYPE_FAILURE',
);
export const resetSearchProductTypeState = createAction(
  'RESET_SEARCH_PRODUCT_TYPE_STATE',
);

export const searchProductRequest = createAction('SEARCH_PRODUCT_REQUEST');
export const searchProductSuccess = createAction('SEARCH_PRODUCT_SUCCESS');
export const searchProductFailure = createAction('SEARCH_PRODUCT_FAILURE');
export const resetSearchProductState = createAction(
  'RESET_SEARCH_PRODUCT_STATE',
);

export const getListOrdersRequest = createAction('GET_LIST_ORDERS_REQUEST');
export const getListOrdersSuccess = createAction('GET_LIST_ORDERS_SUCCESS');
export const getListOrdersFailure = createAction('GET_LIST_ORDERS_FAILURE');

export const getOrderDetailRequest = createAction('GET_ORDER_DETAIL_REQUEST');
export const getOrderDetailSuccess = createAction('GET_ORDER_DETAIL_SUCCESS');
export const getOrderDetailFailure = createAction('GET_ORDER_DETAIL_FAILURE');

export const getAllOrderRequest = createAction('GET_ALL_ORDER_REQUEST');
export const getAllOrderSuccess = createAction('GET_ALL_ORDER_SUCCESS');
export const getAllOrderFailure = createAction('GET_ALL_ORDER_FAILURE');

export const createOrderRequest = createAction('CREATE_ORDER_REQUEST');
export const createOrderSuccess = createAction('CREATE_ORDER_SUCCESS');
export const createOrderFailure = createAction('CREATE_ORDER_FAILURE');
export const resetCreateOrderState = createAction('RESET_CREATE_ORDER_STATE');

export const updateOrderRequest = createAction('UPDATE_ORDER_REQUEST');
export const updateOrderSuccess = createAction('UPDATE_ORDER_SUCCESS');
export const updateOrderFailure = createAction('UPDATE_ORDER_FAILURE');
export const resetUpdateOrderState = createAction('RESET_UPDATE_ORDER_STATE');

export const deleteOrderRequest = createAction('DELETE_ORDER_REQUEST');
export const deleteOrderSuccess = createAction('DELETE_ORDER_SUCCESS');
export const deleteOrderFailure = createAction('DELETE_ORDER_FAILURE');
export const resetDeleteOrderState = createAction('RESET_DELETE_ORDER_STATE');

export const getAllShippingOrderRequest = createAction('GET_ALL_SHIPPING_ORDER_REQUEST');
export const getAllShippingOrderSuccess = createAction('GET_ALL_SHIPPING_ORDER_SUCCESS');
export const getAllShippingOrderFailure = createAction('GET_ALL_SHIPPING_ORDER_FAILURE');

export const updateShippingOrderRequest = createAction('UPDATE_SHIPPING_ORDER_REQUEST');
export const updateShippingOrderSuccess = createAction('UPDATE_SHIPPING_ORDER_SUCCESS');
export const updateShippingOrderFailure = createAction('UPDATE_SHIPPING_ORDER_FAILURE');
export const resetUpdateShippingOrderState = createAction('RESET_UPDATE_SHIPPING_ORDER_STATE');

export const updateShippingIdOrderRequest = createAction('UPDATE_SHIPPING_ID_ORDER_REQUEST');
export const updateShippingIdOrderSuccess = createAction('UPDATE_SHIPPING_ID_ORDER_SUCCESS');
export const updateShippingIdOrderFailure = createAction('UPDATE_SHIPPING_ID_ORDER_FAILURE');
export const resetUpdateShippingIdOrderState = createAction('RESET_UPDATE_SHIPPING_ID_ORDER_STATE');

export const printedOrderRequest = createAction('PRINTED_ORDER_REQUEST');
export const printedOrderSuccess = createAction('PRINTED_ORDER_SUCCESS');
export const printedOrderFailure = createAction('PRINTED_ORDER_FAILURE');
export const resetPrintedOrderState = createAction('RESET_PRINTED_ORDER_STATE');

export const updateStatusOrderRequest = createAction('UPDATE_STATUS_ORDER_REQUEST');
export const updateStatusOrderSuccess = createAction('UPDATE_STATUS_ORDER_SUCCESS');
export const updateStatusOrderFailure = createAction('UPDATE_STATUS_ORDER_FAILURE');
export const resetUpdateStatusOrderState = createAction('RESET_UPDATE_STATUS_ORDER_STATE');

export const getAllWareHouseOrderRequest = createAction('GET_ALL_WAREHOUSE_ORDER_REQUEST');
export const getAllWareHouseOrderSuccess = createAction('GET_ALL_WAREHOUSE_ORDER_SUCCESS');
export const getAllWareHouseOrderFailure = createAction('GET_ALL_WAREHOUSE_ORDER_FAILURE');

export const getAllRegionGroupOrderRequest = createAction('GET_ALL_REGION_ORDER_REQUEST');
export const getAllRegionGroupOrderSuccess = createAction('GET_ALL_REGION_ORDER_SUCCESS');
export const getAllRegionGroupOrderFailure = createAction('GET_ALL_REGION_ORDER_FAILURE');

export const getAllProductPriceOrderRequest = createAction('GET_ALL_PRODUCT_PRICE_ORDER_REQUEST');
export const getAllProductPriceOrderSuccess = createAction('GET_ALL_PRODUCT_PRICE_ORDER_SUCCESS');
export const getAllProductPriceOrderFailure = createAction('GET_ALL_PRODUCT_PRICE_ORDER_FAILURE');

export const getOrderLateRequest = createAction('GET_ORDER_LATE_REQUEST');
export const getOrderLateSuccess = createAction('GET_ORDER_LATE_SUCCESS');
export const getOrderLateFailure = createAction('GET_ORDER_LATE_FAILURE');

export const updateStatusMultiRequest = createAction('UPDATE_STATUS_MULTI_REQUEST');
export const updateStatusMultiSuccess = createAction('UPDATE_STATUS_MULTI_SUCCESS');
export const updateStatusMultiFailure = createAction('UPDATE_STATUS_MULTI_FAILURE');
export const resetUpdateStatusMultiState = createAction('RESET_UPDATE_STATUS_MULTI_STATE');

export const getAllOrderInChartRevenueRequest = createAction('GET_ALL_ORDER_IN_CHART_REVENUE_REQUEST');
export const getAllOrderInChartRevenueSuccess = createAction('GET_ALL_ORDER_IN_CHART_REVENUE_SUCCESS');
export const getAllOrderInChartRevenueFailure = createAction('GET_ALL_ORDER_IN_CHART_REVENUE_FAILURE');

export const getAllOrderInChartGrowRequest = createAction('GET_ALL_ORDER_IN_CHART_GROW_REQUEST');
export const getAllOrderInChartGrowSuccess = createAction('GET_ALL_ORDER_IN_CHART_GROW_SUCCESS');
export const getAllOrderInChartGrowFailure = createAction('GET_ALL_ORDER_IN_CHART_GROW_FAILURE');

export const updateContemporaryExpenseRequest = createAction('UPDATE_CONTEMPORARY_ORDER_REQUEST');
export const updateContemporaryExpenseSuccess = createAction('UPDATE_CONTEMPORARY_EXPENSE_SUCCESS');
export const updateContemporaryExpenseFailure = createAction('UPDATE_CONTEMPORARY_EXPENSE_FAILURE');
export const resetUpdateContemporaryExpenseState = createAction('RESET_CONTEMPORARY_EXPENSE_STATE');

export const updateWarehouseOrderRequest = createAction('UPDATE_WAREHOUSE_ORDER_REQUEST');
export const updateWarehouseOrderSuccess = createAction('UPDATE_WAREHOUSE_ORDER_SUCCESS');
export const updateWarehouseOrderFailure = createAction('UPDATE_WAREHOUSE_ORDER_FAILURE');
export const resetUpdateWarehouseOrderState = createAction('RESET_UPDATE_WAREHOUSE_ORDER_STATE');

export const rollBackOrderRequest = createAction('ROLL_BACK_ORDER_REQUEST');
export const rollBackOrderSuccess = createAction('ROLL_BACK_ORDER_SUCCESS');
export const rollBackOrderFailure = createAction('ROLL_BACK_ORDER_FAILURE');
export const resetRollBackOrderState = createAction('RESET_ROLL_BACK_ORDER_STATE');

export const getAllOrderFromAppRequest = createAction('GET_ALL_ORDER_FROM_APP_REQUEST');
export const getAllOrderFromAppSuccess = createAction('GET_ALL_ORDER_FROM_APP_SUCCESS');
export const getAllOrderFromAppFailure = createAction('GET_ALL_ORDER_FROM_APP_FAILURE');

export const approveOrderFromAppRequest = createAction('APPROVE_ORDER_FROM_APP_REQUEST');
export const approveOrderFromAppSuccess = createAction('APPROVE_ORDER_FROM_APP_SUCCESS');
export const approveOrderFromAppFailure = createAction('APPROVE_ORDER_FROM_APP_FAILURE');
export const resetApproveOrderFromAppState = createAction('RESET_APPROVE_ORDER_FROM_APP_STATE');

export const refuseOrderFromAppRequest = createAction('REFUSE_ORDER_FROM_APP_REQUEST');
export const refuseOrderFromAppSuccess = createAction('REFUSE_ORDER_FROM_APP_SUCCESS');
export const refuseOrderFromAppFailure = createAction('REFUSE_ORDER_FROM_APP_FAILURE');
export const resetRefuseOrderFromAppState = createAction('RESET_REFUSE_ORDER_FROM_APP_STATE');

export const printOrderAndRedirectRequest = createAction('PRINT_ORDER_AND_REDIRECT_REQUEST');
export const printOrderAndRedirectSuccess = createAction('PRINT_ORDER_AND_REDIRECT_SUCCESS');
export const printOrderAndRedirectFailure = createAction('PRINT_ORDER_AND_REDIRECT_FAILURE');
export const resetPrintOrderAndRedirectState = createAction('RESET_PRINT_ORDER_AND_REDIRECT_STATE');

export const updatePaymentOrderAppRequest = createAction('UPDATE_PAYMENT_ORDER_APP_REQUEST');
export const updatePaymentOrderAppSuccess = createAction('UPDATE_PAYMENT_ORDER_APP_SUCCESS');
export const updatePaymentOrderAppFailure = createAction('UPDATE_PAYMENT_ORDER_APP_FAILURE');
export const resetUpdatePaymentOrderAppState = createAction('RESET_UPDATE_PAYMENT_ORDER_APP_STATE');

export const getLogOrderRequest = createAction('GET_LOG_ORDER_REQUEST');
export const getLogOrderSuccess = createAction('GET_LOG_ORDER_SUCCESS');
export const getLogOrderFailure = createAction('GET_LOG_ORDER_FAILURE');

export const exchangeVoucherOrderAppRequest = createAction('EXCHANGE_VOUCHER_ORDER_APP_REQUEST');
export const exchangeVoucherOrderAppSuccess = createAction('EXCHANGE_VOUCHER_ORDER_APP_SUCCESS');
export const exchangeVoucherOrderAppFailure = createAction('EXCHANGE_VOUCHER_ORDER_APP_FAILURE');
export const resetExchangeVoucherState = createAction('RESET_EXCHANGE_VOUCHER_STATE');

export const getOrderByQrCodeRequest = createAction('GET_ORDER_BY_QR_CODE_REQUEST');
export const getOrderByQrCodeSuccess = createAction('GET_ORDER_BY_QR_CODE_SUCCESS');
export const getOrderByQrCodeFailure = createAction('GET_ORDER_BY_QR_CODE_FAILURE');
export const resetGetOrderByQrCode = createAction('RESET_GET_ORDER_BY_QR_CODE');

export const cancelOrderRequest = createAction('CANCEL_ORDER_REQUEST');
export const cancelOrderSuccess = createAction('CANCEL_ORDER_SUCCESS');
export const cancelOrderFailure = createAction('CANCEL_ORDER_FAILURE');
export const resetCancelOrderState = createAction('RESET_CANCEL_ORDER_STATE');

export const refundOrderRequest = createAction('REFUND_ORDER_REQUEST');
export const refundOrderSuccess = createAction('REFUND_ORDER_SUCCESS');
export const refundOrderFailure = createAction('REFUND_ORDER_FAILURE');
export const resetRefundOrderState = createAction('RESET_REFUND_ORDER_STATE');

export const getOrderByQrCodeStaffPackingRequest = createAction('GET_ORDER_BY_QR_CODE_STAFF_PACKING_REQUEST');
export const getOrderByQrCodeStaffPackingSuccess = createAction('GET_ORDER_BY_QR_CODE_STAFF_PACKING_SUCCESS');
export const getOrderByQrCodeStaffPackingFailure = createAction('GET_ORDER_BY_QR_CODE_STAFF_PACKING_FAILURE');
export const resetGetOrderByQrCodeStaffPacking = createAction('RESET_GET_ORDER_BY_QR_CODE_STAFF_PACKING');

export const getListOrderStaffPackingRequest = createAction('GET_LIST_ORDER_STAFF_PACKING_REQUEST');
export const getListOrderStaffPackingSuccess = createAction('GET_LIST_ORDER_STAFF_PACKING_SUCCESS');
export const getListOrderStaffPackingFailure = createAction('GET_LIST_ORDER_STAFF_PACKING_FAILURE');

export const updateStatusOrderStaffPackingRequest = createAction('UPDATE_STATUS_ORDER_STAFF_PACKING_REQUEST');
export const updateStatusOrderStaffPackingSuccess = createAction('UPDATE_STATUS_ORDER_STAFF_PACKING_SUCCESS');
export const updateStatusOrderStaffPackingFailure = createAction('UPDATE_STATUS_ORDER_STAFF_PACKING_FAILURE');
export const resetUpdateStatusOrderStaffPacking = createAction('RESET_UPDATE_STATUS_ORDER_STAFF_PACKING');

export const getPriceShippingLalaRequest = createAction('GET_PRICE_SHIPPING_LALA_REQUEST');
export const getPriceShippingLalaSuccess = createAction('GET_PRICE_SHIPPING_LALA_SUCCESS');
export const getPriceShippingLalaFailure = createAction('GET_PRICE_SHIPPING_LALA_FAILURE');
export const resetGetPriceShippingLalaState = createAction('RESET_GET_PRICE_SHIPPING_LALA_STATE');

export const createFeeLalaManualRequest = createAction('CREATE_FEE_LALA_MANUAL_REQUEST');
export const createFeeLalaManualSuccess = createAction('CREATE_FEE_LALA_MANUAL_SUCCESS');
export const createFeeLalaManualFailure = createAction('CREATE_FEE_LALA_MANUAL_FAILURE');
export const resetCreateFeeLalaManualState = createAction('RESET_CREATE_FEE_LALA_MANUAL_STATE');

export const createShippingLalaRequest = createAction('CREATE_SHIPPING_LALA_REQUEST');
export const createShippingLalaSuccess = createAction('CREATE_SHIPPING_LALA_SUCCESS');
export const createShippingLalaFailure = createAction('CREATE_SHIPPING_LALA_FAILURE');
export const resetCreateShippingLalaState = createAction('RESET_CREATE_SHIPPING_LALA_STATE');

export const getListOrderLalaAcceptedRequest = createAction('GET_LIST_ORDER_LALA_ACCEPTED_REQUEST');
export const getListOrderLalaAcceptedSuccess = createAction('GET_LIST_ORDER_LALA_ACCEPTED_SUCCESS');
export const getListOrderLalaAcceptedFailure = createAction('GET_LIST_ORDER_LALA_ACCEPTED_FAILURE');

export const createFeeSangthuyRequest = createAction('CREATE_FEE_SANGTHUY_REQUEST');
export const createFeeSangthuySuccess = createAction('CREATE_FEE_SANGTHUY_SUCCESS');
export const createFeeSangthuyFailure = createAction('CREATE_FEE_SANGTHUY_FAILURE');
export const resetCreateFeeSangthuyState = createAction('RESET_CREATE_FEE_SANGTHUY_STATE');

export const getLogLalaMoveRequest = createAction('GET_LOG_LALA_MOVE_REQUEST');
export const getLogLalaMoveSuccess = createAction('GET_LOG_LALA_MOVE_SUCCESS');
export const getLogLalaMoveFailure = createAction('GET_LOG_LALA_MOVE_FAILURE');

export const updateFeeLalaMoveRequest = createAction('UPDATE_FEe_LALA_MOVE_REQUEST');
export const updateFeeLalaMoveSuccess = createAction('UPDATE_FEe_LALA_MOVE_SUCCESS');
export const updateFeeLalaMoveFailure = createAction('UPDATE_FEe_LALA_MOVE_FAILURE');
export const resetUpdateFeeLalaMoveState = createAction('RESET_UPDATE_FEE_LALA_MOVE_STATE');

export const cancelLalaMoveRequest = createAction('CANCEL_LALA_MOVE_REQUEST');
export const cancelLalaMoveSuccess = createAction('CANCEL_LALA_MOVE_SUCCESS');
export const cancelLalaMoveFailure = createAction('CANCEL_LALA_MOVE_FAILURE');
export const resetCancelLalaMoveState = createAction('RESET_CANCEL_LALA_MOVE_STATE');

export const claimPermissionRequest = createAction('CLAIM_PERMISSION_REQUEST');
export const claimPermissionSuccess = createAction('CLAIM_PERMISSION_SUCCESS');
export const claimPermissionFailure = createAction('CLAIM_PERMISSION_FAILURE');
export const resetClaimPermissionState = createAction('RESET_CLAIM_PERMISSION_STATE');

export const acceptPermissionRequest = createAction('ACCEPT_PERMISSION_REQUEST');
export const acceptPermissionSuccess = createAction('ACCEPT_PERMISSION_SUCCESS');
export const acceptPermissionFailure = createAction('ACCEPT_PERMISSION_FAILURE');
export const resetAcceptPermissionState = createAction('RESET_ACCEPT_PERMISSION_STATE');

export const updateOrderLalaRequest = createAction('UPDATE_ORDER_LALA_REQUEST');
export const updateOrderLalaSuccess = createAction('UPDATE_ORDER_LALA_SUCCESS');
export const updateOrderLalaFailure = createAction('UPDATE_ORDER_LALA_FAILURE');
export const resetUpdateOrderLalaState = createAction('RESET_UPDATE_ORDER_LALA_STATE');

export const getListOrderLalaCanChangeRequest = createAction('GET_LIST_ORDER_LALA_CAN_CHANGE_REQUEST');
export const getListOrderLalaCanChangeSuccess = createAction('GET_LIST_ORDER_LALA_CAN_CHANGE_SUCCESS');
export const getListOrderLalaCanChangeFailure = createAction('GET_LIST_ORDER_LALA_CAN_CHANGE_FAILURE');

export const resetOrderState = createAction('RESET_ORDER_STATE');

export const createSellingVoucherRequest = createAction('CREATE_SELLING_VOUCHER_REQUEST');
export const createSellingVoucherSuccess = createAction('CREATE_SELLING_VOUCHER_SUCCESS');
export const createSellingVoucherFailure = createAction('CREATE_SELLING_VOUCHER_FAILURE');
export const resetCreateSellingVoucherState = createAction('RESET_CREATE_SELLING_VOUCHER_STATE');

export const createPurcharseVoucherRequest = createAction('CREATE_PURCHARSE_VOUCHER_REQUEST');
export const createPurcharseVoucherSuccess = createAction('CREATE_PURCHARSE_VOUCHER_SUCCESS');
export const createPurcharseVoucherFailure = createAction('CREATE_PURCHARSE_VOUCHER_FAILURE');
export const resetCreatePurcharseVoucherState = createAction('RESET_CREATE_PURCHARSE_VOUCHER_STATE');

export const getAllOrderWaitingRequest = createAction('GET_ALL_ORDER_WAITING_REQUEST');
export const getAllOrderWaitingSuccess = createAction('GET_ALL_ORDER_WAITING_SUCCESS');
export const getAllOrderWaitingFailure = createAction('GET_ALL_ORDER_WAITING_FAILURE');

export const getAllProductSizesOrderRequest = createAction('GET_ALL_PRODUCT_SIZES_ORDER_REQUEST');
export const getAllProductSizesOrderSuccess = createAction('GET_ALL_PRODUCT_SIZES_ORDER_SUCCESS');
export const getAllProductSizesOrderFailure = createAction('GET_ALL_PRODUCT_SIZES_ORDER_FAILURE');
export const resetGetAllProductSizesOrderState = createAction('RESET_GET_ALL_PRODUCT_SIZES_ORDER_STATE');

export const splitOrderRequest = createAction('SPLIT_ORDER_REQUEST');
export const splitOrderSuccess = createAction('SPLIT_ORDER_SUCCESS');
export const splitOrderFailure = createAction('SPLIT_ORDER_FAILURE');
export const resetSplitOrder = createAction('RESET_SPLIT_ORDER');

export const mergeOrderRequest = createAction('MERGE_ORDER_REQUEST');
export const mergeOrderSuccess = createAction('MERGE_ORDER_SUCCESS');
export const mergeOrderFailure = createAction('MERGE_ORDER_FAILURE');
export const resetMergeOrder = createAction('RESET_MERGE_ORDER');

export const checkProductInStockRequest = createAction('CHECK_PRODUCT_IN_STOCK_REQUEST');
export const checkProductInStockSuccess = createAction('CHECK_PRODUCT_IN_STOCK_SUCCESS');
export const checkProductInStockFailure = createAction('CHECK_PRODUCT_IN_STOCK_FAILURE');
export const resetCheckProductInStock = createAction('RESET_CHECK_PRODUCT_IN_STOCK');

export const getListOrdersAllRequest = createAction('GET_LIST_ORDERS_ALL_REQUEST');
export const getListOrdersAllSuccess = createAction('GET_LIST_ORDERS_ALL_SUCCESS');
export const getListOrdersAllFailure = createAction('GET_LIST_ORDERS_ALL_FAILURE');

export const updatePriceOrderRequest = createAction('UPDATE_PRODUCT_ORDER_REQUEST');
export const updatePriceOrderSuccess = createAction('UPDATE_PRODUCT_ORDER_SUCCESS');
export const updatePriceOrderFailure = createAction('UPDATE_PRODUCT_ORDER_FAILURE');
export const resetUpdatePriceOrder = createAction('RESET_UPDATE_PRICE_ORDER');
