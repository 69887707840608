// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All Cart
  isFirstGetAllCarts: false,
  isGetAllCartsRequest: false,
  isGetAllCartsSuccess: false,
  isGetAllCartsFailure: false,
  getAllCartsState: {},
  // Create Cart
  isCreateCartRequest: false,
  isCreateCartSuccess: false,
  isCreateCartFailure: false,
  // Update Cart
  isUpdateCartRequest: false,
  isUpdateCartSuccess: false,
  isUpdateCartFailure: false,
  // Delete Cart
  isDeleteCartRequest: false,
  isDeleteCartSuccess: false,
  isDeleteCartFailure: false,
  // Get all handover
  isGetAllHandoverCartsRequest: false,
  isGetAllHandoverCartsSuccess: false,
  isGetAllHandoverCartsFailure: false,
  getAllHandoverCartsState: false,
  // Approve Cart
  isApproveCartRequest: false,
  isApproveCartSuccess: false,
  isApproveCartFailure: false,
  // Approve Cart
  isCancelCartRequest: false,
  isCancelCartSuccess: false,
  isCancelCartFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Cart
    [Actions.getAllCartsRequest]: (state) => ({
      ...state,
      isGetAllCartsRequest: true,
      isGetAllCartsSuccess: false,
      isGetAllCartsFailure: false,
    }),
    [Actions.getAllCartsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllCarts: true,
      isGetAllCartsRequest: false,
      isGetAllCartsSuccess: true,
      isGetAllCartsFailure: false,
      getAllCartsState: payload,
    }),
    [Actions.getAllCartsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllCartsRequest: false,
      isGetAllCartsSuccess: false,
      isGetAllCartsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Cart
    [Actions.createCartRequest]: (state) => ({
      ...state,
      isCreateCartRequest: true,
      isCreateCartSuccess: false,
      isCreateCartFailure: false,
    }),
    [Actions.createCartSuccess]: (state) => ({
      ...state,
      isCreateCartRequest: false,
      isCreateCartSuccess: true,
      isCreateCartFailure: false,
    }),
    [Actions.createCartFailure]: (state, { payload }) => ({
      ...state,
      isCreateCartRequest: false,
      isCreateCartSuccess: false,
      isCreateCartFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateCart]: (state) => ({
      ...state,
      isCreateCartRequest: false,
      isCreateCartSuccess: false,
      isCreateCartFailure: false,
    }),
    // #endregion
    // #region : Update Cart
    [Actions.updateCartRequest]: (state) => ({
      ...state,
      isUpdateCartRequest: true,
      isUpdateCartSuccess: false,
      isUpdateCartFailure: false,
    }),
    [Actions.updateCartSuccess]: (state) => ({
      ...state,
      isUpdateCartRequest: false,
      isUpdateCartSuccess: true,
      isUpdateCartFailure: false,
    }),
    [Actions.updateCartFailure]: (state, { payload }) => ({
      ...state,
      isUpdateCartRequest: false,
      isUpdateCartSuccess: false,
      isUpdateCartFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateCart]: (state) => ({
      ...state,
      isUpdateCartRequest: false,
      isUpdateCartSuccess: false,
      isUpdateCartFailure: false,
    }),
    // #endregion
    // #region : Delete Cart
    [Actions.deleteCartRequest]: (state) => ({
      ...state,
      isDeleteCartRequest: true,
      isDeleteCartSuccess: false,
      isDeleteCartFailure: false,
    }),
    [Actions.deleteCartSuccess]: (state, { payload }) => {
      const getAllCartsStateTmp = handleUpdateDataList(state.getAllCartsState, payload, 'delete');

      return ({
        ...state,
        isDeleteCartRequest: false,
        isDeleteCartSuccess: true,
        isDeleteCartFailure: false,
        getAllCartsState: { ...getAllCartsStateTmp },
      });
    },
    [Actions.deleteCartFailure]: (state, { payload }) => ({
      ...state,
      isDeleteCartRequest: false,
      isDeleteCartSuccess: false,
      isDeleteCartFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteCart]: (state) => ({
      ...state,
      isDeleteCartRequest: false,
      isDeleteCartSuccess: false,
      isDeleteCartFailure: false,
    }),
    // #endregion

    // #region : Get All Cart
    [Actions.getAllHandoverCartRequest]: (state) => ({
      ...state,
      isGetAllHandoverCartsRequest: true,
      isGetAllHandoverCartsSuccess: false,
      isGetAllHandoverCartsFailure: false,
    }),
    [Actions.getAllHandoverCartSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllHandoverCartsRequest: false,
      isGetAllHandoverCartsSuccess: true,
      isGetAllHandoverCartsFailure: false,
      getAllHandoverCartsState: payload,
    }),
    [Actions.getAllHandoverCartFailure]: (state, { payload }) => ({
      ...state,
      isGetAllHandoverCartsRequest: false,
      isGetAllHandoverCartsSuccess: false,
      isGetAllHandoverCartsFailure: true,
      errorMessages: payload,
    }),
    // #endregion

    // #region Approve cart
    [Actions.approveCartRequest]: (state) => ({
      ...state,
      isApproveCartRequest: true,
      isApproveCartSuccess: false,
      isApproveCartFailure: false,
    }),
    [Actions.approveCartSuccess]: (state) => ({
      ...state,
      isApproveCartRequest: false,
      isApproveCartSuccess: true,
      isApproveCartFailure: false,
    }),
    [Actions.approveCartFailure]: (state, { payload }) => ({
      ...state,
      isApproveCartRequest: false,
      isApproveCartSuccess: false,
      isApproveCartFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetApproveCart]: (state) => ({
      ...state,
      isApproveCartRequest: false,
      isApproveCartSuccess: false,
      isApproveCartFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region Cancel cart
    [Actions.cancelCartRequest]: (state) => ({
      ...state,
      isCancelCartRequest: true,
      isCancelCartSuccess: false,
      isCancelCartFailure: false,
    }),
    [Actions.cancelCartSuccess]: (state) => ({
      ...state,
      isCancelCartRequest: false,
      isCancelCartSuccess: true,
      isCancelCartFailure: false,
    }),
    [Actions.cancelCartFailure]: (state, { payload }) => ({
      ...state,
      isCancelCartRequest: false,
      isCancelCartSuccess: false,
      isCancelCartFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelCart]: (state) => ({
      ...state,
      isCancelCartRequest: false,
      isCancelCartSuccess: false,
      isCancelCartFailure: false,
      errorMessages: [],
    }),
    // #endregion
    // #region : Local
    [Actions.resetCartState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
