// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All PublicRefund
  isFirstGetAllPublicRefunds: false,
  isGetAllPublicRefundsRequest: false,
  isGetAllPublicRefundsSuccess: false,
  isGetAllPublicRefundsFailure: false,
  getAllPublicRefundsState: {},
  // Create PublicRefund
  isCreatePublicRefundRequest: false,
  isCreatePublicRefundSuccess: false,
  isCreatePublicRefundFailure: false,
  // Update PublicRefund
  isUpdatePublicRefundRequest: false,
  isUpdatePublicRefundSuccess: false,
  isUpdatePublicRefundFailure: false,
  // Delete PublicRefund
  isDeletePublicRefundRequest: false,
  isDeletePublicRefundSuccess: false,
  isDeletePublicRefundFailure: false,
  //
  isUpdateStatusPublicRefundRequest: false,
  isUpdateStatusPublicRefundSuccess: false,
  isUpdateStatusPublicRefundFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All PublicRefund
    [Actions.getAllPublicRefundsRequest]: (state) => ({
      ...state,
      isGetAllPublicRefundsRequest: true,
      isGetAllPublicRefundsSuccess: false,
      isGetAllPublicRefundsFailure: false,
    }),
    [Actions.getAllPublicRefundsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllPublicRefunds: true,
      isGetAllPublicRefundsRequest: false,
      isGetAllPublicRefundsSuccess: true,
      isGetAllPublicRefundsFailure: false,
      getAllPublicRefundsState: payload,
    }),
    [Actions.getAllPublicRefundsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllPublicRefundsRequest: false,
      isGetAllPublicRefundsSuccess: false,
      isGetAllPublicRefundsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create PublicRefund
    [Actions.createPublicRefundRequest]: (state) => ({
      ...state,
      isCreatePublicRefundRequest: true,
      isCreatePublicRefundSuccess: false,
      isCreatePublicRefundFailure: false,
    }),
    [Actions.createPublicRefundSuccess]: (state, { payload }) => {
      const getAllPublicRefundsStateTmp = handleUpdateDataList(state.getAllPublicRefundsState, payload.data, 'create');

      return ({
        ...state,
        isCreatePublicRefundRequest: false,
        isCreatePublicRefundSuccess: true,
        isCreatePublicRefundFailure: false,
        getAllPublicRefundsState: { ...getAllPublicRefundsStateTmp },
      });
    },
    [Actions.createPublicRefundFailure]: (state, { payload }) => ({
      ...state,
      isCreatePublicRefundRequest: false,
      isCreatePublicRefundSuccess: false,
      isCreatePublicRefundFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreatePublicRefund]: (state) => ({
      ...state,
      isCreatePublicRefundRequest: false,
      isCreatePublicRefundSuccess: false,
      isCreatePublicRefundFailure: false,
    }),
    // #endregion
    // #region : Update PublicRefund
    [Actions.updatePublicRefundRequest]: (state) => ({
      ...state,
      isUpdatePublicRefundRequest: true,
      isUpdatePublicRefundSuccess: false,
      isUpdatePublicRefundFailure: false,
    }),
    [Actions.updatePublicRefundSuccess]: (state, { payload }) => {
      const getAllPublicRefundsStateTmp = handleUpdateDataList(state.getAllPublicRefundsState, payload.data, 'update');

      return ({
        ...state,
        isUpdatePublicRefundRequest: false,
        isUpdatePublicRefundSuccess: true,
        isUpdatePublicRefundFailure: false,
        getAllPublicRefundsState: { ...getAllPublicRefundsStateTmp },
      });
    },
    [Actions.updatePublicRefundFailure]: (state, { payload }) => ({
      ...state,
      isUpdatePublicRefundRequest: false,
      isUpdatePublicRefundSuccess: false,
      isUpdatePublicRefundFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdatePublicRefund]: (state) => ({
      ...state,
      isUpdatePublicRefundRequest: false,
      isUpdatePublicRefundSuccess: false,
      isUpdatePublicRefundFailure: false,
    }),
    // #endregion
    // #region : Delete PublicRefund
    [Actions.deletePublicRefundRequest]: (state) => ({
      ...state,
      isDeletePublicRefundRequest: true,
      isDeletePublicRefundSuccess: false,
      isDeletePublicRefundFailure: false,
    }),
    [Actions.deletePublicRefundSuccess]: (state, { payload }) => {
      const getAllPublicRefundsStateTmp = handleUpdateDataList(state.getAllPublicRefundsState, payload, 'delete');

      return ({
        ...state,
        isDeletePublicRefundRequest: false,
        isDeletePublicRefundSuccess: true,
        isDeletePublicRefundFailure: false,
        getAllPublicRefundsState: { ...getAllPublicRefundsStateTmp },
      });
    },
    [Actions.deletePublicRefundFailure]: (state, { payload }) => ({
      ...state,
      isDeletePublicRefundRequest: false,
      isDeletePublicRefundSuccess: false,
      isDeletePublicRefundFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeletePublicRefund]: (state) => ({
      ...state,
      isDeletePublicRefundRequest: false,
      isDeletePublicRefundSuccess: false,
      isDeletePublicRefundFailure: false,
    }),
    // #endregion

    // #region
    [Actions.updateStatusPublicRefundRequest]: (state) => ({
      ...state,
      isUpdateStatusPublicRefundRequest: true,
      isUpdateStatusPublicRefundSuccess: false,
      isUpdateStatusPublicRefundFailure: false,
    }),
    [Actions.updateStatusPublicRefundSuccess]: (state) => ({
      ...state,
      isUpdateStatusPublicRefundRequest: false,
      isUpdateStatusPublicRefundSuccess: true,
      isUpdateStatusPublicRefundFailure: false,
    }),
    [Actions.updateStatusPublicRefundFailure]: (state, { payload }) => ({
      ...state,
      isUpdateStatusPublicRefundRequest: false,
      isUpdateStatusPublicRefundSuccess: false,
      isUpdateStatusPublicRefundFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateStatusPublicRefund]: (state) => ({
      ...state,
      isUpdateStatusPublicRefundRequest: false,
      isUpdateStatusPublicRefundSuccess: false,
      isUpdateStatusPublicRefundFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region : Local
    [Actions.resetPublicRefundState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
