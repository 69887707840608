// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All CustomerBranch
  isFirstGetAllCustomerBranches: false,
  isGetAllCustomerBranchesRequest: false,
  isGetAllCustomerBranchesSuccess: false,
  isGetAllCustomerBranchesFailure: false,
  getAllCustomerBranchesState: {},
  // Create CustomerBranch
  isCreateCustomerBranchRequest: false,
  isCreateCustomerBranchSuccess: false,
  isCreateCustomerBranchFailure: false,
  // Update CustomerBranch
  isUpdateCustomerBranchRequest: false,
  isUpdateCustomerBranchSuccess: false,
  isUpdateCustomerBranchFailure: false,
  // Delete CustomerBranch
  isDeleteCustomerBranchRequest: false,
  isDeleteCustomerBranchSuccess: false,
  isDeleteCustomerBranchFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All CustomerBranch
    [Actions.getAllCustomerBranchesRequest]: (state) => ({
      ...state,
      isGetAllCustomerBranchesRequest: true,
      isGetAllCustomerBranchesSuccess: false,
      isGetAllCustomerBranchesFailure: false,
    }),
    [Actions.getAllCustomerBranchesSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllCustomerBranches: true,
      isGetAllCustomerBranchesRequest: false,
      isGetAllCustomerBranchesSuccess: true,
      isGetAllCustomerBranchesFailure: false,
      getAllCustomerBranchesState: payload,
    }),
    [Actions.getAllCustomerBranchesFailure]: (state, { payload }) => ({
      ...state,
      isGetAllCustomerBranchesRequest: false,
      isGetAllCustomerBranchesSuccess: false,
      isGetAllCustomerBranchesFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create CustomerBranch
    [Actions.createCustomerBranchRequest]: (state) => ({
      ...state,
      isCreateCustomerBranchRequest: true,
      isCreateCustomerBranchSuccess: false,
      isCreateCustomerBranchFailure: false,
    }),
    [Actions.createCustomerBranchSuccess]: (state) => ({
      ...state,
      isCreateCustomerBranchRequest: false,
      isCreateCustomerBranchSuccess: true,
      isCreateCustomerBranchFailure: false,
    }),
    [Actions.createCustomerBranchFailure]: (state, { payload }) => ({
      ...state,
      isCreateCustomerBranchRequest: false,
      isCreateCustomerBranchSuccess: false,
      isCreateCustomerBranchFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateCustomerBranch]: (state) => ({
      ...state,
      isCreateCustomerBranchRequest: false,
      isCreateCustomerBranchSuccess: false,
      isCreateCustomerBranchFailure: false,
    }),
    // #endregion
    // #region : Update CustomerBranch
    [Actions.updateCustomerBranchRequest]: (state) => ({
      ...state,
      isUpdateCustomerBranchRequest: true,
      isUpdateCustomerBranchSuccess: false,
      isUpdateCustomerBranchFailure: false,
    }),
    [Actions.updateCustomerBranchSuccess]: (state, { payload }) => {
      const getAllCustomerBranchesStateTmp = handleUpdateDataList(state.getAllCustomerBranchesState, payload.data, 'update');

      return ({
        ...state,
        isUpdateCustomerBranchRequest: false,
        isUpdateCustomerBranchSuccess: true,
        isUpdateCustomerBranchFailure: false,
        getAllCustomerBranchesState: { ...getAllCustomerBranchesStateTmp },
      });
    },
    [Actions.updateCustomerBranchFailure]: (state, { payload }) => ({
      ...state,
      isUpdateCustomerBranchRequest: false,
      isUpdateCustomerBranchSuccess: false,
      isUpdateCustomerBranchFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateCustomerBranch]: (state) => ({
      ...state,
      isUpdateCustomerBranchRequest: false,
      isUpdateCustomerBranchSuccess: false,
      isUpdateCustomerBranchFailure: false,
    }),
    // #endregion
    // #region : Delete CustomerBranch
    [Actions.deleteCustomerBranchRequest]: (state) => ({
      ...state,
      isDeleteCustomerBranchRequest: true,
      isDeleteCustomerBranchSuccess: false,
      isDeleteCustomerBranchFailure: false,
    }),
    [Actions.deleteCustomerBranchSuccess]: (state) => ({
      ...state,
      isDeleteCustomerBranchRequest: false,
      isDeleteCustomerBranchSuccess: true,
      isDeleteCustomerBranchFailure: false,
    }),
    [Actions.deleteCustomerBranchFailure]: (state, { payload }) => ({
      ...state,
      isDeleteCustomerBranchRequest: false,
      isDeleteCustomerBranchSuccess: false,
      isDeleteCustomerBranchFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteCustomerBranch]: (state) => ({
      ...state,
      isDeleteCustomerBranchRequest: false,
      isDeleteCustomerBranchSuccess: false,
      isDeleteCustomerBranchFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetCustomerBranchState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
