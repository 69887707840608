import { combineReducers } from 'redux';

import auth from './auth/reducer';
import employee from './employee/reducer';
import menu from './menu/reducer';
import permissions from './permissions/reducer';
import roles from './roles/reducer';
import user from './user/reducer';
import productType from './productType/reducer';
import product from './product/reducer';
import customer from './customer/reducer';
import order from './order/reducer';
import regionGroup from './regionGroup/reducer';
import common from './common/reducer';
import productPrice from './productPrice/reducer';
import calendar from './calendar/reducer';
import scheduleCalendar from './scheduleCalendar/reducer';
import scheduleBookCalendar from './scheduleBookCalendar/reducer';
import report from './report/reducer';
import config from './config/reducer';
import region from './region/reducer';
import chat from './chat/reducer';
import chart from './chart/reducer';
import work from './work/reducer';
import workTracking from './workTracking/reducer';
import productBag from './productBag/reducer';
import productSize from './productSize/reducer';
import productGrade from './productGrade/reducer';
import cart from './cart/reducer';
import warehouse from './warehouse/reducer';
import customerBranch from './customerBranch/reducer';
import exportWarehouse from './exportWarehouse/reducer';
import batch from './batch/reducer';
import exportProduct from './exportProduct/reducer';
import refund from './refund/reducer';
import shipping from './shipping/reducer';
import importWarehouse from './importWarehouse/reducer';
import exchangeWarehouse from './exchangeWarehouse/reducer';
import driver from './driver/reducer';
import resize from './resize/reducer';
import label from './label/reducer';
import lateReview from './lateReview/reducer';
import publicOrder from './publicOrder/reducer';
import publicRefund from './publicRefund/reducer';

const rootReducer = combineReducers({
  auth,
  employee,
  menu,
  roles,
  user,
  permissions,
  product,
  productType,
  customer,
  order,
  regionGroup,
  common,
  productPrice,
  calendar,
  scheduleCalendar,
  scheduleBookCalendar,
  report,
  config,
  region,
  chat,
  chart,
  work,
  workTracking,
  productBag,
  productSize,
  productGrade,
  cart,
  warehouse,
  customerBranch,
  exportWarehouse,
  batch,
  exportProduct,
  refund,
  shipping,
  importWarehouse,
  exchangeWarehouse,
  driver,
  resize,
  label,
  lateReview,
  publicOrder,
  publicRefund,
});

export default rootReducer;
