// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllCustomerBranchesRequest = createAction('GET_ALL_CUSTOMERBRANCHES_REQUEST');
export const getAllCustomerBranchesSuccess = createAction('GET_ALL_CUSTOMERBRANCHES_SUCCESS');
export const getAllCustomerBranchesFailure = createAction('GET_ALL_CUSTOMERBRANCHES_FAILURE');

export const createCustomerBranchRequest = createAction('CREATE_CUSTOMERBRANCH_REQUEST');
export const createCustomerBranchSuccess = createAction('CREATE_CUSTOMERBRANCH_SUCCESS');
export const createCustomerBranchFailure = createAction('CREATE_CUSTOMERBRANCH_FAILURE');
export const resetCreateCustomerBranch = createAction('RESET_CREATE_CUSTOMERBRANCH');

export const updateCustomerBranchRequest = createAction('UPDATE_CUSTOMERBRANCH_REQUEST');
export const updateCustomerBranchSuccess = createAction('UPDATE_CUSTOMERBRANCH_SUCCESS');
export const updateCustomerBranchFailure = createAction('UPDATE_CUSTOMERBRANCH_FAILURE');
export const resetUpdateCustomerBranch = createAction('RESET_UPDATE_CUSTOMERBRANCH');

export const deleteCustomerBranchRequest = createAction('DELETE_CUSTOMERBRANCH_REQUEST');
export const deleteCustomerBranchSuccess = createAction('DELETE_CUSTOMERBRANCH_SUCCESS');
export const deleteCustomerBranchFailure = createAction('DELETE_CUSTOMERBRANCH_FAILURE');
export const resetDeleteCustomerBranch = createAction('RESET_DELETE_CUSTOMERBRANCH');

export const resetCustomerBranchState = createAction('RESET_CUSTOMERBRANCH_STATE');
