// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/reduxFunc';
import * as Actions from './actions';

const initialState = {
  // Get All LateReview
  isFirstGetAllLateReviews: false,
  isGetAllLateReviewsRequest: false,
  isGetAllLateReviewsSuccess: false,
  isGetAllLateReviewsFailure: false,
  getAllLateReviewsState: {},
  // Create LateReview
  isCreateLateReviewRequest: false,
  isCreateLateReviewSuccess: false,
  isCreateLateReviewFailure: false,
  // Update LateReview
  isUpdateLateReviewRequest: false,
  isUpdateLateReviewSuccess: false,
  isUpdateLateReviewFailure: false,
  // Delete LateReview
  isDeleteLateReviewRequest: false,
  isDeleteLateReviewSuccess: false,
  isDeleteLateReviewFailure: false,
  // Change status
  isChangeStatusLateReviewRequest: false,
  isChangeStatusLateReviewSuccess: false,
  isChangeStatusLateReviewFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All LateReview
    [Actions.getAllLateReviewsRequest]: (state) => ({
      ...state,
      isGetAllLateReviewsRequest: true,
      isGetAllLateReviewsSuccess: false,
      isGetAllLateReviewsFailure: false,
    }),
    [Actions.getAllLateReviewsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllLateReviews: true,
      isGetAllLateReviewsRequest: false,
      isGetAllLateReviewsSuccess: true,
      isGetAllLateReviewsFailure: false,
      getAllLateReviewsState: payload,
    }),
    [Actions.getAllLateReviewsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllLateReviewsRequest: false,
      isGetAllLateReviewsSuccess: false,
      isGetAllLateReviewsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create LateReview
    [Actions.createLateReviewRequest]: (state) => ({
      ...state,
      isCreateLateReviewRequest: true,
      isCreateLateReviewSuccess: false,
      isCreateLateReviewFailure: false,
    }),
    [Actions.createLateReviewSuccess]: (state, { payload }) => {
      const getAllLateReviewsStateTmp = handleUpdateDataList(state.getAllLateReviewsState, payload.data, 'create');

      return ({
        ...state,
        isCreateLateReviewRequest: false,
        isCreateLateReviewSuccess: true,
        isCreateLateReviewFailure: false,
        getAllLateReviewsState: { ...getAllLateReviewsStateTmp },
      });
    },
    [Actions.createLateReviewFailure]: (state, { payload }) => ({
      ...state,
      isCreateLateReviewRequest: false,
      isCreateLateReviewSuccess: false,
      isCreateLateReviewFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateLateReview]: (state) => ({
      ...state,
      isCreateLateReviewRequest: false,
      isCreateLateReviewSuccess: false,
      isCreateLateReviewFailure: false,
    }),
    // #endregion
    // #region : Update LateReview
    [Actions.updateLateReviewRequest]: (state) => ({
      ...state,
      isUpdateLateReviewRequest: true,
      isUpdateLateReviewSuccess: false,
      isUpdateLateReviewFailure: false,
    }),
    [Actions.updateLateReviewSuccess]: (state, { payload }) => {
      const getAllLateReviewsStateTmp = handleUpdateDataList(state.getAllLateReviewsState, payload.data, 'update');

      return ({
        ...state,
        isUpdateLateReviewRequest: false,
        isUpdateLateReviewSuccess: true,
        isUpdateLateReviewFailure: false,
        getAllLateReviewsState: { ...getAllLateReviewsStateTmp },
      });
    },
    [Actions.updateLateReviewFailure]: (state, { payload }) => ({
      ...state,
      isUpdateLateReviewRequest: false,
      isUpdateLateReviewSuccess: false,
      isUpdateLateReviewFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateLateReview]: (state) => ({
      ...state,
      isUpdateLateReviewRequest: false,
      isUpdateLateReviewSuccess: false,
      isUpdateLateReviewFailure: false,
    }),
    // #endregion
    // #region : Delete LateReview
    [Actions.deleteLateReviewRequest]: (state) => ({
      ...state,
      isDeleteLateReviewRequest: true,
      isDeleteLateReviewSuccess: false,
      isDeleteLateReviewFailure: false,
    }),
    [Actions.deleteLateReviewSuccess]: (state, { payload }) => {
      const getAllLateReviewsStateTmp = handleUpdateDataList(state.getAllLateReviewsState, payload, 'delete');

      return ({
        ...state,
        isDeleteLateReviewRequest: false,
        isDeleteLateReviewSuccess: true,
        isDeleteLateReviewFailure: false,
        getAllLateReviewsState: { ...getAllLateReviewsStateTmp },
      });
    },
    [Actions.deleteLateReviewFailure]: (state, { payload }) => ({
      ...state,
      isDeleteLateReviewRequest: false,
      isDeleteLateReviewSuccess: false,
      isDeleteLateReviewFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteLateReview]: (state) => ({
      ...state,
      isDeleteLateReviewRequest: false,
      isDeleteLateReviewSuccess: false,
      isDeleteLateReviewFailure: false,
    }),
    // #endregion
    // #region : Change status LateReview
    [Actions.changeStatusLateReviewRequest]: (state) => ({
      ...state,
      isChangeStatusLateReviewRequest: true,
      isChangeStatusLateReviewSuccess: false,
      isChangeStatusLateReviewFailure: false,
    }),
    [Actions.changeStatusLateReviewSuccess]: (state) => ({
      ...state,
      isChangeStatusLateReviewRequest: false,
      isChangeStatusLateReviewSuccess: true,
      isChangeStatusLateReviewFailure: false,
    }),
    [Actions.changeStatusLateReviewFailure]: (state, { payload }) => ({
      ...state,
      isChangeStatusLateReviewRequest: false,
      isChangeStatusLateReviewSuccess: false,
      isChangeStatusLateReviewFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetChangeStatusLateReview]: (state) => ({
      ...state,
      isChangeStatusLateReviewRequest: false,
      isChangeStatusLateReviewSuccess: false,
      isChangeStatusLateReviewFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetLateReviewState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
