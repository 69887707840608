/* eslint-disable import/no-extraneous-dependencies */
import {
  call, put, takeLatest,
} from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getListEmployees({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(payload?.params ? `/users?${payload.params}` : '/users'));
    yield put(Actions.getListEmployeesSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getListEmployeesFailure(messages));
    }
  }
}

function* addEmployee({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/register', payload));
    yield put(Actions.addEmployeeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.addEmployeeFailure(messages));
    }
  }
}

function* editEmployee({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/users/${payload.id}`, payload.body));
    if (response?.status === 200) {
      yield put(Actions.editEmployeeSuccess());
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.editEmployeeFailure(messages));
    }
  }
}

function* deleteEmployee({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/users/${payload}`));
    yield put(Actions.deleteEmployeeSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.deleteEmployeeFailure(messages));
    }
  }
}
function* getDetailEmployee({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/users?id=${payload}`));
    yield put(Actions.getDetailEmployeeSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.getDetailEmployeeFailure(messages));
    }
  }
}

function* checkEmailExist({ payload }) {
  try {
    const response = yield call(() => axiosMicro.get(`/users?limit=1000&search=email:${payload}`));
    yield put(Actions.checkEmailExistSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.checkEmailExistFailure(messages));
    }
  }
}

function* resetPassword({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/users/${payload.id}`, payload));
    yield put(Actions.resetPasswordEmployeeSuccess());
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.resetPasswordEmployeeFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getListEmployeesRequest, getListEmployees);
  yield takeLatest(Actions.addEmployeeRequest, addEmployee);
  yield takeLatest(Actions.getDetailEmployeeRequest, getDetailEmployee);
  yield takeLatest(Actions.editEmployeeRequest, editEmployee);
  yield takeLatest(Actions.resetPasswordEmployeeRequest, resetPassword);
  yield takeLatest(Actions.deleteEmployeeRequest, deleteEmployee);
  yield takeLatest(Actions.checkEmailExistRequest, checkEmailExist);
}
