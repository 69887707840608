// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

import * as Actions from './actions';

const initialState = {
  // Get All Refund
  isFirstGetAllRefunds: false,
  isGetAllRefundsRequest: false,
  isGetAllRefundsSuccess: false,
  isGetAllRefundsFailure: false,
  getAllRefundsState: {},
  // Create Refund
  isCreateRefundRequest: false,
  isCreateRefundSuccess: false,
  isCreateRefundFailure: false,
  // Cancel Refund
  isCancelRefundRequest: false,
  isCancelRefundSuccess: false,
  isCancelRefundFailure: false,
  // Update Refund Status
  isUpdateRefundStatusRequest: false,
  isUpdateRefundStatusSuccess: false,
  isUpdateRefundStatusFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All Refund
    [Actions.getAllRefundsRequest]: (state) => ({
      ...state,
      isGetAllRefundsRequest: true,
      isGetAllRefundsSuccess: false,
      isGetAllRefundsFailure: false,
    }),
    [Actions.getAllRefundsSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllRefunds: true,
      isGetAllRefundsRequest: false,
      isGetAllRefundsSuccess: true,
      isGetAllRefundsFailure: false,
      getAllRefundsState: payload,
    }),
    [Actions.getAllRefundsFailure]: (state, { payload }) => ({
      ...state,
      isGetAllRefundsRequest: false,
      isGetAllRefundsSuccess: false,
      isGetAllRefundsFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create Refund
    [Actions.createRefundRequest]: (state) => ({
      ...state,
      isCreateRefundRequest: true,
      isCreateRefundSuccess: false,
      isCreateRefundFailure: false,
    }),
    [Actions.createRefundSuccess]: (state) => ({
      ...state,
      isCreateRefundRequest: false,
      isCreateRefundSuccess: true,
      isCreateRefundFailure: false,
    }),
    [Actions.createRefundFailure]: (state, { payload }) => ({
      ...state,
      isCreateRefundRequest: false,
      isCreateRefundSuccess: false,
      isCreateRefundFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateRefund]: (state) => ({
      ...state,
      isCreateRefundRequest: false,
      isCreateRefundSuccess: false,
      isCreateRefundFailure: false,
    }),
    // #endregion
    // #region : Cancel Refund
    [Actions.cancelRefundRequest]: (state) => ({
      ...state,
      isCancelRefundRequest: true,
      isCancelRefundSuccess: false,
      isCancelRefundFailure: false,
    }),
    [Actions.cancelRefundSuccess]: (state) => ({
      ...state,
      isCancelRefundRequest: false,
      isCancelRefundSuccess: true,
      isCancelRefundFailure: false,
    }),
    [Actions.cancelRefundFailure]: (state, { payload }) => ({
      ...state,
      isCancelRefundRequest: false,
      isCancelRefundSuccess: false,
      isCancelRefundFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCancelRefund]: (state) => ({
      ...state,
      isCancelRefundRequest: false,
      isCancelRefundSuccess: false,
      isCancelRefundFailure: false,
    }),
    // #endregion
    // #region : Update Refund
    [Actions.updateRefundStatusRequest]: (state) => ({
      ...state,
      isUpdateRefundStatusRequest: true,
      isUpdateRefundStatusSuccess: false,
      isUpdateRefundStatusFailure: false,
    }),
    [Actions.updateRefundStatusSuccess]: (state) => ({
      ...state,
      isUpdateRefundStatusRequest: false,
      isUpdateRefundStatusSuccess: true,
      isUpdateRefundStatusFailure: false,
    }),
    [Actions.updateRefundStatusFailure]: (state, { payload }) => ({
      ...state,
      isUpdateRefundStatusRequest: false,
      isUpdateRefundStatusSuccess: false,
      isUpdateRefundStatusFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateRefundStatus]: (state) => ({
      ...state,
      isUpdateRefundStatusRequest: false,
      isUpdateRefundStatusSuccess: false,
      isUpdateRefundStatusFailure: false,
    }),
    // #endregion
    // #region : Local
    [Actions.resetRefundState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
