// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllProductBagsRequest = createAction('GET_ALL_PRODUCTBAGS_REQUEST');
export const getAllProductBagsSuccess = createAction('GET_ALL_PRODUCTBAGS_SUCCESS');
export const getAllProductBagsFailure = createAction('GET_ALL_PRODUCTBAGS_FAILURE');

export const createProductBagRequest = createAction('CREATE_PRODUCTBAG_REQUEST');
export const createProductBagSuccess = createAction('CREATE_PRODUCTBAG_SUCCESS');
export const createProductBagFailure = createAction('CREATE_PRODUCTBAG_FAILURE');
export const resetCreateProductBag = createAction('RESET_CREATE_PRODUCTBAG');

export const createProductBagMultiRequest = createAction('CREATE_PRODUCTBAG_MULTI_REQUEST');
export const createProductBagMultiSuccess = createAction('CREATE_PRODUCTBAG_MULTI_SUCCESS');
export const createProductBagMultiFailure = createAction('CREATE_PRODUCTBAG_MULTI_FAILURE');
export const resetCreateProductBagMulti = createAction('RESET_CREATE_PRODUCTBAG_MULTI');

export const updateProductBagRequest = createAction('UPDATE_PRODUCTBAG_REQUEST');
export const updateProductBagSuccess = createAction('UPDATE_PRODUCTBAG_SUCCESS');
export const updateProductBagFailure = createAction('UPDATE_PRODUCTBAG_FAILURE');
export const resetUpdateProductBag = createAction('RESET_UPDATE_PRODUCTBAG');

export const deleteProductBagRequest = createAction('DELETE_PRODUCTBAG_REQUEST');
export const deleteProductBagSuccess = createAction('DELETE_PRODUCTBAG_SUCCESS');
export const deleteProductBagFailure = createAction('DELETE_PRODUCTBAG_FAILURE');
export const resetDeleteProductBag = createAction('RESET_DELETE_PRODUCTBAG');

export const resetProductBagState = createAction('RESET_PRODUCTBAG_STATE');
