/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllPublicRefunds({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/public/refunds?${payload}`)
        : () => axiosMicro.get('/public/refunds', { params: payload }),
    );
    yield put(Actions.getAllPublicRefundsSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllPublicRefundsFailure(messages));
    }
  }
}

function* createPublicRefund({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/public/refunds', payload));
    yield put(Actions.createPublicRefundSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createPublicRefundFailure(messages));
    }
  }
}

function* updatePublicRefund({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/public/refunds/${payload.id}`, payload.body));
    yield put(Actions.updatePublicRefundSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updatePublicRefundFailure(messages));
    }
  }
}

function* deletePublicRefund({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/public/refunds/${payload}`));
    yield put(Actions.deletePublicRefundSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deletePublicRefundFailure(messages));
    }
  }
}

function* updateStatusPublicRefund({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/public/refunds/${payload.id}/status`, {
      status: payload.status,
    }));

    yield put(Actions.updateStatusPublicRefundSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateStatusPublicRefundFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllPublicRefundsRequest, getAllPublicRefunds);
  yield takeLatest(Actions.createPublicRefundRequest, createPublicRefund);
  yield takeLatest(Actions.updatePublicRefundRequest, updatePublicRefund);
  yield takeLatest(Actions.deletePublicRefundRequest, deletePublicRefund);
  yield takeLatest(Actions.updateStatusPublicRefundRequest, updateStatusPublicRefund);
}
