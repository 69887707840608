// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllCartsRequest = createAction('GET_ALL_CARTS_REQUEST');
export const getAllCartsSuccess = createAction('GET_ALL_CARTS_SUCCESS');
export const getAllCartsFailure = createAction('GET_ALL_CARTS_FAILURE');

export const createCartRequest = createAction('CREATE_CART_REQUEST');
export const createCartSuccess = createAction('CREATE_CART_SUCCESS');
export const createCartFailure = createAction('CREATE_CART_FAILURE');
export const resetCreateCart = createAction('RESET_CREATE_CART');

export const updateCartRequest = createAction('UPDATE_CART_REQUEST');
export const updateCartSuccess = createAction('UPDATE_CART_SUCCESS');
export const updateCartFailure = createAction('UPDATE_CART_FAILURE');
export const resetUpdateCart = createAction('RESET_UPDATE_CART');

export const deleteCartRequest = createAction('DELETE_CART_REQUEST');
export const deleteCartSuccess = createAction('DELETE_CART_SUCCESS');
export const deleteCartFailure = createAction('DELETE_CART_FAILURE');
export const resetDeleteCart = createAction('RESET_DELETE_CART');

export const getAllHandoverCartRequest = createAction('GET_ALL_HANDOVER_CART_REQUEST');
export const getAllHandoverCartSuccess = createAction('GET_ALL_HANDOVER_CART_SUCCESS');
export const getAllHandoverCartFailure = createAction('GET_ALL_HANDOVER_CART_FAILURE');

export const approveCartRequest = createAction('APPROVE_CART_REQUEST');
export const approveCartSuccess = createAction('APPROVE_CART_SUCCESS');
export const approveCartFailure = createAction('APPROVE_CART_FAILURE');
export const resetApproveCart = createAction('RESET_APPROVE_CART');

export const cancelCartRequest = createAction('CANCEL_CART_REQUEST');
export const cancelCartSuccess = createAction('CANCEL_CART_SUCCESS');
export const cancelCartFailure = createAction('CANCEL_CART_FAILURE');
export const resetCancelCart = createAction('RESET_CANCEL_CART');

export const resetCartState = createAction('RESET_CART_STATE');
